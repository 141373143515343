import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { connect } from "react-redux";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import HeaderBar from './../../components/HeaderBar';
import FooterBar from './../../components/FooterBar';
import Breadcrumbs from "./../../components/Breadcrumbs";
import ProfileCard from "./../../components/CardViews/ProfileCard";
import GeneralCard from "./../../components/CardViews/GeneralCard";
import PageNotFound from '../PageNotFound';
import { getAgentDetail } from '../../api/Agent/Detail';
import Tabs from "./tabPage";
import eventBus from '../../EventBus';
import { setBreadcrumbsPath } from '../../store/actions';
import styles from './styles.module.css';


// import eventBus from '../../EventBus';

const pageview = {
    dataLayer: {
        event: 'pageview',
        log_pageurl: '',
        log_pagename: ''
    }
}

const AgentDetailPage = ({ answeredDirQns, breadcrumbsPath, setBreadcrumbsPath }) => {
    document.title = "各募集人のプロフィールページ";

    const { state, pathname } = useLocation();
    const { t } = useTranslation();
    const [agent, setAgent] = useState();
    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView();
        let upperLevel = breadcrumbsPath.slice(0,1);
        let pathnames = [...upperLevel, pathname.substring(1)];
        setBreadcrumbsPath(pathnames);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        TagManager.dataLayer(pageview);
        const setData = async () => {
            try {
                const data = await getAgentDetail({
                    "agentId": state.agentData.agentId,
                    "branchId": state.agentData.branchId,
                    "appointmentMethodId": answeredDirQns.find((el) => el.questionId === 4).itemId
                });
                setAgent(data);
            } catch (error) {
                eventBus.dispatch("something_went_wrong");
                console.log('getting agent detail error - ', error)
            }
        };
        setData();
    }, [state.agentData.agentId, state, answeredDirQns]); 

    return (
        (state === undefined || state.branchId === null || state.branchId === '') ?
            <PageNotFound />
            :
            <div>
                {agent &&
                    <>
                        <HeaderBar />
                        <div>
                            <div className={`${styles.agent_detail}  cmn-inner-width cmn-height`}>
                                <Row className={styles.header_container}>
                                    <Col lg="12" >
                                        <Breadcrumbs breadcrumbsPath={breadcrumbsPath}/>
                                        <h3 className={styles.header} >{t('agent_detail_page.title')}</h3>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xl="4" lg="4" md="4" sm="12" className={styles.col_container} >
                                        <ProfileCard data={agent} />
                                    </Col>
                                    <Col xl="8" lg="8" md="8" sm="12" className={styles.col_container}>
                                        <GeneralCard className={`${styles.description_card}`}>
                                            <Row>
                                                <Container fluid={true} className={styles.wrap}>
                                                    <Row>
                                                        <Col className={styles.title} xs="6" md="4" lg="4" >{t('agent_detail_page.working_experience')}</Col>
                                                        <Col className={`${styles.value}`} xs="6" md="8" lg="8">{agent.workYears} {t('agent_detail_page.years')}</Col>
                                                    </Row>
                                                    <Row className={styles.wrap_tabs}>
                                                        <Col>
                                                            <Tabs description="description" data={agent} />
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Row>
                                        </GeneralCard>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <FooterBar />
                    </>}
            </div>
    )
};

const stateToProps = (state) => {
    return {
      answeredDirQns: state.answeredDirQns,
      breadcrumbsPath: state.breadcrumbsPath,
    };
  };

const dispatchToProps = (dispatch) => {
    return {
        setBreadcrumbsPath: (breadcrumbsPath) => {
            dispatch(setBreadcrumbsPath(breadcrumbsPath));
        }
    }
}
  
export default connect(stateToProps, dispatchToProps)(AgentDetailPage);
