import { base_api_url } from '../../configs/environments';
import api_urls from '../../routes/api_urls';
import { defaultHeaderOptions } from '../headerOptions';


import eventBus from '../../EventBus';

const insertAppointment = (body, onSuccess, onFail) => {

    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions,
        body: JSON.stringify(body)
    };


    fetch(`${base_api_url}${api_urls.insert_appointment}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            onSuccess && onSuccess(data);
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('Insert Appointment api error - ', error);
            onFail && onFail(error);
        });
}

export {
    insertAppointment
};