import React from 'react';


import classes from './styles.module.css';

const TextIcon = ({ text, icon, className, style}) => {
    return (
        <div className={`${classes.text_icon} ${className}`} style={style}>
            {icon && <img src={icon} alt="text icon" className={classes.icon}/>}
            <p className={classes.text}>{text}</p>
        </div>
    )
};


export default TextIcon;