import React from 'react';

import Radio from '../RadioButtons';
import styles from './styles.module.css';
// import morning from '../../assets/images/icons/Period/morning_icon.png';
// import afternoon from '../../assets/images/icons/Period/afternoon_icon.png';
// import night from '../../assets/images/icons/Period/night_icon.png';
// import TextIcon from '../Icons/TextIcon';

// const Period = ({ value }) => {
//     if (value === 'morning') {
//         return <TextIcon text="Morning" icon={morning} className={styles.period} />;
//     } else if (value === 'afternoon') {
//         return <TextIcon text="Afternoon" icon={afternoon} className={styles.period} />;
//     } else {
//         return <TextIcon text="Night" icon={night} className={styles.period} />;
//     }
// }


const TimeSlot = ({ value, selectedValue, onChange }) => {
    const handleChange = (event) => {
        onChange(event.target.value);
    }
    return (
        <div className={styles.peroid_container}>
            <Radio
                selectedValue={selectedValue}
                value={value}
                name="radio-button"
                color="default"
                onChange={handleChange}
                label={value}
                labelClassName={styles.time_duration}
            />
            {/* <span className={styles.time_duration}>{value}</span> */}
            {/* <Period value={time}/> */}
        </div>
    )
}

export default TimeSlot;