import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.css";
import GeneralDropdown from "../../Dropdowns/GeneralDropdown";
import DateSelector from "../../DateSelector";
import ClearableTextBox from "../../TextBoxes/ClearableTextbox";
import GeneralButton from "../../Buttons/GeneralButton";

const DateFilterCard = ({
  style,
  onDateChange,
  onStartTimeChange,
  onEndTimeChange,
  onAgentNameChange,
  onFocus,
  onReset,
  onSearch,
  defaultDate,
  defaultStartTime,
  defaultEndTime,
  defaultAgentName,
  update,
  onReSearch
}) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = React.useState(defaultDate);
  const [startTime, setStartTime] = React.useState(defaultStartTime);
  const [endTime, setEndTime] = React.useState(defaultEndTime);
  const [endTimeSlots, setEndTimeSlots] = React.useState([]);
  const [timeSlotsEnabled, setTimeSlotsEnabled] = React.useState(false);
  const [agentName, setAgentName] = React.useState(defaultAgentName);
  const [twoRow, setTwoRow] = useState(
    window.innerWidth <= 575.98 ? true : false
  );
  const timeSlots = [
    { id: "0000", name: t("general.date_filters.unspecific") },
    { id: "1100", name: "11時00分" },
    { id: "1200", name: "12時00分" },
    { id: "1300", name: "13時00分" },
    { id: "1400", name: "14時00分" },
    { id: "1500", name: "15時00分" },
    { id: "1600", name: "16時00分" },
    { id: "1700", name: "17時00分" },
    { id: "1800", name: "18時00分" },
    { id: "1900", name: "19時00分" },
    { id: "2000", name: "20時00分" },
  ];

  useEffect(() => {
    const updateWindowDimensions = () => {
      setTwoRow(window.innerWidth <= 575.98 ? true : false);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const startTimeSlotChange = (value) => {
    setStartTime(value);
    onStartTimeChange(value);
    var dates = timeSlots.filter((item) => {
      return Number(value) < Number(item.id) || Number(item.id) === 0
    });
    setEndTimeSlots(dates);
    if (Number(value) > Number(endTime)) {
      onEndTimeChange("");
      setEndTime("");
    }
  };

  const endTimeSlotChange = (value) => {
    onEndTimeChange(value);
    setEndTime(value);
  };

  const AgentNameChange = (value) => {
    setAgentName(value.target.value);
    onAgentNameChange(value.target.value);
  };
  const CleanAgentName = () => {
    setAgentName("");
    onAgentNameChange("");
    onReset();
  };

  const handleKeydown = (event) => {
    let key = event.which || event.keyCode || event.charCode;
    if (key === 13) {
      onSearch();
    }
  };

  useEffect(() => {
    onDateChange(selectedDate);
    if (selectedDate) {
      setTimeSlotsEnabled(true);
    } else {
      setTimeSlotsEnabled(false);
    }
  }, [selectedDate, onDateChange]);

  useEffect(() => {
    setEndTimeSlots(timeSlots);
  }, [1]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStartTime(defaultStartTime);
    setEndTime(defaultEndTime);
    setSelectedDate(defaultDate);
    setAgentName(defaultAgentName);
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.filter_all_container}>
      <div style={style} className={`${styles.filter_container}`}>
        <label className={styles.timeLabel}>
          {t("general.date_filters.title")}
        </label>
        <div className={`${styles.dropdown_container}`}>
          <DateSelector
            placeholder={t("general.date_filters.date_placeholder")}
            defaultDate={selectedDate}
            getSelectedDate={setSelectedDate}
          />
          <GeneralDropdown
            enabled={timeSlotsEnabled}
            onSelect={startTimeSlotChange}
            placeholder={t("general.date_filters.start_time_placeholder")}
            items={timeSlots}
            selectedItem={startTime.toString().padStart(4, "0")}
            className={`${styles.dropdown_position} ${styles.start_time}`}
          />
          <span className={styles.range_text}>~</span>
          <GeneralDropdown
            enabled={timeSlotsEnabled}
            placeholder={t("general.date_filters.end_time_placeholder")}
            onSelect={endTimeSlotChange}
            items={endTimeSlots}
            selectedItem={endTime.toString().padStart(4, "0")}
            className={`${styles.end_time} ${styles.dropdown_position}`}
          />
        </div>
      </div>
      <div className={`${styles.filter_container} ${styles.name_filter_padding}`}>
        <label className={styles.timeLabel}>
          {t("general.date_filters.staff_name")}
        </label>
        <div className={`${styles.dropdown_container} ${styles.name_filter}`}>
          <ClearableTextBox
            onChange={AgentNameChange}
            onClean={CleanAgentName}
            className={`${styles.name_textbox}`}
            text={agentName}
            onFocus={onFocus}
            onKeyDown={handleKeydown}
          />
          <div className={`${styles.filter_buttons}`}>
            {
              !twoRow && 
              <>
                <GeneralButton
                  text={t("general.buttons.search")}
                  onClick={onSearch}
                />
                <GeneralButton
                  text={t("general.buttons.reset")}
                  onClick={onReset}
                />
                {/* <GeneralButton
                  text={t("general.buttons.research")}
                  onClick={onReSearch}
                 /> */}
              </>
            }
            {/* {
              twoRow && 
              <>
                <div className={styles.first_row}>
                <GeneralButton
                  text={t("general.buttons.search")}
                  onClick={onSearch}
                />
                <GeneralButton
                  text={t("general.buttons.reset")}
                  onClick={onReset}
                />
                </div>
                <div>
                <GeneralButton
                  text={t("general.buttons.research")}
                  onClick={onReSearch}
                />
                </div>
              </>
            } */}
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateFilterCard;
