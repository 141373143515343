import React from 'react';


import classes from './styles.module.css';

const ErrorMessage = ({ message, className, style}) => {
    return (
        <div className={`${classes.error_message} ${className}`} style={style}>
            <p>{message}</p>
        </div>
    )
}

export default ErrorMessage;