import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import EntryPage from "../pages/EntryPage";
import WelcomePage from "../pages/WelcomePage";
import BranchesSelectionPage from "../pages/BranchesSelectionPage";
import AgentListPage from "../pages/AgentListingPage";
import AgentDetailPage from "../pages/AgentDetailPage";
import ARStep1 from "../pages/AppointmentReservationPage/Step1";
import ARStep2 from "../pages/AppointmentReservationPage/Step2";
import ARStep3 from "../pages/AppointmentReservationPage/Step3";
import ThanksPage from "../pages/ThanksPage";
import TestPage from "../pages/Test";
import PageNotFound from "../pages/PageNotFound";
import AiMatchingPage from "../pages/AIMatchingPage";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/welcome" component={EntryPage} />
        <Route exact path="/branches_selection" component={BranchesSelectionPage} />
        
        <Route exact path="/welcome_regular/:branchId" component={WelcomePage} />
        <Route exact path="/matching/:branchId" component={AiMatchingPage} />
        <Route exact path="/agents/:branchId" component={AgentListPage} />
        <Route exact path="/agent_detail/:branchId" component={AgentDetailPage} />
        <Route exact path="/reservation_step_1/:branchId" component={ARStep1} />
        <Route exact path="/reservation_step_2/:branchId" component={ARStep2} />
        <Route exact path="/reservation_step_3/:branchId" component={ARStep3} />
        <Route exact path="/thanks/:branchId" component={ThanksPage} />

        <Route exact path="/welcome_group/:branchId" component={WelcomePage} />
        <Route exact path="/group_reservation_step_1/:branchId" component={ARStep1} />
        <Route exact path="/group_reservation_step_2/:branchId" component={ARStep2} />
        <Route exact path="/group_reservation_step_3/:branchId" component={ARStep3} />
        <Route exact path="/group_thanks/:branchId" component={ThanksPage} />

        <Route exact path="/welcome_mamatas/:branchId" component={WelcomePage} />
        <Route exact path="/mamatas_reservation_step_1/:branchId" component={ARStep1} />
        <Route exact path="/mamatas_reservation_step_2/:branchId" component={ARStep2} />
        <Route exact path="/mamatas_reservation_step_3/:branchId" component={ARStep3} />
        <Route exact path="/mamatas_thanks/:branchId" component={ThanksPage} />

        <Route exact path="/components" component={TestPage} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
