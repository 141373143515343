import React from 'react';

// just need to toggle comments to toggle language flags
import { connect } from 'react-redux';
// import { useTranslation } from 'react-i18next'; //
import { useHistory, useLocation } from 'react-router-dom';

// import { environment } from '../../configs/environments';
import { resetWelcomeSearchingInfo } from '../../store/actions';

import MainLogo from '../Icons/MainLogo';
// import EnglishFlag from '../../assets/images/english_flag.png'; //
// import JapanFlag from '../../assets/images/japan_flag.png'; //
import classes from './styles.module.css';

const HeaderBar = ({ branchId, s_id, locationId, resetWelcomeSearchingInfo, className, style }) => {

    const { pathname } = useLocation();
    const history = useHistory();
    // const { i18n } = useTranslation(); //

    // const [language, setLanguage] = React.useState(i18n.language); //

    const onLogoClick = () => {
        if (pathname !== '/welcome') {
            resetWelcomeSearchingInfo();
            if(locationId){
                history.push({ pathname: '/welcome', search: `?s_id=${branchId || s_id}&l_id=${locationId}` });
            }else{
                history.push({ pathname: '/welcome', search: `?s_id=${branchId || s_id}` });
            }
        }
    }

    
    // const onEnglishFlagClick = () => {
    //     i18n.changeLanguage('en');
    //     setLanguage('en');
    // }

    
    // const onJapanFlagClick = () => {
    //     i18n.changeLanguage('jp');
    //     setLanguage('jp');
    // }

    return (
        <div className={`${classes.header_bar} ${className}`} style={style}>
            <div className={`cmn-inner-width ${classes.header_bar_container}`}>
                <MainLogo className={classes.main_logo} onClick={onLogoClick} />
                {/* comment flags_container div */}
                {/* {
                    (process.env.NODE_ENV === 'development') &&
                    <div className={classes.flags_container}>
                        <img
                            src={EnglishFlag}
                            onClick={onEnglishFlagClick}
                            className={language === 'en' ? classes.selected_flag_icon : classes.flag_icon} alt="english flag" />
                        <img
                            src={JapanFlag}
                            onClick={onJapanFlagClick}
                            className={language === 'jp' ? classes.selected_flag_icon : classes.flag_icon} alt="japan flag" />
                    </div>
                } */}
            </div>
        </div>
    )
};


const stateToProps = state => {
    return {
        s_id: state.s_id,
        l_id: state.l_id
    }
}

const dispatchToProps = dispatch => {
    return {
        resetWelcomeSearchingInfo: () => {
            dispatch(resetWelcomeSearchingInfo());
        }
    }
}

export default connect(stateToProps, dispatchToProps)(HeaderBar);