import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { useMatomo } from "@datapunt/matomo-tracker-react";

import {
    setStep2AppointmentMethod,
    setStep1Insurances,
    setStep1IsHasExperience,
    setStep1Request
} from '../../../store/actions';

import GeneralLoadingButton from '../../Buttons/GeneralLoadingButton';
import GeneralCardView from '../GeneralCard';
import Skills from '../../Icons/SkillBudgeIcon';
import LabelIcon from '../../Icons/IconLabel';
import styles from './styles.module.css';
import defaultImg from '../../../assets/images/no-profile-picture.jpg';

import StoreIcon from '../../../assets/images/icons/store_icon.png';
import { getRandomQueryString, formatPostcode, formatPhoneNo } from '../../../utilities/helper';

const ProfileCard = ({
    style,
    data,
    insuranceTypes,
    selectedInsurancesId,
    setStep1IsHasExperience,
    setStep2AppointmentMethod,
    setStep1Insurances,
    setStep1Request
}) => {

    const { state } = useLocation();
    const { t } = useTranslation();
    const history = useHistory();
    const { trackEvent } = useMatomo();

    const [btnLoading, setBtnLoading] = useState(false);

    const onScheduleMeetUp = async () => {
        setBtnLoading(true);
        const scheduleMeetupButtonEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': 'click, Schedule Meetup'
            }
        }
        TagManager.dataLayer(scheduleMeetupButtonEvent);
        trackEvent({ category: 'onScheduleMeetupSelect', action: 'agent-detail-click-event' });
        setStep1IsHasExperience(1);
        setStep2AppointmentMethod(data.availAppointmentMethod.length === 1 ? data.availAppointmentMethod[0] : 0);
        setStep1Insurances(insuranceTypes.filter(x => selectedInsurancesId.some(y => y === x.id)));
        setStep1Request('');
        history.push({ pathname: `/reservation_step_1/${state.branchId}`, state: { branchId: state.branchId, agentData: data } });
    }

    return (
        <GeneralCardView style={{ style }} className={styles.generalcard_container}>
            <div className={styles.top}>
                <div className={styles.profileImageContainer}>
                    <img src={data.photoId ? data.photoId + getRandomQueryString() : defaultImg} className={styles.profileImage} alt="profile" />
                </div>
                <label className={`${styles.username}`}>{data.agentName}</label>
                <GeneralLoadingButton text={t('general.buttons.schedule_meetup')} loading={btnLoading} className={styles.scheduleMeetupButton} onClick={onScheduleMeetUp} />
            </div>
            <div className={styles.middle}>
                <label className={styles.title}>{t('general.profile_card.branch_name')}</label>
                <LabelIcon className={styles.branchName} icon={StoreIcon} text={data.branchName} />
                <label className={styles.sub_branch_name}>{data.subBranchName}</label>
                <label className={styles.title}>{t('general.profile_card.agency_address')}</label>
                <label className={styles.titleValue}>{data.branchPostcode ? `〒${formatPostcode(data.branchPostcode)} ` : ''}{data.branchAddress}</label>
                <label className={styles.title}>{t('general.profile_card.agency_contact_info')}</label>
                <label className={styles.titleValue}>{formatPhoneNo(data.branchContact)}</label>
                {/* <label className={styles.title}>{t('general.profile_card.area_of_responsibility')}</label>
                <label className={styles.titleValue}>{data.agentLocation}</label> */}
                <label className={styles.title}>{t('general.profile_card.knowledgable_insurances')}</label>
                <div className={styles.skill_container}>
                    {
                        data.goodAtInsurance && data.goodAtInsurance.sort((a, b) => a.id - b.id).map((skill, index) => {
                            return <Skills skill={skill} key={index} />;
                        })
                    }
                </div>
                <label className={styles.title}>{t('general.profile_card.qualifications')}</label>
                <label className={styles.titleValue}>{(data.certificate === '' || data.certificate === null) ? '-' : data.certificate}</label>
            </div>
        </GeneralCardView>
    )
};

const stateToProps = state => {
    return {
        insuranceTypes: state.insuranceTypes,
        selectedInsurancesId: state.selectedInsurancesId
    }
}

const dispatchToProps = dispatch => {
    return {
        setStep1IsHasExperience: (isHasExperience) => {
            dispatch(setStep1IsHasExperience(isHasExperience));
        },
        setStep2AppointmentMethod: (appointmentMethod) => {
            dispatch(setStep2AppointmentMethod(appointmentMethod));
        },
        setStep1Insurances: (insuranceTypes) => {
            dispatch(setStep1Insurances(insuranceTypes));
        },
        setStep1Request: (requestMessage) => {
            dispatch(setStep1Request(requestMessage));
        }
    }
}

export default connect(stateToProps, dispatchToProps)(ProfileCard);