import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import classes from "./styles.module.css";
import Calendar from "../Calendar/index";
import GeneralButton from "../Buttons/GeneralButton";

const DateSelector = ({
    defaultDate,
    getSelectedDate,
    placeholder,
    className,
}) => {
    const list = useRef();
    const elementRef = useRef(null);
    const { t } = useTranslation();

    // const [height, setHeight] = useState();
    // ul
    // ${height > 250 ? classes.over_height : ""}
    const [isOpen, setIsOpen] = useState(false);
    const [selectedText, setSelectedText] = useState(null);
    const [displayText, setDisplayText] = useState(null);

    useEffect(() => {
        setIsOpen(false);
        getSelectedDate(null);
        setDisplayText(null);
        if (selectedText) {
            var date = new Date(selectedText).toLocaleString("ja", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });

            if (date !== "Invalid Date") {
                setDisplayText(date);
            }
        }
        getSelectedDate(selectedText);
    }, [selectedText, getSelectedDate]);

    const onOpen = () => {
        setIsOpen(true);
        list.current.focus();
    };

    const clear = () => {
        setIsOpen(false);
        getSelectedDate(undefined);
        setDisplayText(t("general.date_filters.date_placeholder"));
    };

    const cancel = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setIsOpen(false);
        }
    };

    return ( <div className = { `${classes.select_box} ${className}` } >
                <div 
                    onClick = { onOpen } 
                    className = { displayText === null && placeholder === null ? classes.blank_placeholder : ""}
                >
                <span> { displayText !== null ? displayText : placeholder } </span> 
                </div> 
                <div 
                    tabIndex = "0"
                    ref = { list }
                    onBlur = { (e) => cancel(e) } >
                    <ul className = { `${isOpen ? classes.showbox : classes.hidebox} ` } ref = { elementRef } >
                    <div>
                        <Calendar onSelect = { setSelectedText } selectedDate = { defaultDate } clickPosition = 'agents'/> 
                        <div className = { classes.button_container } >
                        <GeneralButton text = { t("general.buttons.clear") } className = { classes.clear_button } onClick = { clear }/> 
                        </div> 
                    </div> 
                    </ul> 
                </div> 
            </div>
    );
};

export default DateSelector;