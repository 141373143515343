import React from 'react';
import {  useLocation } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useMatomo } from "@datapunt/matomo-tracker-react";

import GeneralButton from '../../components/Buttons/GeneralButton';
import BackButton from '../../components/Buttons/BackButton';
import ErrorMessage from '../../components/ErrorMessage';
import Mandatory from '../../components/MandatoryLabel';
import PageNotFound from '../PageNotFound';

import classes from './styles.module.css';


const SympathyQuestionsPage = ({  
    sympathyFirstAnswer,
    setSympathyFirstAnswer,
    sympathySecondAnswer,
    setSympathySecondAnswer,
    sympathyThirdAnswer,
    setSympathyThirdAnswer,
    sympathyQuestions,
    sympathyQuestionsError,
    onSubmit,
    onBack
 }) => {

    const { state } = useLocation();
    const { t } = useTranslation();
    const { trackEvent } = useMatomo()

    const onFirstAnswerClick = (answer) => {
        setSympathyFirstAnswer(answer)
        trackEvent({ category: 'onSympathyQuestionsAnswer', action: 'matching-click-event' })
    }

    const onSecondAnswerClick = (answer) => {
        setSympathySecondAnswer(answer)
        trackEvent({ category: 'onSympathyQuestionsAnswer', action: 'matching-click-event' })
    }

    const onThirdAnswerClick = (answer) => {
        setSympathyThirdAnswer(answer)
        trackEvent({ category: 'onSympathyQuestionsAnswer', action: 'matching-click-event' })
    }

    // const onFocus = () => {
    //     if (sympathyFirstAnswer === undefined) {
    //         document.getElementById("question1").focus({ preventScroll: false });
    //     } else if (sympathySecondAnswer === undefined) {
    //         document.getElementById("question2").focus({ preventScroll: false });
    //     } else if (sympathyThirdAnswer === undefined) {
    //         document.getElementById("question3").focus({ preventScroll: false });
    //     }
    // }
    
    // window.onfocus = onFocus();
    return (
        (state === undefined || state.branchId === null || state.branchId === '') ?
            <PageNotFound />
            :
            sympathyQuestions &&
            <>
                <Row className={`row ${classes.ai_img_box_row}`}>
                    <Col lg="12">
                        <div className={classes.question_container}>
                            <h5 tabIndex="0" id="question1" >{sympathyQuestions[0].descriptionForUser}</h5>
                            <Mandatory text={t('general.fields.mandatory')} />
                        </div>
                    </Col>
                    {
                        sympathyQuestions[0].questionItems.map((answer, index) => (
                            <Col lg="3" xs="6" key={index} onClick={() => onFirstAnswerClick(answer)}>
                                <img src={answer.content} alt="profile" className={`${(sympathyFirstAnswer && sympathyFirstAnswer.itemId === answer.itemId) ? classes.active : ''} w-100`} />
                            </Col>
                        ))
                    }
                </Row>
                <Row className={`row ${classes.ai_img_box_row}`}>
                    <Col lg="12">
                        <div className={classes.question_container}>
                            <h5 tabIndex="0" id="question2">{sympathyQuestions[1].descriptionForUser}</h5>
                            <Mandatory text={t('general.fields.mandatory')} />
                        </div>
                    </Col>
                    {
                        sympathyQuestions[1].questionItems.map((answer, index) => (
                            <Col lg="3" xs="6" key={index} onClick={() => onSecondAnswerClick(answer)}>
                                <img src={answer.content} alt="profile" className={`${(sympathySecondAnswer && sympathySecondAnswer.itemId === answer.itemId) ? classes.active : ''} w-100`} />
                            </Col>
                        ))
                    }
                </Row>
                <Row className={`row ${classes.ai_img_box_row}`}>
                    <Col lg="12">
                        <div className={classes.question_container}>
                            <h5 tabIndex="0" id="question3">{sympathyQuestions[2].descriptionForUser}</h5>
                            <Mandatory text={t('general.fields.mandatory')} />
                        </div>
                    </Col>
                    {
                        sympathyQuestions[2].questionItems.map((answer, index) => (
                            <Col lg="3" xs="6" key={index} onClick={() => onThirdAnswerClick(answer)}>
                                <img src={answer.content} alt="profile" className={`${(sympathyThirdAnswer && sympathyThirdAnswer.itemId === answer.itemId) ? classes.active : ''} w-100`} />
                            </Col>
                        ))
                    }
                </Row>
                {
                    sympathyQuestionsError &&
                    <Row id ="sympath_question_error" >
                        <Col>
                            <ErrorMessage message={t('ai_matching_page.error_message')} />
                        </Col>
                    </Row>
                }
                <Row className={classes.buttons_container} >
                    <Col className="d-flex justify-content-between align-items-center">
                        <GeneralButton
                            id="btn"
                            text={t('general.buttons.find_insurance_agent')}
                            onClick={onSubmit}
                            className={`order-2 ${classes.find_agent_btn}`} />
                        <BackButton onClick={onBack} className="order-1" />
                    </Col>
                </Row>
            </>
    )
}

export default SympathyQuestionsPage;