import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { useMatomo } from "@datapunt/matomo-tracker-react";

import { setSelectedBirthYear, setSelectedFamilyStr, setSelectedGenderId, setInsuranceTypes, setAnsweredDirectQns, setAnsweredSympathyQns, setDirectQuestions, setSympathyQuestions, setSelectedBranch, setLoginUser } from '../../../store/actions';

import GeneralDropdown from '../../../components/Dropdowns/GeneralDropdown';
import DobDropdown from '../../../components/Dropdowns/DobDropdown';
import GenderCard from '../../../components/CardViews/GenderCard';
import NextButton from '../../../components/Buttons/NextButton';

import { getInfoForSearching, getDirectQuestions, getSympathyQuestions, getBranchListByAgencyBranch } from '../../../api/Welcome/searchingAPI';

import classes from './styles.module.css';

const SearchingForm = ({
    userId,
    branchId,
    selectedBirthYear,
    selectedFamilyStr,
    selectedGenderId,
    setInsuranceTypes,
    setSelectedBirthYear,
    setSelectedFamilyStr,
    setSelectedGenderId,
    setDirectQuestions,
    setSympathyQuestions,
    setAnsweredDirectQns,
    setAnsweredSympathyQns,
    setSelectedBranch,
    className,
    setLoginUser,
}) => {

    const { t } = useTranslation();
    const { trackEvent } = useMatomo()

    const history = useHistory();
    const isMountedRef = useRef();
    const [dobList, setDobList] = useState();
    const [familyStructureList, setFamilyStructureList] = useState();
    const [genderList, setGenderList] = useState();
    const [birthYear, setBirthYear] = useState(selectedBirthYear);
    const [familyStructure, setFamilyStructure] = useState(selectedFamilyStr);
    const [gender, setGender] = useState(selectedGenderId);
    const [nextBtnLoading, setNextBtnLoading] = useState(false);

    useEffect(() => {
        isMountedRef.current = true
        const setData = async () => {
            const data = await getInfoForSearching();
            if (isMountedRef.current) {
                if(!userId){
                    setLoginUser(data.userId);
                }
                setInsuranceTypes(data.insuranceType);
                setDobList(data.birthYear);
                setFamilyStructureList(data.familyStructure);
                setGenderList(data.gender);
            }
        };
        setData();
        return () => { isMountedRef.current = false }
    }, [setInsuranceTypes, setLoginUser, userId]);

    const onBirthYearSelect = (id, name) => {
        setBirthYear({ id, name });
        trackEvent({ category: 'onBirthYearSelect', action: 'welcome-click-event' })
    }

    const onFamilyStructureSelect = (id, name) => {
        setFamilyStructure({ id, name });
        trackEvent({ category: 'onFamilyStructureSelect', action: 'welcome-click-event' })
    }

    const onGenderSelect = (id, name) => {
        setGender({ id, name });
        trackEvent({ category: 'onGenderSelect', action: 'welcome-click-event' })
    }

    const onNext = async () => {
        const nextButtonEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': 'click, Answer Insurance Types, Year of Birth, Family Structure and Gender for matching'
            }
        }
        TagManager.dataLayer(nextButtonEvent);
        trackEvent({ category: 'onNext', action: 'welcome-click-event' });
        setNextBtnLoading(true);

        const onDirectSuccess = async (data) => {
            setDirectQuestions(data);
            setAnsweredDirectQns([]);
            setAnsweredSympathyQns([]);
            setSelectedBirthYear(birthYear);
            setSelectedFamilyStr(familyStructure);
            setSelectedGenderId(gender);
            setSelectedBranch(undefined);

            await getSympathyQuestions(onSympathySuccess, onSympathyFail);
        }

        const onDirectFail = (error) => {
            console.log(error);
        }

        const onSympathySuccess = async (data) => {
            setSympathyQuestions(data);
            await getBranchListByAgencyBranch({ mainBranchId: branchId }, onBranchSuccess, onBranchFail);
        }

        const onSympathyFail = (error) => {
            console.log(error);
        }

        const onBranchSuccess = (data) => {
            window.scrollTo(0, 0);
            setNextBtnLoading(false);
            history.push({ pathname: `/matching/${branchId}`, state: { branchId, branchListData: data } });
        }

        const onBranchFail = (error) => {
            console.log(error);
        }

        await getDirectQuestions(onDirectSuccess, onDirectFail);
    }

    return (
        <div className={`${classes.search_form} ${className}`}>
            <h4 className={classes.title}>{t('home_search_page.searching_form.title2')}</h4>
               <div></div>
            <div>
                <p className={`mb-2 ${classes.sub_title}`}>{t('general.dropdowns.year_of_birth.title')}</p>
                {
                    dobList &&
                    <DobDropdown
                        items={dobList}
                        selectedItem={birthYear.id}
                        onSelect={onBirthYearSelect}
                        placeholder={t('general.dropdowns.year_of_birth.placeholder')} />
                }
            </div>

            <div>
                <p className={`mb-2 ${classes.sub_title}`}>{t('general.dropdowns.family_structure.title')}</p>
                {
                    familyStructureList &&
                    <GeneralDropdown
                        items={familyStructureList}
                        selectedItem={familyStructure.id}
                        onSelect={onFamilyStructureSelect}
                        className={classes.family_structure_dropdown}
                        placeholder={t('general.dropdowns.family_structure.placeholder')} />
                }
            </div>

            {
                genderList &&
                <GenderCard
                    items={genderList}
                    defaultItem={gender.id}
                    onSelect={onGenderSelect}
                    className={classes.gender_card} />
            }

            <div className={classes.next_btn_container}>
                <NextButton text={t('general.buttons.next')} onClick={onNext} loading={nextBtnLoading} />
            </div>
        </div>
    )
}


const stateToProps = state => {
    return {
        userId: state.userId,
        insuranceTypes: state.insuranceTypes,
        selectedInsurancesId: state.selectedInsurancesId,
        selectedBirthYear: state.selectedBirthYear,
        selectedFamilyStr: state.selectedFamilyStr,
        selectedGenderId: state.selectedGenderId
    }
}

const dispatchToProps = dispatch => {
    return {
        setInsuranceTypes: (insuranceTypes) => {
            dispatch(setInsuranceTypes(insuranceTypes));
        },
        setSelectedBirthYear: (birthYear) => {
            dispatch(setSelectedBirthYear(birthYear));
        },
        setSelectedFamilyStr: (familyStructure) => {
            dispatch(setSelectedFamilyStr(familyStructure));
        },
        setSelectedGenderId: (genderId) => {
            dispatch(setSelectedGenderId(genderId));
        },
        setDirectQuestions: (directQuestions) => {
            dispatch(setDirectQuestions(directQuestions));
        },
        setAnsweredDirectQns: (answeredQns) => {
            dispatch(setAnsweredDirectQns(answeredQns));
        },
        setAnsweredSympathyQns: (answeredQns) => {
            dispatch(setAnsweredSympathyQns(answeredQns));
        },
        setSympathyQuestions: (directQuestions) => {
            dispatch(setSympathyQuestions(directQuestions));
        },
        setSelectedBranch: (selectedBranch) => {
            dispatch(setSelectedBranch(selectedBranch));
        },
        setLoginUser: (userId) => {
            dispatch(setLoginUser(userId))
        }
    }
}

export default connect(stateToProps, dispatchToProps)(SearchingForm);