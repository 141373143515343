import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import TagManager from 'react-gtm-module';
import { useMatomo } from "@datapunt/matomo-tracker-react";

import { resetWelcomeSearchingInfo } from '../../store/actions';

import HeaderBar from '../../components/HeaderBar';
import FooterBar from '../../components/FooterBar';
import PageNotFound from '../PageNotFound';
import classes from './styles.module.css';
// import GeneralButton from '../../components/Buttons/GeneralButton';

const pageview = {
    dataLayer: {
        event: 'pageview',
        log_pageurl: '',
        log_pagename: ''
    }
}

const ThanksPage = ({ s_id, resetWelcomeSearchingInfo }) => {
    const history = useHistory();
    const { state } = useLocation();
    const { t } = useTranslation();
    const { trackPageView } = useMatomo();

    document.title = !state.locationId ? '送信完了ページ' : state.locationId === 'mamatas' ? '送信完了ページ（ママタス）': '送信完了ページ（団体予約）';

    useEffect(() => {
        trackPageView();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        TagManager.dataLayer(pageview);
        window.addEventListener(
            "popstate",
            function (e) {
                const len = window.location.pathname.split('/').length;
                if (window.location.pathname.split('/')[len - 1] === "appointment_reservation_step_3") {
                    resetWelcomeSearchingInfo();
                    if(state.locationId){
                        history.push({ pathname: '/welcome', search: `?s_id=${s_id}&l_id=${state.locationId}`  });
                    }else{
                        history.push({ pathname: '/welcome', search: `?s_id=${s_id}` });
                    }
                }
            },
            false
        )
    }, [history, resetWelcomeSearchingInfo, s_id]); // eslint-disable-line react-hooks/exhaustive-deps

    const renderEmail = () => {
        if(state.customerEmail && state.companyEmail){
            return (
                <>
                    <a href="mailto:cat@neco.com" className={`font-14 ${classes.email_address}`}>{state.customerEmail}</a>
                    、<a href="mailto:cat@neco.com" className={`font-14 ${classes.email_address}`}>{state.companyEmail}</a>
                </>
            );
        }else {
            return (
                <>
                  <a href="mailto:cat@neco.com" className={`font-14 ${classes.email_address}`}>{state.customerEmail ? state.customerEmail : state.companyEmail}</a>
                </>
            );
        }
    }

    return (
        (state === undefined || state.branchId === null || state.branchId === '') ?
            <PageNotFound />
            :
            <div>
                <HeaderBar locationId={state.locationId} />
                <div className={`${classes.thanks_page} cmn-inner-width cmn-height`}>
                    <Row>
                        <Col lg="8" className="m-auto text-center">
                            <h1 className={`${classes.title}`}>{t('thanks_page.thanks')}</h1>
                            <h5 className={`${classes.sub_title}`}>{t('thanks_page.text1')}</h5>
                            {state.locationId === 'mamatas' ?  
                                <>{state.customerEmail &&
                                    <h5 className={`${classes.sub_title}`}>
                                        {t('thanks_page.text2')}
                                        {renderEmail()}
                                        {t('thanks_page.text2_1')}
                                    </h5>
                                }</>
                                :
                                <h5 className={`${classes.sub_title}`}>
                                    {t('thanks_page.text2')}
                                    {renderEmail()}
                                    {t('thanks_page.text2_1')}
                                </h5>
                            }
                            <h5 className={`${classes.sub_title}`}>{t('thanks_page.text3')}</h5>
                            {/* <GeneralButton text={t('general.buttons.ok')} className={classes.ok_btn} onClick={handleOkClick} /> */}
                        </Col>
                    </Row>
                </div>
                <FooterBar />
            </div>
    )
}


const stateToProps = state => {
    return {
        s_id: state.s_id
    }
}

const dispatchToProps = dispatch => {
    return {
        resetWelcomeSearchingInfo: () => {
            dispatch(resetWelcomeSearchingInfo());
        }
    }
}

export default connect(stateToProps, dispatchToProps)(ThanksPage);