import React from 'react';
import { Label } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { useMatomo } from "@datapunt/matomo-tracker-react"

import ViewProfileIcon from '../../../assets/images/icons/view_profile.png';
import DefaultImg from '../../../assets/images/no-profile-picture.jpg';

import styles from './styles.module.css'
import GeneralButton from '../../Buttons/GeneralButton';
import TextIcon from '../../Icons/TextIcon';
import Skills from '../../Icons/SkillBudgeIcon';
import StoreIcon from '../../../assets/images/icons/store_icon.png';
import LabelIcon from '../../Icons/IconLabel';
import { getRandomQueryString } from '../../../utilities/helper';

const AgentCard = ({ data, setHistory }) => {
    const { state } = useLocation();
    const { t } = useTranslation();
    const history = useHistory();
    const { trackEvent } = useMatomo()
    const onDetail = (id) => {
        const viewProfileButtonEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': 'click, View Agent Profile'
            }
        }
        trackEvent({ category: 'onAgentProfileView', action: 'agents-click-event' })
        TagManager.dataLayer(viewProfileButtonEvent);
        setHistory();
        history.push({ pathname: `/agent_detail/${state.branchId}`, state: { branchId: state.branchId, agentData: data } });
    }

    return (
        <Container className={styles.agentcard_container}>
            <Row className={`${styles.agentcard_row} h-100`}>
                <Col xs={12} sm={3} md={3} lg={3} className={`${styles.first_column} ${styles.small_padding}`} >
                    <div className={styles.imageContainer}>
                        <img src={data.photoId ? data.photoId + getRandomQueryString() : DefaultImg} className={styles.profileImage} alt="user profile" />
                    </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} className={`${styles.second_column} ${styles.small_padding}`}>
                    <div>
                        <Label className={styles.username}>{data.agentName}</Label>
                        <LabelIcon icon={StoreIcon} text={data.branchName} className={styles.agentlocation} />
                        <Label className={styles.paragraph}>{data.description}</Label>
                    </div>
                    <div>
                        <div className={styles.divider}></div>
                        <div className={`${styles.icon_container} ${styles.middle_align}`}>
                            {data.availAppointmentMethod && data.availAppointmentMethod.sort((a, b) => a.id - b.id).map((appointmentMethod, index) => {
                                return <TextIcon text={appointmentMethod.name.concat("可")} className={styles.textcategory} key={index} />;
                            })
                            }
                        </div>
                        <div className={styles.middle_align}>
                            {data.goodAtInsurance && data.goodAtInsurance.sort((a, b) => a.id - b.id).map((skill, index) => {
                                return <Skills skill={skill} key={index} />;
                            })
                            }
                        </div>
                    </div>

                </Col>
                <Col xs={12} sm={3} md={3} lg={3} className={` ${styles.third_column} ${styles.small_padding}`} >
                    <div className={styles.experience_container}>
                        <div className={styles.experience}>
                            <div>
                                <Label className={styles.third_columnTitle}>{t('general.agent_card.years_of_experience')}<span>&nbsp;:&nbsp;</span></Label>
                            </div>
                            <Label className={styles.third_columnTitleValue} >{data.workYears} {t('general.agent_card.years')}</Label>
                        </div>
                        {/* <div className={styles.experience}>
                            <Label className={styles.thirdColumnTitleValue}>{t('general.agent_card.request_record')}</Label>
                            <Label className={styles.thirdColumnTitle}>{data.caseAmount} {t('general.agent_card.times')}</Label>
                        </div> */}
                    </div>
                    <div className={styles.button_container}>
                        <GeneralButton text={t('general.buttons.view_profile')} frontIcon={ViewProfileIcon} className={` ${styles.viewProfileBtn}`} onClick={() => onDetail(data.agentId)} />
                    </div>

                </Col>
            </Row>
        </Container>
    )
};

export default AgentCard;