import { base_api_url } from '../../configs/environments';
import api_urls from '../../routes/api_urls';
import { defaultHeaderOptions } from '../headerOptions';


import eventBus from '../../EventBus';

const getInfoForSearching = () => {

    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions
    };

    const result = fetch(`${base_api_url}${api_urls.get_info_for_searching}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }

            return data;
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('Searching Info api error - ', error);
            return error;
        });

    return result;
}

const getInsuranceTypes = (onSuccess, onFail) => {

    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions
    };

    fetch(`${base_api_url}${api_urls.get_info_for_searching}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }

            onSuccess && onSuccess(data.insuranceType);
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('Insurance Types api error - ', error);
            onFail && onFail(error);
        });
}

const getAppointmentMethods = (onSuccess, onFail) => {

    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions
    };

    const result = fetch(`${base_api_url}${api_urls.get_info_for_searching}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }

            return data.availAppointmentMethod;
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('Appointment methods api error - ', error);
        });

    return result;
}

const getDirectQuestions = (onSuccess, onFail) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions
    };
    fetch(`${base_api_url}${api_urls.get_direct_questions}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            onSuccess && onSuccess(data);
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('Get Direct Questions api error - ', error);
            onFail && onFail(error);
        });
}

const getBranchListByAgencyBranch = (body, onSuccess, onFail) => {

    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions,
        body: JSON.stringify(body)
    }

    fetch(`${base_api_url}${api_urls.get_branch_list_by_agency_branch}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            onSuccess && onSuccess(data);
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('Get branch list by agency branch api error - ', error);
            onFail && onFail(error);
        });
}

const getMamatasBranchList = (onSuccess, onFail) =>{
    const requestOptions = {
        method: 'GET',
        headers: defaultHeaderOptions,
    }

    fetch(`${base_api_url}${api_urls.get_mamatas_branch_list}`, requestOptions)
    .then(async response => {
        const data = await response.json();

        if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        onSuccess && onSuccess(data);
    })
    .catch(error => {
        eventBus.dispatch("something_went_wrong");
        console.error('Get mamatas branch list api error - ', error);
        onFail && onFail(error);
    });
}

const getSympathyQuestions = (onSuccess, onFail) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions
    };
    fetch(`${base_api_url}${api_urls.get_sympathy_questions}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            onSuccess && onSuccess(data);
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('Get Sympathy Questions api error - ', error);
            onFail && onFail(error);
        });
}

export {
    getInfoForSearching,
    getInsuranceTypes,
    getAppointmentMethods,
    getDirectQuestions,
    getBranchListByAgencyBranch,
    getSympathyQuestions,
    getMamatasBranchList
};