import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import ErrorMessage from "../../components/ErrorMessage";
import BranchMI from "../../components/MenuItems/BranchMI";
import PageNotFound from "../PageNotFound";
import Mandatory from "../../components/MandatoryLabel";

import { isMainBranch } from "../../utilities/helper";
import classes from "./styles.module.css";


const BranchesSelectionPage = ({
  selectedBranch,
  setSelectedBranch,
  branchError,
  setBranchError,
}) => {
  const { trackEvent } = useMatomo();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [branchList] = useState(
    state?.branchListData?.filter((branch) => !isMainBranch(branch.branchId)) ||
      []
  );

  const onSelectBranch = (branch) => {
    setBranchError(false);
    setSelectedBranch(branch);
    trackEvent({ category: 'onDirectQuestionsAnswer', action: 'matching-click-event' })
  };

  return state === undefined ||
    state.branchId === null ||
    state.branchId === "" ? (
    <PageNotFound />
  ) : (
    <>
      <div className={classes.question_container}>
        <h5>{t("branches_selection_page.title")}</h5>
        <Mandatory text={t("general.fields.mandatory")} />
      </div>
      <div className={classes["branch-list-container"]}>
        <Row>
          <Col>
            {Array.isArray(branchList) && branchList.length === 0 ? (
              <h3 className={classes["no_branch"]}>
                {t("branches_selection_page.no_branch")}
              </h3>
            ) : (
              branchList &&
              branchList.map((branch, index) => (
                <BranchMI
                  key={index}
                  selectedBranch={selectedBranch}
                  branch={branch}
                  onClick={() => onSelectBranch(branch)}
                  className={classes["branch-mi"]}
                />
              ))
            )}
          </Col>
        </Row>
        {Array.isArray(branchList) && branchList.length !== 0 && branchError && (
          <Row id="branch_list_error">
            <Col>
              <ErrorMessage
                message={t("branches_selection_page.error_message")}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default BranchesSelectionPage;
