import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import moment from 'moment';
import { useMatomo } from "@datapunt/matomo-tracker-react";

import {
    setStep1Insurances,
    setStep1IsHasExperience,
    setStep1Request,
    setStep2Date,
    setStep1DiscussStatus,
    setStep1DiscussStatusText,
    setStep1TalkingDemand,
    setStep1TalkinngDemandText,
    setStep2DefaultExceptDate,
    setBreadcrumbsPath
} from '../../../store/actions';

import ARContainer from '../index';
import AppointmentStepper from '../AppointmentStepper';
import GeneralTextArea from '../../../components/TextAreas/GeneralTextArea';
import BackButton from '../../../components/Buttons/BackButton';
import NextButton from '../../../components/Buttons/NextButton';
import RadioButton from '../../../components/RadioButtons';
import StyledCheckbox from '../../../components/Checkbox';
import ErrorMessage from '../../../components/ErrorMessage';
import Mandatory from '../../../components/MandatoryLabel';
import PageNotFound from '../../PageNotFound';

import { getTomorrowDate4Calendar, getNext3WorkdayDate4Calendar } from '../../../api/Appointment/Step2';
import { last_date } from '../../../utilities/calendar';
import { useNonInitialEffect } from '../../../utilities/customHooks';
import { countWord } from '../../../utilities/helper';

import classes from './styles.module.css';

const pageview = {
    dataLayer: {
        event: 'pageview',
        log_pageurl: '',
        log_pagename: ''
    }
}

const ARStep1 = ({
    step1IsHasExperience,
    step1InsuranceTypes,
    step1RequestMessage,
    setStep1IsHasExperience,
    setStep1Insurances,
    setStep1Request,
    setStep2Date,
    steps,
    setStep2DefaultExceptDate,
    step1DiscussStatus,
    step1DiscussStatusText,
    setStep1DiscussStatus,
    setStep1DiscussStatusText,
    step1TalkingDemand,
    step1TalkinngDemandText,
    setStep1TalkingDemand,
    setStep1TalkinngDemandText,
    breadcrumbsPath,
    setBreadcrumbsPath,
}) => {

    const { state, pathname } = useLocation();
    const { t } = useTranslation();
    const history = useHistory();
    const { trackPageView, trackEvent } = useMatomo();
    
    document.title = !state.locationId ? '相談予約Step1' : state.locationId === 'mamatas' ? '相談予約Step1（ママタス）': '相談予約Step1（団体予約）';

    const q1 = steps.items.filter((item) => item.questionNo === "01")[0];
    const q2 = steps.items.filter((item) => item.questionNo === "02")[0];
    const q4 = steps.items.filter((item) => item.questionNo === "04")[0];
    const q20 = steps.items.filter((item) => item.questionNo === "20")[0];
    const q21 = steps.items.filter((item) => item.questionNo === "21")[0];

    const [isHasExperienceBefore, setIsHasExperienceBefore] = useState(step1IsHasExperience);
    const [discussStatus, setDiscussStatus] = useState(step1DiscussStatus ? step1DiscussStatus : q20.questionActive === 1 ? q20.options.filter((item)=> item.optionActive === 1)[0].optionContent : '');
    const [discussStatusText, setDiscussStatusText] = useState(step1DiscussStatusText);
    const [isDiscussStatusText, setIsDiscussStatusText] = useState("");
    const [selectedInsurances, setSelectedInsurances] = useState(step1InsuranceTypes);
    const [insurancesError, setInsurancesError] = useState(false);
    const [requestMessage, setRequestMessage] = useState(step1RequestMessage);
    const [isRequestMessage, setIsRequestMessage] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [showDiscussStatusText, setShowDiscussStatusText] = useState(false);
    const [showCompnents, setShowComponents] = useState(false);
    const [selectedTalkingDemand, setSelectedTalkingDemand] = useState(step1TalkingDemand ? step1TalkingDemand : []);
    const [talkingDemandText, setTalkingDemandText] = useState(step1TalkinngDemandText);
    const [showTalkingDemandText, setShowTalkingDemandText] = useState(false);
    const [isDemandtError, setIsDemandtError] = useState(true);

    useEffect(() => {
        TagManager.dataLayer(pageview);
        if (isSkip()) {
            onNext();
        }else{
            setShowComponents(true);
        }
        trackPageView();
        if(!state.locationId){
            let pathnames = [`agents/${state.branchId}`, `agent_detail/${state.branchId}`, pathname.substring(1)];
            setBreadcrumbsPath(pathnames);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if(discussStatus === "その他"){
            setShowDiscussStatusText(true);
        }else{
            setShowDiscussStatusText(false);
        }
    }, [discussStatus]);

    useEffect(() => {
        if(selectedTalkingDemand.some(item => item.name === 'その他')){
            setShowTalkingDemandText(true);
        }else{
            setShowTalkingDemandText(false);
        }
    },[selectedTalkingDemand])

    useNonInitialEffect(() => {
        selectedInsurances.length === 0 ? setInsurancesError(true) : setInsurancesError(false);
    }, [selectedInsurances]);

    const isSkip = () => {
        if ((q20.questionActive === 0 && q4.questionActive === 0) || q21?.questionActive === 0) {
            return true;
        }else{
            return false;
        }
    }

    const handleAgentBefore = (e, name) => {
        setIsHasExperienceBefore(parseInt(e.target.value));
        trackEvent({ category: 'onInsuranceExperienceSelect', action: 'step1-click-event' });
    }

    const isInsuranceCheck = (item) => {
        return selectedInsurances.some(x => x.id === item);
    }

    const handleDiscussStatus = (value) => {
        setDiscussStatus(value);
        trackEvent({ category: 'onDiscussStatusSelect', action: 'step1-click-event' });
    }

    const onInsuranceChecked = (id, name) => {
        const insuranceTypeCheckboxEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': `click, Check ${name} Insurance for Appointment`
            }
        }
        TagManager.dataLayer(insuranceTypeCheckboxEvent);
        setSelectedInsurances([...selectedInsurances, { id, name }]);
        trackEvent({ category: 'onInsuranceTypeSelect', action: 'step1-click-event' });
    }

    const onInsuranceUnchecked = (id, name) => {
        const insuranceTypeCheckboxEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': `click, Uncheck ${name} Insurance for Appointment`
            }
        }
        TagManager.dataLayer(insuranceTypeCheckboxEvent);
        setSelectedInsurances(selectedInsurances.filter(x => x.id !== id));
        trackEvent({ category: 'onInsuranceTypeSelect', action: 'step1-click-event' });
    }

    const onDemandChecked = (id, name) => {
        const talkingDemandCheckboxEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': `click, Check ${name} Demand for Appointment`
            }
        }
        TagManager.dataLayer(talkingDemandCheckboxEvent);
        setSelectedTalkingDemand([...selectedTalkingDemand, { id, name }]);
        trackEvent({ category: 'onDemandSelect', action: 'step1-click-event' });
    }

    const onDemandUnchecked = (id, name) => {
        const talkingDemandCheckboxEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': `click, Uncheck ${name} Demand for Appointment`
            }
        }
        TagManager.dataLayer(talkingDemandCheckboxEvent);
        setSelectedTalkingDemand(selectedTalkingDemand.filter(item => item.id !== id));
        trackEvent({ category: 'onDemandSelect', action: 'step1-click-event' });
    }

    const isDemandCheck = (item) => {
        return selectedTalkingDemand.some(x => x.id === item);
    }

    const onTextAreaFocus = (eventCategory) => {
        trackEvent({ category: eventCategory, action: 'step1-input-event' });
    }

    const onBack = () => {
        const backButtonEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': 'click, Back to show agent detail'
            }
        }
        TagManager.dataLayer(backButtonEvent);
        trackEvent({ category: 'onBack', action: 'step1-click-event' });
        history.goBack();
    }

    const onNext = async () => {
        const nextButtonEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': 'click, Answer step 1 of Appointment Reservation'
            }
        }
        TagManager.dataLayer(nextButtonEvent);
        trackEvent({ category: 'onNext', action: 'step1-click-event' });
        selectedInsurances.length === 0 ? setInsurancesError(true) : setInsurancesError(false);

        if (q1?.questionActive && isHasExperienceBefore === 0) {
            return;
        }
        
        if(!state.locationId){
            if(selectedInsurances.length === 0){
                return;
            }
        }
        
        if (q4.questionActive && state.locationId && state.locationId === 'reservation') {
            if (isRequestMessage === 'lengthError') {
                return;
            }
            else {
                setIsRequestMessage('');
            }
        }

        if(q20.questionActive && state.locationId && state.locationId === 'reservation'){
            if(isDiscussStatusText === 'lengthError'){
                return;
            }else{
                setIsDiscussStatusText('')
            }
        }

        if(q21?.questionActive && state.locationId && state.locationId === 'mamatas'){
            if(isDemandtError === 'lengthError'){
                return;
            }else{
                setIsDemandtError('')
            }
        }

        
        const onSuccess = (tmrDate) => {
            if (tmrDate && (moment(last_date) > moment(tmrDate))) {
                setStep2Date(tmrDate);
            } else {
                setStep2Date('');
            }
            setStep1IsHasExperience(state.locationId ? '' : isHasExperienceBefore);
            setStep1Insurances(selectedInsurances);
            setStep1Request(requestMessage);
            if(q20.questionActive === 1 && state.locationId && state.locationId === 'reservation'){
                setStep1DiscussStatus(discussStatus);
                if(discussStatus === "その他"){
                    setStep1DiscussStatusText(discussStatusText);
                }else{
                    setStep1DiscussStatusText('');
                }
            }else{
                setStep1DiscussStatusText('');
                setStep1DiscussStatus('');
            }
            if(q21?.questionActive === 1 && state.locationId === 'mamatas'){
                selectedTalkingDemand.sort((a, b) => {
                    return a.id - b.id
                });
                setStep1TalkingDemand(selectedTalkingDemand);
                if(selectedTalkingDemand.some(item => item.name === 'その他')){
                    setStep1TalkinngDemandText(talkingDemandText);
                }else{
                    setStep1TalkinngDemandText('');
                }
            }else{
                setStep1TalkingDemand([]);
                setStep1TalkinngDemandText('');
            }
            setBtnLoading(false);
            setStep2DefaultExceptDate();
            window.scrollTo(0, 0);
            if (state.locationId) {
                history.push({ pathname: state.locationId === 'mamatas' ? `/mamatas_reservation_step_2/${state.branchId}` : `/group_reservation_step_2/${state.branchId}`, state: { branchId: state.branchId, locationId: state.locationId, skip_1: isSkip() } });
            } else {
                history.push({ pathname: `/reservation_step_2/${state.branchId}`, state: { branchId: state.branchId, locationId: state.locationId, agentData: state.agentData } });
            }
        }
        const onFail = (error) => {
            setStep2Date('');
            setBtnLoading(false);
        }

        await state.locationId === 'mamatas'? getNext3WorkdayDate4Calendar({ date: "" }, onSuccess, onFail) : getTomorrowDate4Calendar({ date: "" }, onSuccess, onFail);
    }

    return (
        !showCompnents ? <></> :
        (state === undefined || state.branchId === null || state.branchId === '') ?
            <PageNotFound />
            :
            <ARContainer>
                <div className={classes.schedule_meetup_step1}>
                    <AppointmentStepper step_no={1} />
                    {
                       !state.locationId && q1?.questionActive === 1 &&
                        <div className={classes.top}>
                            <p className={`font-16 ${classes.top_label}`}>{q1.questionContent}</p>
                            <div>
                                <RadioButton
                                    labelClassName={classes.agent_before_radio_button}
                                    id="agent-before"
                                    value={1}
                                    label={t('appointment_reservation_page.step_1.question_1_no')}
                                    selectedValue={isHasExperienceBefore}
                                    onChange={handleAgentBefore} />
                                <RadioButton
                                    labelClassName={classes.agent_before_radio_button}
                                    id="agent-before"
                                    value={2}
                                    label={t('appointment_reservation_page.step_1.question_1_yes')}
                                    selectedValue={isHasExperienceBefore}
                                    onChange={handleAgentBefore} />
                            </div>
                        </div>
                    }
                    {
                        (!state.locationId && q2.questionActive === 1) &&
                        <div className={classes.middle}>
                            <p className={`font-16 ${classes.question}`}>{q2.questionContent}</p>
                            <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />
                            <div className={classes.insurance_types_container}>
                                {
                                    q2.options.map((item, index) => {
                                        return item.optionActive === 1 ?
                                            <StyledCheckbox
                                                key={index}
                                                className={classes.insurance_type}
                                                value={index + 1}
                                                text={item.optionContent}
                                                checked={isInsuranceCheck(index + 1)}
                                                onChecked={onInsuranceChecked}
                                                onUnchecked={onInsuranceUnchecked} />
                                            : <></>
                                    }
                                    )
                                }
                            </div>
                            {insurancesError && <ErrorMessage message={t('appointment_reservation_page.step_1.error_message')} className={classes.error_message} />}
                        </div>
                    }
                    {
                        (q4.questionActive === 1  && state.locationId !=='mamatas') &&
                        <div className={`${classes.middle}`}>
                            <p className={`font-16 ${classes.request_textbox_label}`}>{(!state.locationId ? '' : t('general.label.optional') )+ q4.questionContent }</p>
                            <GeneralTextArea value={requestMessage} onFocus={()=>{onTextAreaFocus('onDiscussContentInput')}} onChange={(e) => {
                                setRequestMessage(e.target.value);
                                if (countWord(e.target.value) > 2000) {
                                    setIsRequestMessage('lengthError');
                                } else {
                                    setIsRequestMessage('');
                                }
                            }} placeholder={t('appointment_reservation_page.step_1.request_summery_placeholder')} />
                            {(isRequestMessage === 'null' || isRequestMessage === 'formatError' || isRequestMessage === 'lengthError') && <ErrorMessage message={t('appointment_reservation_page.step_1.request_summery_placeholder')} />}
                        </div>
                    }

                    <div className={classes.bottom}>
                        {
                            (q20.questionActive === 1 && state.locationId && state.locationId ==='reservation') &&
                            <>
                                <div className='pb-2'>
                                <p className={`font-16 ${classes.top_label}`}>{q20.questionContent}</p>
                                    <div className={`${classes.appointment_method}`}>
                                        {
                                            q20.options.map((item, index)=>{
                                               return item.optionActive === 1 ? <RadioButton
                                                        key={index}
                                                        className={classes.discussStatus_radio}
                                                        labelClassName={classes.agent_before_radio_button}
                                                        id="discussStatus"
                                                        value={item.optionContent}
                                                        label={item.optionContent}
                                                        selectedValue={discussStatus}
                                                        onChange={(e)=> {handleDiscussStatus(item.optionContent)}} /> : <></>
                                            })
                                        }
                                </div>
                                </div>
                                {
                                    showDiscussStatusText &&
                                    <>
                                    <p className={`font-16 ${classes.top_label}`}>{t('general.label.optional') + t('general.fields.remark.title')}</p>
                                        <GeneralTextArea value={discussStatusText} onFocus={()=>{onTextAreaFocus('onDiscussStatusInput')}} onChange={(e) => {
                                        setDiscussStatusText(e.target.value);
                                        if (countWord(e.target.value) > 2000) {
                                            setIsDiscussStatusText('lengthError');
                                        } else {
                                            setIsDiscussStatusText('');
                                        }
                                    }} placeholder={t('appointment_reservation_page.step_1.discuss_status_placeholder')} />
                                    {(isDiscussStatusText === 'null' || isDiscussStatusText === 'formatError' || isDiscussStatusText === 'lengthError') && <ErrorMessage message={t('appointment_reservation_page.step_1.discuss_status_error')} />}
                                    </>
                                }
                            </>
                        }
                        {
                            (q21?.questionActive === 1 && state.locationId && state.locationId ==='mamatas') &&
                                <>
                                    <div className='pb-2'>
                                        <p className={`font-16 ${classes.top_label}`}>{t('general.label.optional')}{q21?.questionContent}{t('general.label.multi_select')}</p>
                                        <div className={`${classes.appointment_method}`}>
                                            {
                                                q21?.options.map((item, index)=>{
                                                return item.optionActive === 1 ? <StyledCheckbox
                                                        key={index}
                                                        className={classes.talking_demand}
                                                        value={index + 1}
                                                        text={item.optionContent}
                                                        checked={isDemandCheck(index + 1)}
                                                        onChecked={onDemandChecked}
                                                        onUnchecked={onDemandUnchecked} />
                                                        : <></>
                                                })
                                            }
                                        </div>
                                    </div>
                                    {
                                        showTalkingDemandText &&
                                        <>
                                            <p className={`font-16 ${classes.top_label}`}>{t('general.label.optional') + t('general.fields.remark.title')}</p>
                                                <GeneralTextArea value={talkingDemandText} onFocus={()=>{onTextAreaFocus('onDemandInput')}} onChange={(e) => {
                                                setTalkingDemandText(e.target.value);
                                                if (countWord(e.target.value) > 2000) {
                                                    setIsDemandtError('lengthError');
                                                } else {
                                                    setIsDemandtError('');
                                                }
                                            }} placeholder={t('appointment_reservation_page.step_1.request_summery_placeholder')} />
                                            {(isDemandtError === 'null' || isDemandtError === 'formatError' || isDemandtError === 'lengthError') && <ErrorMessage message={t('appointment_reservation_page.step_1.request_summery_placeholder')} />}
                                        </>
                                    }
                                </>
                        }
                        <div className={classes.buttons_container}>
                            <NextButton onClick={onNext} loading={btnLoading} className="order-2" />
                            {!state.locationId ? <BackButton onClick={onBack} className="order-1" /> : <div></div>}
                        </div>
                    </div>
                    
                </div>
            </ARContainer>
    )
}


const stateToProps = state => {
    return {
        insuranceTypes: state.insuranceTypes,
        step1IsHasExperience: state.step1IsHasExperience,
        step1InsuranceTypes: state.step1InsuranceTypes,
        step1RequestMessage: state.step1RequestMessage,
        step1DiscussStatus: state.step1DiscussStatus,
        step1DiscussStatusText: state.step1DiscussStatusText,
        step1TalkingDemand: state.step1TalkingDemand,
        step1TalkinngDemandText: state.step1TalkinngDemandText,
        steps: state.groupReservationConfig.steps.filter((item) => item.step === 1)[0],
        breadcrumbsPath: state.breadcrumbsPath
    }
}

const dispatchToProps = dispatch => {
    return {
        setStep1IsHasExperience: (isHasExperience) => {
            dispatch(setStep1IsHasExperience(isHasExperience));
        },
        setStep1Insurances: (selectedInsurances) => {
            dispatch(setStep1Insurances(selectedInsurances));
        },
        setStep1Request: (requestMessage) => {
            dispatch(setStep1Request(requestMessage));
        },
        setStep1DiscussStatus: (discussStatus) => {
            dispatch(setStep1DiscussStatus(discussStatus))
        },
        setStep1DiscussStatusText: (discussStatusText) => {
            dispatch(setStep1DiscussStatusText(discussStatusText))
        },
        setStep1TalkingDemand: (selectedTalkingDemand) => {
            dispatch(setStep1TalkingDemand(selectedTalkingDemand))
        },
        setStep1TalkinngDemandText: (talkingDemandText) => {
            dispatch(setStep1TalkinngDemandText(talkingDemandText))
        },
        setStep2Date: (date) => {
            dispatch(setStep2Date(date));
        },
        setStep2DefaultExceptDate: () => {
            dispatch(setStep2DefaultExceptDate());
        },
        setBreadcrumbsPath: (breadcrumbsPath) => {
            dispatch(setBreadcrumbsPath(breadcrumbsPath))
        }
    }
}

export default connect(stateToProps, dispatchToProps)(ARStep1);