import React, { useState } from 'react';

import classes from './styles.module.css';

const Checkbox = ({ className, text, value, checked = false, onChecked, onUnchecked}) => {
	const [isChecked, setChecked] = useState(checked);

	const handleChange = (e, name) => {
		if(e.target.checked){
			onChecked && onChecked(parseInt(e.target.value), name);
		}
		else {
			onUnchecked && onUnchecked(parseInt(e.target.value), name);
		}
		setChecked(!isChecked);
	}
	
	return (           
		<div className={className}>
			<label 
				className={classes.custom_check_box} 
				draggable="true">
					{text}
					<input type="checkbox" value={value} checked={isChecked} onChange={(e) => handleChange(e, text)} />
					<span className={classes.custom_check}></span>
			</label>
		</div> 
  );
};

export default Checkbox;