import { useEffect, useRef } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router';
import {useTranslation} from 'react-i18next';

const useNonInitialEffect = (func, deps) => {
    const didMount = useRef(false);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const useRemoveSpace = (value) => {
    return value.split(" ").join("");
}

const useConvertWeekDay = (value) => {
    const {t} = useTranslation();
    const dayName = t(`general.calendar.weekdays.${moment(value).format('dddd').toLowerCase()}`)
    return dayName;
}

export { useNonInitialEffect, useQuery, useRemoveSpace, useConvertWeekDay };