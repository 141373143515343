import React from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";

import ErrorMessage from "../../components/ErrorMessage";
import DirectQuestionsMI from "../../components/MenuItems/DirectQuestionMI";
import Mandatory from "../../components/MandatoryLabel";
import PageNotFound from "../PageNotFound";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import classes from "./styles.module.css";

const DirectQuestionsPage = ({
  state,
  directQuestions,
  firstAnswer,
  setFirstAnswer,
  secondAnswer,
  setSecondAnswer,
  directQuestionsError
}) => {

  const { t } = useTranslation();
  const { trackEvent } = useMatomo();

  const onFirstAnswerClick = (answer) => {
    setFirstAnswer(answer)
    trackEvent({ category: 'onDirectQuestionsAnswer', action: 'matching-click-event' })
  }

  const onSecondAnswerClick = (answer) => {
    setSecondAnswer(answer)
    trackEvent({ category: 'onDirectQuestionsAnswer', action: 'matching-click-event' })
  }

  
  return state === undefined ||
    state.branchId === null ||
    state.branchId === "" ? (
    <PageNotFound />
  ) : (
    directQuestions && (
      <>
        <Row className={`row ${classes.ai_direct_question_row}`}>
          <Col lg="12">
            <div className={classes.question_container}>
              <h5>{directQuestions[0].descriptionForUser}</h5>
              <Mandatory text={t("general.fields.mandatory")} />
            </div>
          </Col>
          {directQuestions[0].questionItems.map((answer, index) => (
            <DirectQuestionsMI
              key={index}
              answer={answer}
              selectedAnswer={firstAnswer}
              onClick={() => onFirstAnswerClick(answer)}
            />
          ))}
        </Row>
        <Row className={`row ${classes.ai_direct_question_row}`}>
          <Col lg="12">
            <div className={classes.question_container}>
              <h5>{directQuestions[1].descriptionForUser}</h5>
              <Mandatory text={t("general.fields.mandatory")} />
            </div>
          </Col>
          {directQuestions[1].questionItems.map((answer, index) => (
            <DirectQuestionsMI
              key={index}
              answer={answer}
              selectedAnswer={secondAnswer}
              onClick={() => onSecondAnswerClick(answer)}
            />
          ))}
        </Row>
        {directQuestionsError && (
          <Row id='direct_question_error' >
            <Col>
              <ErrorMessage message={t("ai_matching_page.error_message")} />
            </Col>
          </Row>
        )}
      </>
    )
  );
};

export default DirectQuestionsPage;
