import React from 'react';

import styles from './styles.module.css';

const ProfileInfoCard = ({ className, title, description }) => {
    return (
        <div className={`${styles.agent_detail_media_box} ${className}`}>
            <p>{title}</p>
            <p>{description}</p>
        </div>
    )
};

export default ProfileInfoCard;