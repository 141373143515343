import moment from 'moment';

let currentDate = new Date();
export function beforeToday(day) {
    return day.isBefore(currentDate, "day");
}

export function beforeThisMonth(day, value) {
    const startDay = value.clone().startOf("month");
    return day.isBefore(startDay, "day");
}

export function afterThisMonth(day, value) {
    const endDay = value.clone().endOf("month");
    return day.isAfter(endDay, "day");
}

export function afterNextMonth(day) {
    return day.isAfter(moment(currentDate).add(1, "month").endOf("month"), "day");
}

export function beforeNextMonth(day) {
    return day.isBefore(moment(currentDate).add(1, "month").startOf("month").startOf("week"), "day");
}

export const last_date = '2025-12-31'

export function afterDec31(day) {
    return day.isAfter(last_date);
}

export function isBlock(day){
    return moment(day).isSameOrAfter('2022-12-31') && moment(day).isSameOrBefore('2023-01-05')
}

export function isSelected(day, value, selectedDate) {
    return moment(selectedDate).isSame(day, "day") && moment(selectedDate).isSame(day, 'month');
}

export function isToday(day) {
    return day.isSame(currentDate, "day");
}

export function isSunday(day) {
    return day.isSame(moment(day).startOf("week"), "day");
}

export function isSaturdayday(day) {
    return day.isSame(moment(day).endOf("week"), "day");
}

export function isWeekend(date) {
    return date.isWeekend ? true : false;
}

export function isHoliday(date) {
    return date.isHoliday ? true : false;
}

export function isDayOff(date) {
    return date.isDayOff ? true : false;
}

// export function isEnable(date) {
//     let dayoff = !!date.isDayOff ? false : true;
//     let holiday = !!date.isHoliday ? false : true;
//     let weeken = !!date.isWeekend ? false : true;
//     return !(dayoff && holiday && weeken);
// }


export function dayStyles(dateObj, value, selectedDate) {
    // disable after Dec 31
    if (afterDec31(moment(dateObj.date)) && isWeekend(dateObj)) return "disabled_weekend";
    if (afterDec31(moment(dateObj.date))) return "disable";


    if ((afterThisMonth(moment(dateObj.date), value) || beforeThisMonth(moment(dateObj.date), value)) && (isWeekend(dateObj) || isHoliday(dateObj) || isDayOff(dateObj))) return "sunday-nextday";
    if (afterThisMonth(moment(dateObj.date), value) || beforeThisMonth(moment(dateObj.date), value)) return "nextday";
    if ((beforeToday(moment(dateObj.date)) || isToday(moment(dateObj.date))) && (isWeekend(dateObj) || isHoliday(dateObj) || isDayOff(dateObj))) return "sunday-before";
    if (beforeToday(moment(dateObj.date)) || isToday(moment(dateObj.date))) return "before";
    if (beforeThisMonth(moment(dateObj.date), value) && beforeNextMonth(moment(dateObj.date))) return "disable";

    // weekends selectable
    if (isSelected(moment(dateObj.date), value, selectedDate) && !(isDayOff(dateObj) && isHoliday(dateObj) && isWeekend(dateObj))) return "selected";
    if (isWeekend(dateObj) || isHoliday(dateObj) || isDayOff(dateObj)) return "offday ";

    // weekends not selectable
    // if (isWeekend(dateObj) || isHoliday(dateObj) || isDayOff(dateObj)) return "offday ";
    // if (isSelected(moment(dateObj.date), value, selectedDate) && !(isDayOff(dateObj) && isHoliday(dateObj) && isWeekend(dateObj))) return "selected";

    if (afterNextMonth(moment(dateObj.date), value)) return "disable";
    return "default";
}


function SubstractExtraDays(startDay, api_dates) {
    const new_array = api_dates.filter(obj => {
        if (moment(obj.date) >= startDay) {
            return obj;
        }
        else {
            return null;
        }
    });
    return new_array;
}

export default function buildCalendar(origin, data, value, param_current) {
    let ck_calendar = origin.length <= 0 ? data : origin;
    currentDate = param_current;

    const emptyObj = {
        "date": "1",
        "weekday": 0,
        "isDayOff": 0,
        "isWeekend": 0,
        "isHoliday": 0
    }
    const startDay = value.clone().startOf("month").startOf("week");
    const endDay = value.clone().endOf("month").endOf('week');
    const day = startDay.clone().subtract(1, "day");

    const dates = SubstractExtraDays(moment(startDay).toDate(), ck_calendar.dateList)
    const calendar = [];
    const number_of_weeks = Math.ceil(dates.length / 7);
    let current = 0;
    for (let index = 0; index < number_of_weeks; index++) {
        while (day.isBefore(endDay, "day")) {
            const week = Array(7).fill(0);
            for (let index1 = 0; index1 < week.length; index1++) {
                current++;
                day.add(1, "day").clone();
                dates[current - 1] === undefined ? week[index1] = emptyObj : week[index1] = dates[current - 1];
            }
            // let day_of_week = 0;
            // const week = Array(7).fill(0).map(() => {
            //     while (day_of_week < 7) {
            //         day_of_week++;
            //         current++;
            //         day.add(1, "day").clone();
            //         return dates[current - 1] === undefined ? emptyObj : dates[current - 1];
            //     }
            // });
            calendar.push(week);
        }
    }
    return calendar;
}

function beforeNext3WorkDay(day, calendar){
    const dateData = calendar.flat();
    let today = moment(currentDate).format("YYYY-MM-DD");
    let index = dateData.findIndex(item => item.date === today);
    if(index !== -1){
        let calculateDate = dateData[index];
        let lastCalculateDate = calculateDate;
        for(let next = 0; next < 3; next++){
            index += 1;
            calculateDate = dateData[index];
            if(calculateDate){
                lastCalculateDate = calculateDate;
                if(isWeekend(calculateDate) || isHoliday(calculateDate) || isDayOff(calculateDate)){
                    next--;
                }
            }
        }
        if(calculateDate){
            return day.isBefore(moment(calculateDate.date), "day"); 
        }else{
            return day.isBefore(moment(lastCalculateDate.date).add(1, "day").format("YYYY-MM-DD"), "day");  
        }
    }else{
        return false;
    }
}


export function dayMamatasStyles(dateObj, value, selectedDate, calendar) {
    // disable after Dec 31
    if (afterDec31(moment(dateObj.date)) && isWeekend(dateObj)) return "disabled_weekend";
    if (afterDec31(moment(dateObj.date))) return "disable";


    if ((afterThisMonth(moment(dateObj.date), value) || beforeThisMonth(moment(dateObj.date), value)) && (isWeekend(dateObj) || isHoliday(dateObj) || isDayOff(dateObj))) return "sunday-nextday";
    if (afterThisMonth(moment(dateObj.date), value) || beforeThisMonth(moment(dateObj.date), value)) return "nextday";
    if (beforeNext3WorkDay(moment(dateObj.date), calendar) && (isWeekend(dateObj) || isHoliday(dateObj) || isDayOff(dateObj))) return "sunday-before";
    if (beforeNext3WorkDay(moment(dateObj.date), calendar)) return 'before';
    if (beforeThisMonth(moment(dateObj.date), value) && beforeNextMonth(moment(dateObj.date))) return "disable";

    // weekends selectable
    if (isSelected(moment(dateObj.date), value, selectedDate) && !(isDayOff(dateObj) && isHoliday(dateObj) && isWeekend(dateObj))) return "selected";
    if (isWeekend(dateObj) || isHoliday(dateObj) || isDayOff(dateObj)) return "offday ";

    // weekends not selectable
    // if (isWeekend(dateObj) || isHoliday(dateObj) || isDayOff(dateObj)) return "offday ";
    // if (isSelected(moment(dateObj.date), value, selectedDate) && !(isDayOff(dateObj) && isHoliday(dateObj) && isWeekend(dateObj))) return "selected";

    if (afterNextMonth(moment(dateObj.date), value)) return "disable";
    return "default";
}