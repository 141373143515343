import React from 'react';
import { getAppointmentMethodContent } from '../../../utilities/helper'

import classes from './styles.module.css';

const DirectQuestionsMI = ({ answer, selectedAnswer = false, onClick, className, id, name, label, value, selectedValue }) => {
    
    return (
        <div className={`${classes.direct_question_mi} ${className}`} onClick={onClick}>
            <div className={answer.itemId === selectedAnswer.itemId ? classes.selected_answer : classes.answer}>
                <p className={classes.label}>{getAppointmentMethodContent(answer.itemId, answer.content)}</p>
                {
                    answer.itemId === selectedAnswer.itemId 
                    &&
                    <img src={require('../../../assets/images/icons/selected_answer.png').default} className={classes.selected_icon} alt="selected answer" />
                }
            </div>
        </div> 
    )
}

export default DirectQuestionsMI;