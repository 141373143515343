import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.css';
import backArrowIcon from '../../../assets/images/icons/back-arrow.png';

const BackButton = ({onClick, iconClassName, iconStyle, className, style}) => {

    const { t } = useTranslation();

    return (
        <button className={`${classes.back_btn} ${className}`} onClick={onClick} style={style}>
            <img src={backArrowIcon} className={`${classes.icon} ${iconClassName}`} style={iconStyle} alt="back icon" />
            <p className={`PoppinsMedium ${classes.label}`} draggable="true">{t('general.buttons.back')}</p>
        </button>
    )
}

export default BackButton;