import { base_api_url } from '../../configs/environments';
import api_urls from '../../routes/api_urls';
import { defaultHeaderOptions } from '../headerOptions';

import eventBus from '../../EventBus';

const getRecruitmentNum = (onSuccess, onFail) => {

    const requestOptions = {
        method: 'GET',
        headers: defaultHeaderOptions,
    };
    fetch(`${base_api_url}${api_urls.get_recruitment_number}`, requestOptions)
        .then(async response => {
          const data = await response.text();
          if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
          return data;
        }).then((data) => {
          onSuccess && onSuccess(data);
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('Get Questions config api error - ', error);
            onFail && onFail(error);
        });
}

export {
  getRecruitmentNum
}