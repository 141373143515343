import moment from "moment";

const convert = (value, format) => {
    return moment(value).format(format);
};

const convertDateTime = (value) => {
    return convert(value, "YYYY-MM-DD HH:mm");
};

const convertDate = (value) => {
    return convert(value, "YYYY/MM/DD");
};

const convertTime = (value) => {
    return `${value.substring(0, 2)}:${value.substring(2, 4)}`;
};

const convertPeroid = (value) => {
    let first = value.substring(0, 4);
    let second = value.substring(4, 8);
    return `${convertTime(first)} - ${convertTime(second)}`;
};

const getRandomQueryString = () => {
    return "?" + Math.random().toString(36).substring(7);
};

const isMainBranch = (branchCode) => {
    if (!branchCode) return;

    return branchCode.substring(branchCode.length - 3) === "000";
};

const formatPostcode = (postcode) => {
    if (!postcode) return;

    try {
        let postcodeString = postcode.toString().replace(/\D/g, "");
        if (postcodeString.length >= 4 && postcodeString.charAt(3) !== "-") {
            return (
                postcodeString.substring(0, 3) +
                "-" +
                postcodeString.substring(3, postcodeString.length)
            );
        } else {
            return postcode;
        }
    } catch {
        return postcode;
    }
};

const formatPhoneNo = (phoneNo) => {
    if (!phoneNo) return;

    try {
        let phoneNoString = phoneNo.toString().replace(/\D/g, "");
        if (phoneNoString.charAt(4) !== "-") {
            phoneNoString =
                phoneNoString.substring(0, 4) +
                "-" +
                phoneNoString.substring(4, phoneNoString.length);
        }
        if (phoneNoString.charAt(7) !== "-") {
            phoneNoString =
                phoneNoString.substring(0, 7) +
                "-" +
                phoneNoString.substring(7, phoneNoString.length);
        }
        return phoneNoString;
    } catch {
        return phoneNo;
    }
};

const getAppointmentMethodContent = (id, default_text) => {
    switch (id) {
        case 401:
            return '来店して相談したい';
        case 402:
            return 'オンラインで相談したい';
        case 403:
            return '電話で相談したい';
        default:
            return default_text
    }
}

const countWord = (param) => {
    let length = 0;
    if (param.length === 0) return;
    for (let i = 0; i < param.length; i++) {
        var item = param.charAt(i);
        if (item.match(/^[\u00A4-\uFFe5]+$/)) {
            length += 2;
        } else {
            length += 1;
        }
    }
    return length
}

let half_width = /[ｧ-ﾝﾞﾟ]+|[a-z A-Z 0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+|[ｦ-ﾟ]+/g;
const noFullWidth = (value) => {
    let match = value.match(half_width);
    if(!match){
        return false;
    }
    if(match.length > 1){
        return false;
    }else if (match.length === 1 && value.length !== match[0].length){
        return false;
    }else{
        return true;
    }
};

export {
    convertDateTime,
    convertTime,
    convertPeroid,
    convertDate,
    getRandomQueryString,
    isMainBranch,
    formatPostcode,
    formatPhoneNo,
    getAppointmentMethodContent,
    countWord,
    noFullWidth
};