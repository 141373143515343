import React from 'react';
import { useTranslation } from 'react-i18next';


import styles from './styles.module.css';
import loadingGif from '../../../assets/images/icons/submit_btn_loading.gif';
import nextRightArrow from '../../../assets/images/icons/next-right-arrow.png';

const NextButton = ({ onClick, iconClassName, iconStyle, className, style, disabled = false, loading = false }) => {

    const { t } = useTranslation();
    return (
        <button disabled={disabled || loading} className={!disabled ? `${styles.next_button} ${className}` : `${styles.disabled_next_btn} ${className}`} onClick={onClick} style={style} >
            { loading && <img src={loadingGif} className={styles.loading_icon} alt="button loading icon" />}
            <p className={`PoppinsSemiBold ${styles.text}`} draggable="true">{t('general.buttons.next')}</p>
            <img src={nextRightArrow} className={`${styles.icon} ${iconClassName}`} style={iconStyle} alt="button back icon" />
        </button>
    );
};


export default NextButton;