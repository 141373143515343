import {
    RESET_ALL_2_INITIAL_STATE,
    SET_S_ID,
    RESET_WELCOME_SEARCHING_INFO,
    RESET_AGENT_LIST_HISTORIES,
    RESET_APPOINTMENT_RESERVATION,
    USER_ID,
    INSURANCE_TYPES,
    SELECTED_INSURANCES_ID,
    SELECTED_BIRTH_YEAR,
    SELECTED_FAMILY_STR,
    SELECTED_GENDER_ID,
    DIRECT_QUESTIONS,
    ANSWERED_DIRECT_QNA,
    SYMPATHY_QUESTIONS,
    ANSWERED_SYMPATHY_QNS,
    SELECTED_BRANCH,
    AGENT_LIST_SCROLL_POSITION,
    AGENT_LIST_CURRENT_PAGE,
    AGENT_LIST_SELECTED_FILTER,
    AGENT_LIST_SELECTED_DATE_FILTER,
    AGENT_LIST_START_TIME_FILTER,
    AGENT_LIST_END_TIME_FILTER,
    AGENT_NAME_FILTER,
    STEP1_IS_HAS_EXPERIENCE,
    STEP2_APPOINTMENT_METHOD,
    STEP2_SELECTED_STORE,
    STEP1_INSURANCE_TYPES,
    STEP1_REQUEST,
    STEP2_DATE,
    STEP2_TIME,
    STEP2_FIRST_NAME_KANJI,
    STEP2_LAST_NAME_KANJI,
    STEP2_FIRST_NAME_KANA,
    STEP2_LAST_NAME_KANA,
    STEP2_EMAIL,
    STEP2_PHONE,
    GROUP_RESERVATION_CONFIG,
    STEP2_EMPLOYEE_NUMBER,
    RESET_MATCHING_QUESTIONS,
    STEP2_DOB,
    STEP2_GENDER,
    STEP2_POST_CODE,
    STEP2_PREFECTURES,
    STEP2_MUNICIPALITIES,
    STEP2_ADDRESS,
    STEP2_COMPANY_EMAIL,
    STEP2_DEPARTMENT,
    STEP2_EXTENSION_NUMBER,
    STEP2_PREFERRED_APPOINTMENT,
    STEP2_CONVENIENT_TIME,
    STEP2_REMARK,
    STEP1_DISCUSS_STATUS,
    STEP1_DISCUSS_STATUS_TEXT,
    STEP1_TALKING_DEMAND,
    STEP1_TALKIING_DEMANND_TEXT,
    RESET_SETP2_EXCEPT_DATE,
    SET_BREADCRUMBS_PATH
} from "../constants";

export function resetAll2InitialState() {
    return { type: RESET_ALL_2_INITIAL_STATE };
}

export function setSID(s_id) {
    return { type: SET_S_ID, s_id };
}

export function resetWelcomeSearchingInfo() {
    return { type: RESET_WELCOME_SEARCHING_INFO };
}

export function resetAgentListFilter() {
    return { type: RESET_AGENT_LIST_HISTORIES };
}

export function resetAppointmentReservation() {
    return { type: RESET_APPOINTMENT_RESERVATION };
}

export function setStep2DefaultExceptDate() {
    return { type: RESET_SETP2_EXCEPT_DATE };
}

export function setLoginUser(userId) {
    return { type: USER_ID, userId };
}

export function setInsuranceTypes(insuranceTypes) {
    return { type: INSURANCE_TYPES, insuranceTypes };
}

export function setSelectedInsurancesId(insuranceTypes) {
    return { type: SELECTED_INSURANCES_ID, insuranceTypes };
}

export function setSelectedBirthYear(birthYear) {
    return { type: SELECTED_BIRTH_YEAR, birthYear };
}

export function setSelectedFamilyStr(familyStructure) {
    return { type: SELECTED_FAMILY_STR, familyStructure };
}

export function setSelectedGenderId(genderId) {
    return { type: SELECTED_GENDER_ID, genderId };
}

export function setDirectQuestions(directQuestions) {
    return { type: DIRECT_QUESTIONS, directQuestions };
}

export function setAnsweredDirectQns(answeredQns) {
    return { type: ANSWERED_DIRECT_QNA, answeredQns };
}

export function setSympathyQuestions(sympathyQuestions) {
    return { type: SYMPATHY_QUESTIONS, sympathyQuestions };
}

export function setAnsweredSympathyQns(answeredQns) {
    return { type: ANSWERED_SYMPATHY_QNS, answeredQns };
}

export function setSelectedBranch(selectedBranch) {
    return { type: SELECTED_BRANCH, selectedBranch };
}

export function setAgentListingScrollPosition(agentListScrollPosition) {
    return { type: AGENT_LIST_SCROLL_POSITION, agentListScrollPosition };
}

export function setAgentListingCurrentPage(agentListCurrentPage) {
    return { type: AGENT_LIST_CURRENT_PAGE, agentListCurrentPage };
}

export function setAgentListingSelectedFilter(agentListSelectedFilter) {
    return { type: AGENT_LIST_SELECTED_FILTER, agentListSelectedFilter };
}

export function setAgentListingSelectedDateFilter(agentListSelectedDateFilter) {
    return { type: AGENT_LIST_SELECTED_DATE_FILTER, agentListSelectedDateFilter };
}

export function setAgentListingStartTimeFilter(agentListStartTimeFilter) {
    return { type: AGENT_LIST_START_TIME_FILTER, agentListStartTimeFilter };
}

export function setAgentListingEndTimeFilter(agentListEndTimeFilter) {
    return { type: AGENT_LIST_END_TIME_FILTER, agentListEndTimeFilter };
}

export function setAgentNameFilter(agentNameFilter) {
    return { type: AGENT_NAME_FILTER, agentNameFilter };
}

export function setStep1IsHasExperience(isHasExperience) {
    return { type: STEP1_IS_HAS_EXPERIENCE, isHasExperience };
}

export function setStep2AppointmentMethod(appointmentMethod) {
    return { type: STEP2_APPOINTMENT_METHOD, appointmentMethod };
}

export function setStep2SelectedStore(selectedStore) {
    return { type: STEP2_SELECTED_STORE, selectedStore };
}

export function setStep1Insurances(selectedInsurances) {
    return { type: STEP1_INSURANCE_TYPES, selectedInsurances };
}

export function setStep1Request(requestMessage) {
    return { type: STEP1_REQUEST, requestMessage };
}

export function setStep1DiscussStatus(discussStatus) {
    return { type: STEP1_DISCUSS_STATUS, discussStatus };
}

export function setStep1DiscussStatusText(discussStatusText) {
    return { type: STEP1_DISCUSS_STATUS_TEXT, discussStatusText };
}

export function setStep1TalkingDemand(selectedTalkingDemand) {
    return { type: STEP1_TALKING_DEMAND, selectedTalkingDemand };
}

export function setStep1TalkinngDemandText(talkingDemandText) {
    return { type: STEP1_TALKIING_DEMANND_TEXT, talkingDemandText };
}

export function setStep2Date(step2_date) {
    return { type: STEP2_DATE, step2_date };
}

export function setStep2Time(step2_time) {
    return { type: STEP2_TIME, step2_time };
}

export function setStep2FirstNameKanji(step2_first_name_kanji) {
    return { type: STEP2_FIRST_NAME_KANJI, step2_first_name_kanji };
}

export function setStep2LastNameKanji(step2_last_name_kanji) {
    return { type: STEP2_LAST_NAME_KANJI, step2_last_name_kanji };
}

export function setStep2FirstNameKana(step2_first_name_kana) {
    return { type: STEP2_FIRST_NAME_KANA, step2_first_name_kana };
}

export function setStep2LastNameKana(step2_last_name_kana) {
    return { type: STEP2_LAST_NAME_KANA, step2_last_name_kana };
}

export function setStep2Email(step2_email) {
    return { type: STEP2_EMAIL, step2_email };
}

export function setStep2Phone(step2_phone) {
    return { type: STEP2_PHONE, step2_phone };
}

export function setStep2EmpoyeeNumber(step2_employee_number) {
    return { type: STEP2_EMPLOYEE_NUMBER, step2_employee_number };
}

export function setStep2Dob(dob) {
    return { type: STEP2_DOB, dob };
}

export function setStep2Gender(gender) {
    return { type: STEP2_GENDER, gender };
}

export function setStep2PostCode(postCode) {
    return { type: STEP2_POST_CODE, postCode };
}

export function setStep2Prefectures(prefectures) {
    return { type: STEP2_PREFECTURES, prefectures };
}

export function setStep2Municipalities(municipalities) {
    return { type: STEP2_MUNICIPALITIES, municipalities };
}

export function setStep2Address(address) {
    return { type: STEP2_ADDRESS, address };
}

export function setStep2CompanyEmail(company_email) {
    return { type: STEP2_COMPANY_EMAIL, company_email };
}

export function setStep2Department(department) {
    return { type: STEP2_DEPARTMENT, department };
}

export function setStep2ExtensionNumber(extension_number) {
    return { type: STEP2_EXTENSION_NUMBER, extension_number };
}

export function setStep2PreferredAppointmentMethod(preferred_appointment_method) {
    return { type: STEP2_PREFERRED_APPOINTMENT, preferred_appointment_method };
}

export function setStep2ConvenientTime(convenient_time) {
    return { type: STEP2_CONVENIENT_TIME, convenient_time };
}

export function setStep2Remark(remark) {
    return { type: STEP2_REMARK, remark };
}

export function setGroupReservationConfig(config) {
    return { type: GROUP_RESERVATION_CONFIG, config };
}

export function resetMatchingQuestions() {
    return { type: RESET_MATCHING_QUESTIONS };
}

export function setBreadcrumbsPath(breadcrumbsPath) {
    return { type: SET_BREADCRUMBS_PATH, breadcrumbsPath };
}