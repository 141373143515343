import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import './styles.css'

export default function CalendarHeader({value, setValue}) {
    const { t } = useTranslation();
    function currMonthName() {
        const month = value.format("MMMM");
        switch (month) {
            case "January":
                return t('general.calendar.months.january');
            case "February":
                return t('general.calendar.months.february');
            case "March":
                return t('general.calendar.months.march');
            case "April":
                return t('general.calendar.months.april');
            case "May":
                return t('general.calendar.months.may');
            case "June":
                return t('general.calendar.months.june');
            case "July":
                return t('general.calendar.months.july');
            case "August":
                return t('general.calendar.months.august');
            case "September":
                return t('general.calendar.months.september');
            case "October":
                return t('general.calendar.months.october');
            case "November":
                return t('general.calendar.months.november');
            case "December":
                return t('general.calendar.months.december');
            default:
                return month;
        }
    }
    
    function currYear() {
        return value.format("YYYY");
    }
    
    function prevMonth() {
        return value.clone().subtract(1, "month");
    }
    
    function nextMonth() {
        return value.clone().add(1, "month");
    }

    function thisMonth() {
        return value.isSame(new Date(), "month");
    }

    function isNextMonth() {
        return value.isSame(moment(new Date()).add(1, "month"), "month");
    }

    return (
        <div className="header">
            <div className="previousContainer">
                <div  className="previous" onClick={() => !thisMonth() && setValue(prevMonth())}>
                    {!thisMonth() ? <span>&#8592;</span> : null}
                </div>
            </div>
            <div className="currentContainer">
                {currMonthName()} {currYear()}
            </div>
            <div className="nextContainer">
                <div className="next" onClick={() => !isNextMonth() && setValue(nextMonth())}>
                    {!isNextMonth() && <span>&#8594;</span>}
                </div>
            </div>
        </div>
    );
}