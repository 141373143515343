import React from 'react';

import classes from './styles.module.css';

const FooterBar = ({className}) => {
    return (
        <div className={`${classes.footer_bar} ${className}`}>
            <p className={`PoppinsMedium ${classes.footer_label}`}>© Aflac Life Insurance Japan Ltd</p>
        </div>
    )
};

export default FooterBar;