import React, { useRef, useState, useEffect } from "react";

import classes from "./styles.module.css";

const GeneralDropdown = ({
  items,
  selectedItem,
  placeholder,
  onSelect,
  className,
  enabled = true,
}) => {
  const list = useRef();
  const elementRef = useRef(null);

  const [height, setHeight] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedText, setSelectedText] = useState();

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
    items.map((x) => x.id === selectedItem && setSelectedText(x.name));
  }, [items, selectedItem]);

  const onOpen = () => {
    if (enabled) {
      setIsOpen(true);
      list.current.focus();
    }
  };

  const close = () => {
    setIsOpen(false);
  };

  const itemClick = (e) => {
    setSelectedText(e.target.textContent);
    setIsOpen(false);
    onSelect(e.target.value, e.target.textContent);
  };

  return (
    <div className={`${classes.select_box} ${className} ${enabled? '' : classes.disabled}`}>
      <div
        onClick={onOpen}
        className={
          selectedText === undefined && placeholder === undefined
            ? classes.blank_placeholder
            : ""
        }
      >
        <span>{selectedText !== undefined ? selectedText : placeholder}</span>
      </div>
      <div tabIndex="0" ref={list} onBlur={close}>
        <ul
          className={`${isOpen ? classes.showbox : classes.hidebox} ${
            height > 250 ? classes.over_height : ""
          }`}
          ref={elementRef}
        >
          {items &&
            items.map((item, index) => (
              <li key={index} value={item.id} onClick={itemClick}>
                {item.name}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default GeneralDropdown;
