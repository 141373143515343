const api_urls = {
    // search
    get_info_for_searching: '/api/welcome/getInfos',

    // ai matching
    get_sympathy_questions: '/api/welcome/questions/getSympathy',
    get_direct_questions: '/api/welcome/questions/getDirect',
    get_branch_list_by_agency_branch: '/api/branch/getByAgency',
    get_mamatas_branch_list: '/api/branch/getMamatasBranchList',

    // agent
    get_agent_listing: '/api/welcome/matching',
    get_agent_detail: '/api/agent/getItem',

    //Appoint Reservation
    get_calender: '/api/calendar/getList',
    get_time_slot: '/api/agent/getTimeSlots',
    get_time_slot_by_uuid: '/api/agent/getTimeSlotsByUuid',
    get_time_slot_by_branchid: '/api/branch/getTimeSlotsByBranchId',
    insert_appointment: '/api/appointment/insert',
    get_address: '/api/postcode/getByPostcode',

    //questionConfigs
    get_question_configs: '/api/management/questions/getGroupQuestions',

    //getRecruitmentNum
    get_recruitment_number: '/api/welcome/getRecruitmentNumber',

    //getKatakana
    get_katakana: '/api/getKatakana'
}

export default api_urls;