import React from 'react';


import styles from './styles.module.css';

const SkillBudgeIcon = ({ skill, index, className }) => {
    if (skill.id === 1) {
        return (<div className={`${styles.skill_budge_icon} ${styles.skill} ${styles.blue_color} ${className}`} key={index}>
            {skill.name}
        </div>)
    } else if (skill.id === 2) {
        return (<div className={`${styles.skill_budge_icon} ${styles.skill} ${styles.green_color} ${className}`} key={index}>
            {skill.name}
        </div>)
    } else if (skill.id === 3) {
        return (<div className={`${styles.skill_budge_icon} ${styles.skill} ${styles.purple_color} ${className}`} key={index}>
            {skill.name}
        </div>)
    } else if (skill.id === 4) {
        return (<div className={`${styles.skill_budge_icon} ${styles.skill} ${styles.red_color} ${className}`} key={index}>
            {skill.name}
        </div>)
    } else if (skill.id === 5) {
        return (<div className={`${styles.skill_budge_icon} ${styles.skill} ${styles.brown_color} ${className}`} key={index}>
            {skill.name}
        </div>)
    } else if (skill.id === 6) {
        return (<div className={`${styles.skill_budge_icon} ${styles.skill} ${styles.grey_color} ${className}`} key={index}>
            {skill.name}
        </div>)
    } else {
        return <div className={`${styles.skill_budge_icon} ${styles.skill} ${styles.blue_color} ${className}`} key={index}>
            {skill.name}
        </div>
    }

};

export default SkillBudgeIcon;