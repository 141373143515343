import React, { useRef, useState, useEffect } from 'react';

import classes from './styles.module.css';

const DobDropdown = ({ items, selectedItem, placeholder, onSelect, className }) => {
    const list = useRef();
    const elementRef = useRef(null);

    const [height, setHeight] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedText, setSelectedText] = useState();


    useEffect(() => {
        setHeight(elementRef.current.clientHeight);
    }, []);

    useEffect(() => {
        items.map(x => x.id === selectedItem && setSelectedText(x.name));
    }, [items, selectedItem]);

    const onOpen = () => {
        setIsOpen(true);
        list.current.focus();
        elementRef.current.scrollTo(0, 150);
    }

    const close = () => {
        setIsOpen(false);
    }

    const itemClick = (e) => {
        setSelectedText(e.target.textContent);
        setIsOpen(false);
        onSelect(e.target.value, e.target.textContent);
    }

    return (
        <div className={`${classes.select_box} ${className}`}>
            <div onClick={onOpen} className={(selectedText === undefined && placeholder === undefined) ? classes.blank_placeholder : ''}>
                <span>{selectedText !== undefined ? selectedText : placeholder}</span>
            </div>
            {/* <div onClick={onOpen}>
                <span>{selectedText !== undefined ? selectedText : placeholder}</span>
            </div> */}
            <div
                tabIndex="0"
                ref={list}
                onBlur={close}
            >
                <ul className={`${isOpen ? classes.showbox : classes.hidebox} ${(height > 250) ? classes.over_height : ''}`} ref={elementRef}>
                    {
                        items
                        &&
                        items.map((item, index) => (
                            // item.name === '1971年〜1975年' ?
                            //     <React.Fragment key={index}>
                            //         <li className={classes.placeholder}>
                            //             {placeholder}
                            //         </li>
                            //         <li value={item.id} onClick={itemClick}>
                            //             {item.name}
                            //         </li>
                            //     </React.Fragment>
                            //     :
                            <li key={index} value={item.id} onClick={itemClick}>
                                {item.name}
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default DobDropdown;