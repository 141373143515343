import React from 'react';


import styles from './styles.module.css';

const GeneralButton = ({ id, text, onClick, frontIcon, frontIconClassName, frontIconStyle, backIcon, backIconClassName, backIconStyle, className, style }) => {

    return (
        <button id={id} className={`${styles.general_button} ${className}`} onClick={onClick} style={style}>
            {
                frontIcon &&
                <img id={id} src={frontIcon} className={`${styles.front_icon} ${frontIconClassName}`} style={frontIconStyle} alt="button front icon" />
            }

            <p id={id} className={`${styles.text}`} draggable="true">{text}</p>

            {
                backIcon &&
                <img id={id} src={backIcon} className={`${styles.back_icon} ${backIconClassName}`} style={backIconStyle} alt="button back icon" />
            }
        </button>
    );
};


export default GeneralButton;