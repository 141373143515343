import React from 'react';


import classes from './styles.module.css';

const GeneralTextbox = ({placeholder, className, style, onChange, value, id, onFocus}) => {
    return (
        <div className={`${classes.general_textbox} ${className}`} style={style}>
            <input id={id} type="text" className={classes.general_textinput} value={value} placeholder={placeholder} onChange={onChange} onFocus={onFocus} />
        </div>
    )
};

export default GeneralTextbox;