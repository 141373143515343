import React from 'react';
import classes from './styles.module.css';

const Mandatory = ({ text, className, style}) => {

    return (
        <div className={`font-12 ${classes.mandatory} ${className}`} style={style}>
            <p className={classes.text}>{text}</p>
        </div>
    )
};


export default Mandatory;