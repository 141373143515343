import React from 'react';
import {useTranslation} from 'react-i18next';

import classes from './styles.module.css';

const AppointmentStepper = ({ step_no = 1, className, skip_1 = false }) => {

    const {t} = useTranslation();

    return (
        <div>
            <ul className={`${classes.appointment_stepper} ${className}`}>
                {
                    !skip_1 && 
                    <li className={step_no === 1 ? `${classes.selected} ${classes.active}` : (step_no === 2 || step_no === 3) ? classes.active : ''}>
                    {
                        step_no === 1 ?
                            <span className={classes.stepper_box}>
                                <span className="d-md-block d-none">{t('appointment_reservation_page.appointment_stepper.step_1.title')}</span>
                                <span className="d-md-none">{t('appointment_reservation_page.appointment_stepper.step_1.label')}</span>
                            </span>
                            :
                            <span className={classes.stepper_box}>
                                <span className="d-md-block d-sm-none d-block">{t('appointment_reservation_page.appointment_stepper.step_1.title')}</span>
                                <span className="d-md-none d-sm-block d-none">{t('appointment_reservation_page.appointment_stepper.step_1.label')}</span>
                            </span>
                    }
                    <span className={classes.stepper_text}>{t('appointment_reservation_page.appointment_stepper.step_1.label')}</span>
                </li>
                }
                <li className={step_no === 2 ? `${classes.selected} ${classes.active}` : step_no === 3 ? classes.active : ''}>
                    {
                        step_no === 2 ?
                            <span className={classes.stepper_box}>
                                {
                                    !skip_1 ? <span className="d-md-block d-none">{t('appointment_reservation_page.appointment_stepper.step_2.title')}</span> :
                                    <span className="d-md-block d-none">{t('appointment_reservation_page.appointment_stepper.step_1.title')}</span>
                                }
                                <span className="d-md-none d-sm-block d-none">{t('appointment_reservation_page.appointment_stepper.step_2.label_1')}</span>
                                <span className="d-sm-none">{t('appointment_reservation_page.appointment_stepper.step_2.label_2')}</span>
                            </span>
                            :
                            <span className={classes.stepper_box}>
                                {
                                    !skip_1 ? <span className="d-md-block d-sm-none d-block">{t('appointment_reservation_page.appointment_stepper.step_2.title')}</span>:
                                    <span className="d-md-block d-sm-none d-block">{t('appointment_reservation_page.appointment_stepper.step_1.title')}</span>
                                }
                                <span className="d-md-none d-sm-block d-none">{t('appointment_reservation_page.appointment_stepper.step_2.label_1')}</span>
                            </span>
                    }
                    <span className={classes.stepper_text}>{t('appointment_reservation_page.appointment_stepper.step_2.label_1')}</span>
                </li>
                <li className={step_no === 3 ? `${classes.selected} ${classes.active}` : ''}>
                    {
                        step_no === 3 ?
                            <span className={classes.stepper_box}>
                                {
                                    !skip_1 ? <span className="d-md-block d-none">{t('appointment_reservation_page.appointment_stepper.step_3.title')}</span> :
                                    <span className="d-md-block d-none">{t('appointment_reservation_page.appointment_stepper.step_2.title')}</span>
                                }
                                
                                <span className="d-md-none">{t('appointment_reservation_page.appointment_stepper.step_3.label')}</span>
                            </span>
                            :
                            <span className={classes.stepper_box}>
                                {
                                    !skip_1 ? <span className="d-md-block d-sm-none d-block">{t('appointment_reservation_page.appointment_stepper.step_3.title')}</span> :
                                    <span className="d-md-block d-sm-none d-block">{t('appointment_reservation_page.appointment_stepper.step_2.title')}</span>
                                }
                                
                                <span className="d-md-none d-sm-block d-none">{t('appointment_reservation_page.appointment_stepper.step_3.label')}</span>
                            </span>
                    }
                    <span className={classes.stepper_text}>{t('appointment_reservation_page.appointment_stepper.step_3.label')}</span>
                </li>
            </ul>
        </div>
    )
}

export default AppointmentStepper;