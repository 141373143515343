import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from "@material-ui/core";

import "./styles.css";

const Breadcrumbs = ( props ) => {
  const { t } = useTranslation();
  const { s_id, history, location: { state }, breadcrumbsPath } = props;

  const pathnames = breadcrumbsPath;
  
  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      className={`bread-crumb ${props.className}`}
    >
      {pathnames.length > 0 ? (
        <Link
          onClick={() => history.push(`/welcome?s_id=${s_id}`)}
          className="breadcrumb-label"
        >
          {t("general.breadcrumb.home")}
        </Link>
      ) : (
        <Typography className="breadcrumb-label">
          {t("general.breadcrumb.home")}
        </Typography>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames[index]}`
        const isLast = index === pathnames.length - 1;
        const breadcrumbName = name.split('/')[0];

        return isLast ? (
          <Typography key={name} className="breadcrumb-last-label">
            {t(`general.breadcrumb./${breadcrumbName}`)}
          </Typography>
        ) : (
          <Link key={name} className="breadcrumb-label" onClick={() => {
              index === 1
                ? history.push({
                    pathname: routeTo,
                    state: {
                      branchId: state.branchId,
                      agentData: state.agentData,
                    },
                  })
                : history.push({
                    pathname: routeTo,
                    state: { branchId: state.branchId },
                  });
            }}
          >
            {t(`general.breadcrumb./${breadcrumbName}`)}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

const stateToProps = (state) => {
  return {
    s_id: state.s_id,
  };
};

export default withRouter(connect(stateToProps, null)(Breadcrumbs));
