const environment = process.env.REACT_APP_ENV;
let base_api_url;
let base_proxy_api_url;
console.log("app customer REACT_APP_ENV==>", process.env.REACT_APP_ENV);
console.log("app environment==>", environment);
if (environment === "dev") {
  console.log("app environment==>", "dev branch");
  base_api_url = "https://asmdev2.japaneast.cloudapp.azure.com";
  base_proxy_api_url="https://asmdev2.japaneast.cloudapp.azure.com/proxy";
} else if (environment === "stg") {
  console.log("app environment==>", "stg branch");
  base_api_url = "https://st-amai.aflac.co.jp";
  base_proxy_api_url = "https://st-amai.aflac.co.jp/proxy";
} else if (environment === "prod") {
  console.log("app environment==>", "prod branch");
  base_api_url = "https://amai.aflac.co.jp";
  base_proxy_api_url = "https://amai.aflac.co.jp/proxy";
} else if (environment === "test") {
  console.log("app environment==>", "test branch");
  base_api_url = "https://localhost:8090";
} else {
  console.log("app environment==>", "else branch. default dev");
  // base_api_url = "https://asmdev2.japaneast.cloudapp.azure.com";
  base_api_url = '/dev';
  base_proxy_api_url = '/dev-proxy';
}
console.log("app base_api_url=>", base_api_url);
export { environment, base_api_url, base_proxy_api_url };
