import { createInstance } from '@datapunt/matomo-tracker-react'
import { environment } from './configs/environments'

export const matomo = createInstance({
  urlBase: environment === 'prod' ? '//aflac.platformerfuji.com/analytics/' : environment === 'stg' ? '//st-aflac.platformerfuji.com/analytics/' : '//matomoasmdev2.japaneast.cloudapp.azure.com/',
  siteId: environment === 'prod' ? 5 : environment === 'stg' ? 6 : 1,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST'
  }
})