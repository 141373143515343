import React from 'react';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { useLocation } from "react-router-dom";

import classes from './styles.module.css';
import Breadcrumbs from '../../components/Breadcrumbs';
import FooterBar from '../../components/FooterBar';
import HeaderBar from '../../components/HeaderBar';


const AppointmentReservationContainer = ({ children, breadcrumbsPath }) => {
    const { t } = useTranslation();
    const { state } = useLocation();

    return (
        <div>
            <HeaderBar locationId={state.locationId}/>
            <div className={`cmn-inner-width cmn-height ${classes.schedule_meetup}`}>
               {state.locationId === undefined && <Breadcrumbs breadcrumbsPath={breadcrumbsPath}/>}
                <h3 className={classes.title}>{t('appointment_reservation_page.title')}</h3>
                <Row>
                    <Col lg="8" md="12" className={classes.steps_container}>
                        {children}
                    </Col>
                </Row>
            </div>
            <FooterBar />
        </div>
    )
}

const stateToProps = (state) => {
    return {
      breadcrumbsPath: state.breadcrumbsPath,
    };
  };

export default connect(stateToProps)(AppointmentReservationContainer);