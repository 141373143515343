import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import { useMatomo } from "@datapunt/matomo-tracker-react";
import converter from 'jp-conversion';

import ARContainer from '../index';
import AppointmentStepper from '../AppointmentStepper';
import GeneralTextbox from '../../../components/TextBoxes/GeneralTextbox';
import GeneralDropdown from '../../../components/Dropdowns/GeneralDropdown';
import BackButton from '../../../components/Buttons/BackButton';
import NextButton from '../../../components/Buttons/NextButton';
import GeneralCard from '../../../components/CardViews/GeneralCard';
import Calendar from '../../../components/Calendar';
import CalendarMamatas from '../../../components/CalendarMamatas';
import TimeSlot from '../../../components/TimeSlot';
import Mandatory from '../../../components/MandatoryLabel';
import ErrorMessage from '../../../components/ErrorMessage';
import PageNotFound from '../../PageNotFound';
import DobSpreadSelector from '../../../components/DobSpreadSelector';
import RadioButton from '../../../components/RadioButtons';
import ZipCodeSearch from '../../../components/ZipcodeSearch';

import classes from './styles.module.css';

import { getAvailableTimeSlotsByUuid, getZipAddress, getKatakana, getAvailableTimeSlotsByBranchId } from '../../../api/Appointment/Step2';
import { getMamatasBranchList } from "../../../api/Welcome/searchingAPI";
import { convertPeroid, countWord, noFullWidth } from '../../../utilities/helper';
import { useNonInitialEffect } from '../../../utilities/customHooks';
import { isBlock } from "../../../utilities/calendar";
import prefecturesData from '../../../static/prefectures.json'

import {
    setStep2Date,
    setStep2Time,
    setStep2FirstNameKanji,
    setStep2LastNameKanji,
    setStep2FirstNameKana,
    setStep2LastNameKana,
    setStep2Email,
    setStep2Phone,
    setStep2EmpoyeeNumber,
    setStep2Dob,
    setStep2Gender,
    setStep2PostCode,
    setStep2Prefectures,
    setStep2Municipalities,
    setStep2Address,
    setStep2CompanyEmail,
    setStep2Department,
    setStep2ExtensionNumber,
    setStep2PreferredAppointmentMethod,
    setStep2ConvenientTime,
    setStep2Remark,
    setStep2AppointmentMethod,
    setStep2SelectedStore,
    setSelectedBranch,
    setBreadcrumbsPath,
} from '../../../store/actions';

const pageview = {
    dataLayer: {
        event: 'pageview',
        log_pageurl: '',
        log_pagename: ''
    }
}

const ARStep2 = ({
    step2_date,
    step2_time,
    step2_first_name_kana,
    step2_last_name_kana,
    step2_first_name_kanji,
    step2_last_name_kanji,
    step2_email,
    step2_phone,
    step2_employee_number,
    step2_dob,
    step2_gender,
    setStep2Date,
    step2_postCode,
    step2_prefectures,
    step2_municipalities,
    step2_company_email,
    step2_preferred_appointment,
    step2_convenient_time,
    step2_address,
    step2_department,
    step2_extension_number,
    setStep2Time,
    setStep2FirstNameKanji,
    setStep2LastNameKanji,
    setStep2FirstNameKana,
    setStep2LastNameKana,
    setStep2Email,
    setStep2Phone,
    setStep2EmpoyeeNumber,
    setStep2Dob,
    setStep2Gender,
    setStep2PostCode,
    setStep2Prefectures,
    setStep2Municipalities,
    setStep2Address,
    setStep2CompanyEmail,
    setStep2Department,
    setStep2ExtensionNumber,
    setStep2PreferredAppointmentMethod,
    setStep2ConvenientTime,
    steps,
    step2_group_timeSlots,
    blockDates,
    setStep2Remark,
    step2_remark,
    step2_appointmentMethod,
    setStep2AppointmentMethod,
    step2_selectedStore,
    setStep2SelectedStore,
    step2_selectedBranch,
    setSelectedBranch,
    breadcrumbsPath,
    setBreadcrumbsPath,
}) => {
    const { state, pathname } = useLocation();
    const { t } = useTranslation();
    const history = useHistory();
    const { trackPageView, trackEvent } = useMatomo();
    
    const q3 = steps.items.filter((item) => item.questionNo === "03")[0];
    const q5 = steps.items.filter((item) => item.questionNo === "05")[0];
    const q7 = steps.items.filter((item) => item.questionNo === "07")[0];
    const q8 = steps.items.filter((item) => item.questionNo === "08")[0];
    const q9 = steps.items.filter((item) => item.questionNo === "09")[0];
    const q10 = steps.items.filter((item) => item.questionNo === "10")[0];
    const q11 = steps.items.filter((item) => item.questionNo === "11")[0];
    const q12 = steps.items.filter((item) => item.questionNo === "12")[0];
    const q13 = steps.items.filter((item) => item.questionNo === "13")[0];
    const q14 = steps.items.filter((item) => item.questionNo === "14")[0];
    const q15 = steps.items.filter((item) => item.questionNo === "15")[0];
    const q16 = steps.items.filter((item) => item.questionNo === "16")[0];
    const q17 = steps.items.filter((item) => item.questionNo === "17")[0];
    const q18 = steps.items.filter((item) => item.questionNo === "18")[0];
    const q19 = steps.items.filter((item) => item.questionNo === "19")[0];

    const q18_active_default_method = q18.questionActive === 1 ? q18.options.filter((item)=> item.optionActive === 1)[0].optionContent : '';
    const q19_active_default_time = q19.questionActive === 1? q19.options.filter((item)=> item.optionActive === 1)[0].optionContent : '';

    const emailRegex = /^[A-Za-z0-9_\-.]{1,64}@[A-Za-z0-9_\-.]{1,63}\.[A-Za-z0-9_\-.]{1,63}$/;
    const [timeslots, setTimeslots] = useState([]);
    const [mamatasTimeslots, setMamatasTimeslots] = useState([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(step2_time);
    const [appointmentMethod, setAppointmentMethod] = useState(step2_appointmentMethod);
    const [storeList, setStoreList] = useState([]);
    const [selectedStore, setSelectedStore] = useState(step2_selectedStore);
    const [selectedMamatasBranch, setSelectedMamatasBranch] = useState(step2_selectedBranch);
    const [timeSlotsLoading, setLoading2TimeSlots] = useState(false);
    const [selectedDate, setSelectedDate] = useState(step2_date);
    const [firstNameKanji, setFirstNameKanji] = useState(step2_first_name_kanji);
    const [lastNameKanji, setLastNameKanji] = useState(step2_last_name_kanji);
    const [firstNameKana, setFirstNameKana] = useState(step2_first_name_kana);
    const [lastNameKana, setLastNameKana] = useState(step2_last_name_kana);
    const [email, setEmail] = useState(step2_email);
    const [dob, setDob] = useState(step2_dob ? step2_dob : (state.locationId === 'mamatas'? '1995/01/01' : '1970/01/01'));
    const [gender, setGender] = useState(step2_gender ? step2_gender : t('general.fields.gender.no_answer'));
    const [postCode, setPostCode] = useState(step2_postCode);
    const [prefectures, setPrefetchures] = useState(step2_prefectures);
    const [municipalities, setMunicipalities] = useState(step2_municipalities);
    const [address, setAddress] = useState(step2_address);
    const [companyEmail, setCompanyEmail] = useState(step2_company_email);
    const [department, setDepartment] = useState(step2_department);
    const [extensionNumber, setExtensionNumber] = useState(step2_extension_number);
    const [preferredAppointmentMethod, setPreferredAppointmentMethod] = useState(step2_preferred_appointment ? step2_preferred_appointment : q18_active_default_method);
    const [convenientTime, setConvenientTime] = useState(step2_convenient_time ? step2_convenient_time : q19_active_default_time);
    const [phone, setPhone] = useState(step2_phone);
    const [employeeNumber, setEmployeeNumber] = useState(step2_employee_number);
    const [remark, setRemark] = useState(step2_remark);
    const [isFirstNameKanji, setIsFirstNameKanji] = useState('');
    const [isLastNameKanji, setIsLastNameKanji] = useState('');
    const [isFirstNameKana, setIsFirstNameKana] = useState('');
    const [isLastNameKana, setIsLastNameKana] = useState('');
    const [isemail, setIsEmail] = useState('');
    const [isCompanyEmail, setIsCompnayEmail] = useState('');
    const [isDepartment, setIsDepartment] = useState('');
    const [isExtensionNumber, setIsExtensionNumber] = useState('');
    const [isPrefecture, setIsPrefecture] = useState('');
    const [isMunicipalities, setIsMunicipalities] = useState('');
    const [isDob, setIsDob] = useState('');
    const [isAddress, setIsAddress] = useState('');
    const [isphone, setIsPhone] = useState('');
    const [isRemark, setIsRemark] = useState('');
    const [isdate, setIsDate] = useState(true);
    const [istime, setIsTime] = useState(true);
    const [isPostCode, setIsPostCode] = useState(true);
    const [isEmployeeNumber , setIsEmployeeNumber] = useState(true);
    const [showConvenientTime, setShowConvenientTime] = useState(false);
    const [showRemark, setShowRemark] = useState(false);
    const [itemOrder, setItemOrder] = useState([]);
    const [isStore, setIsStore] = useState('');

    useEffect(() => {
        trackPageView();
        if(!state.locationId){
            let pathnames = [`agents/${state.branchId}`, `agent_detail/${state.branchId}`, pathname.substring(1)];
            setBreadcrumbsPath(pathnames);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const getMamatasTimeSlotData = async () => {
            const onSuccess = (data) => {
                setMamatasTimeslots(data);
            }
            const onFail = (error) => {
                setMamatasTimeslots([]);
            }
            await getAvailableTimeSlotsByBranchId({branchId: selectedMamatasBranch ? selectedMamatasBranch : state.branchId}, onSuccess, onFail )
        }

        if(state?.locationId && state?.locationId === 'mamatas'){
            getMamatasTimeSlotData();
        }
    }, [selectedMamatasBranch, state?.locationId, state.branchId]);

    useEffect(() => {
        TagManager.dataLayer(pageview);
        const getTimeSlotsData = async (branchId, agentUuid, day) => {
            const body = {
                branchId,
                agentUuid,
                day
            }
            const onSuccess = (data) => {
                setTimeslots(data);
                setSelectedTimeSlot(step2_time);
            }
            const onFail = (error) => {
                setTimeslots([]);
                setSelectedTimeSlot('');
            }
            if(!state.locationId){
                await getAvailableTimeSlotsByUuid(body, onSuccess, onFail);
            }else{
                getTimeslotsFromConfig(step2_date);
                const availableTimeSlots = getTimeslotsFromConfig(day);
                onSuccess(availableTimeSlots)
            }
        }
        if(state !== undefined && state.branchId !== undefined && state.agentData !== undefined && step2_date !== undefined && step2_date !== '') {
            getTimeSlotsData(state.agentData.branchId, state.agentData.agentId, step2_date);
        } else if (state !== undefined && state.branchId !== undefined && selectedDate !== undefined && selectedDate !== '') {
            getTimeSlotsData(null, null, selectedDate);
        }
    }, [step2_date, step2_time, state, mamatasTimeslots]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        let itemList = [];
        let autoIndex = 1;
        if(steps.items){
            steps.items.forEach((element, index) => {
                if(element.questionActive === 1){
                    itemList.push({
                        questionNo: element.questionNo,
                        index: autoIndex
                    })
                    autoIndex++;
                }
           })
        }
        setItemOrder(itemList)
    }, [steps]);

    useEffect(()=> {
        setShowConvenientTime(preferredAppointmentMethod !== 'メール');
        setShowRemark(preferredAppointmentMethod === 'その他（ご要望などございましたら備考欄にご記入ください）');
    },[preferredAppointmentMethod]);

    useEffect(() => {
        if(state.locationId ==='mamatas' && state.branchId){
            getMamatasStoreList();
        }
    },[state.branchId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(state.locationId === 'mamatas' && appointmentMethod === 1 && isBlock(selectedDate)){
            setTimeslots([]);
        }else{
            const availableTimeSlots = getTimeslotsFromConfig(selectedDate);
            setTimeslots(availableTimeSlots);
        }
    }, [appointmentMethod]); // eslint-disable-line react-hooks/exhaustive-deps

    useNonInitialEffect(() => {
        setLoading2TimeSlots(true);
        const getTimeSlotsData = async (branchId, agentUuid, day) => {
            const body = {
                branchId,
                agentUuid,
                day
            }
            const onSuccess = (data) => {
                if(state.locationId === 'mamatas' && appointmentMethod === 1 && isBlock(selectedDate)){
                    setTimeslots([]);
                }else{  
                    setTimeslots(data);
                }
                setSelectedTimeSlot('');
                setLoading2TimeSlots(false);
            }
            const onFail = (error) => {
                console.log(error);
                setTimeslots([]);
                setSelectedTimeSlot('');
                setLoading2TimeSlots(false);
            }

            if(!state.locationId){
                await getAvailableTimeSlotsByUuid(body, onSuccess, onFail);
            }else{
               const availableTimeSlots = getTimeslotsFromConfig(day);
               onSuccess(availableTimeSlots)
            }
        }
        if (state !== undefined && state.branchId !== undefined && state.agentData !== undefined && selectedDate !== undefined && selectedDate !== '') {
            getTimeSlotsData(state.agentData.branchId, state.agentData.agentId, selectedDate);
        } else if (state !== undefined && state.branchId !== undefined && selectedDate !== undefined && selectedDate !== '') {
            getTimeSlotsData(null, null, selectedDate);
        }
    }, [step2_time, state, selectedDate]);

    document.title = !state.locationId ? '相談予約Step2' : state.locationId === 'mamatas' ? '相談予約Step2（ママタス）': '相談予約Step2（団体予約）';

    const getMamatasStoreList = async (data) => {
        await getMamatasBranchList(onBranchSuccess, onBranchFail);
    }

    const onBranchSuccess = (data) => {
        let list = data.filter(item => item.branchId !== '2000002001');
        for(let i = 0; i < list.length; i++){
            list[i]["name"] = list[i]["branchName"]
            list[i]["id"] = list[i]["branchName"];
        }
        setStoreList(list);
    }

    const onBranchFail = (error) => {
        console.log(error);
    }

    const getTimeslotsFromConfig = (day) => {
        let blocked;
        let availableTimeSlots = [];
        const getBlockDate = blockDates.filter((item)=>{
            return item.date === day
        })

        if(getBlockDate.length > 0){
            blocked = getBlockDate[0].timeSlots
        }

        const getStatus = (timeSlot)=> {
            if(blocked){
                return blocked.some((item)=>{
                    return item === timeSlot
                })
            }
            return false;
           
        }

        if(state?.locationId === 'reservation'){
            step2_group_timeSlots?.map((item)=>{
                if(!getStatus(item)){
                    availableTimeSlots.push( {
                        "timeSlot" : item,
                        "block" : false
                      })
                }
                return true;
            })
        }else if (state.locationId === 'mamatas'){
            mamatasTimeslots.forEach(item => {
                availableTimeSlots.push({
                    "timeSlot" : item,
                    "block" : false
                })
            });
        }
        
        return availableTimeSlots;
    }

    const handleTimeSlotChange = (value) => {
        setSelectedTimeSlot(value);
        trackEvent({ category: 'onTimeSlotSelect', action: 'step2-click-event' });
    };
    const getSelectedDate = (value) => {
        value && setSelectedDate(value);
    }
    const nameKanjiCheck = (name) => {
        // const bln = new RegExp(/^[\u30A0-\u30FF]+$/).test(name)
        if (name.match(/^[\u3041-\u9FA0+\u3000+\u0020+\u0061-\u007a+\u0041-\u005a+\u3005]+$/)) return true;
        else return false;
    }
    // const convertToKana = (value, name) => {
    //     const jp_conv = converter.convert(value);
    //     name === 'firstName' ? setIsFirstNameKana('') : setIsLastNameKana('');
    //     if(jp_conv){
    //         if(jp_conv.kanji){
    //             const body = { kanjiName: jp_conv.kanji };
    //             getKatakana(body).then(res => {
    //                 if(res){
    //                     name === 'firstName' ? setFirstNameKana(res) : setLastNameKana(res);
    //                 }
    //             })
    //         }else{
    //             name === 'firstName' ? setFirstNameKana(jp_conv.katakana) : setLastNameKana(jp_conv.katakana);
    //         }
    //     }
        
    // }
    const handleFirstNameKanji = (event) => {
        if (event.target.value && !nameKanjiCheck(event.target.value)) {
            setIsFirstNameKanji('formatError')
        } else {
            setIsFirstNameKanji('')
        }
        setFirstNameKanji(event.target.value);
        // convertToKana(event.target.value, 'firstName');
    }
    const handleLastNameKanji = (event) => {
        if (event.target.value && !nameKanjiCheck(event.target.value)) {
            setIsLastNameKanji('formatError')
        } else {
            setIsLastNameKanji('')
        }
        setLastNameKanji(event.target.value);
        // convertToKana(event.target.value, 'lastName');
    }
    const nameKanaCheck = (name) => {
        // const bln = new RegExp(/[\u30A1-\u30F6]*/).test(name)
        if (name.match(/^[\u30A1-\u30F6+\u30FC+\u3000+\u0020]+$/)) return true;
        else return false;
    }
    const handleFirstNameKana = (event) => {
        if (event.target.value && !nameKanaCheck(event.target.value)) {
            setIsFirstNameKana('formatError')
        } else {
            setIsFirstNameKana('')
        }
        setFirstNameKana(event.target.value);
    }
    const handleLastNameKana = (event) => {
        if (event.target.value && !nameKanaCheck(event.target.value)) {
            setIsLastNameKana('formatError')
        } else {
            setIsLastNameKana('')
        }
        setLastNameKana(event.target.value);
    }
    const emailCheck = (mail) => {
        if (mail.match(emailRegex)) return true;
        else return false;
    }
    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handleCompanyEmail = (event) => {
        setCompanyEmail(event.target.value);
    }

    const handleAppointmentMethod = (e) => {
        setAppointmentMethod(parseInt(e.target.value));
        trackEvent({ category: 'onAppointmentMethodSelect', action: 'step2-click-event' });
        if(state.locationId === 'mamatas'){
            setSelectedTimeSlot('');
        }
        if(parseInt(e.target.value) === 2){
            setSelectedMamatasBranch('');
            setSelectedStore('');
        }
    }

    const handleStores = (e, value) =>{
        setSelectedStore(value);
        setSelectedMamatasBranch(storeList.filter(item => item.branchName === value)[0]?.branchId);
        trackEvent({ category: 'onMamatasAppointmentStoreSelect', action: 'step2-click-event' });
    }

    const handleDepartment = (event) => {
        if (countWord(event.target.value) > 200) {
            setIsDepartment('lengthError');
        } else {
            setIsDepartment('');
        }
        setDepartment(event.target.value);
    }
    const handlePhone = (event) => {
        if (event.target.value && isNaN(event.target.value)) {
            setIsPhone('formatError');
        }else if (!noFullWidth(event.target.value)){
            setIsPhone('formatError');
        }
        else {
            setIsPhone('')
        }
        setPhone(event.target.value);
    }

    const handleEmployeeNumber = (event) => {
        if (countWord(event.target.value) > 100) {
            setIsEmployeeNumber('lengthError');
        }else if(!noFullWidth(event.target.value)){
            setIsEmployeeNumber('formatError');
        } else {
            setIsEmployeeNumber('');
        }
        setEmployeeNumber(event.target.value);
    }

    const handleGenderSelect = (e) => {
        setGender(e.target.value);
        trackEvent({ category: 'onGenderSelect', action: 'step2-click-event' });
    }

    const handlePreferredAppointmentMethod = (e) => {
        setPreferredAppointmentMethod(e.target.value);
        trackEvent({ category: 'onPreferredContactMethodSelect', action: 'step2-click-event' });
    }

    const handleExtensionNumber = (e) => {
        if (e.target.value && isNaN(e.target.value)) {
            setIsExtensionNumber('formatError');
        }else{
            setIsExtensionNumber('');
        }
        setExtensionNumber(e.target.value);
    }

    const handleConvenientTime = (e) => {
        setConvenientTime(e.target.value);
        trackEvent({ category: 'onConvenientContactTimeSelect', action: 'step2-click-event' });
    }

    const handleRemark = (e) => {
        if (countWord(e.target.value) > 2000) {
            setIsRemark('lengthError');
        }else {
            setIsRemark('');
        }
        setRemark(e.target.value);
    }

    const handlePostCode = async (e) => {
        let value = e.target.value;  
        setPostCode(value);
            if(value.length >= 7){
                setIsPostCode('');
                const onSuccess = (data) => {
                    if (data){
                        setIsPostCode('');
                        setPrefetchures(data.pref);
                        setMunicipalities(data.city + data.town);
                    }else{
                        setIsPostCode('formatError');
                        setPrefetchures('');
                        setMunicipalities('');
                    }
                }
                const onFail = (error) => {
                    setIsPostCode('formatError')
                }
                await getZipAddress(value, onSuccess, onFail )
            }
    }

    const handlePrefectures = (e, value) => {
        setPrefetchures(value)
        trackEvent({ category: 'onPrefectureSelect', action: 'step2-click-event' });
    }

    const handleMunicipalities = (e) => {
        if(countWord(e.target.value) > 100){
            setIsMunicipalities('lengthError');
        }else{
            setIsMunicipalities('');
        }
        setMunicipalities(e.target.value)
    }

    const handleAddress = (e) => {
        if(countWord(e.target.value) > 100){
            setIsAddress('lengthError');
        }else{
            setIsAddress('');
        }
        setAddress(e.target.value)
    }
    const getItemPosition = (questionNo) => {
        let index = itemOrder.filter((item)=> item.questionNo === questionNo)[0]?.index;
        if(index){
            if(state.locationId === 'mamatas'){
                    if(questionNo === '03' || questionNo === '09' || questionNo === '10'){
                        return questionNo === '09' ? 'pl-lg-5' : 'pr-lg-5';
                    }
                    if(index % 2 !== 0){
                        return q5.questionActive === 1 ? 'pl-lg-5' : 'pr-lg-5';
                    }
                    return q5.questionActive !== 1 ? 'pl-lg-5' : 'pr-lg-5';
            }
            if(index % 2 !== 0){
                return q5.questionActive === 1 ? 'pr-lg-5' : 'pl-lg-5'
            }
        }
        return q5.questionActive !== 1 ? 'pr-lg-5' : 'pl-lg-5'
    }

    const onTextBoxFocus = (eventCategory) => {
        trackEvent({ category: eventCategory, action: 'step2-input-event' });
    }

    const onBack = () => {
        const backButtonEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': 'click, Back to answer step 1 of Appointment Reservation'
            }
        }
        trackEvent({ category: 'onBack', action: 'step2-click-event' });
        TagManager.dataLayer(backButtonEvent);
        history.goBack();
    }
  
    const onNext = () => {
        const nextButtonEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': 'click, Answer step 2 of Appointment Reservation'
            }
        }
        TagManager.dataLayer(nextButtonEvent);
        trackEvent({ category: 'onNext', action: 'step2-click-event' });
        let success = true;
        let alreadyFocus = false;

        if(q5.questionActive){
            if (selectedTimeSlot === '') {
                success = false;
                setIsTime(false);
                document.getElementById("timeslots").focus();
                alreadyFocus = true;
            }
            else {
                setIsTime(true);
            }

            if (selectedDate === '') {
                success = false;
                setIsDate(false);
            } else {
                setIsDate(true);
            }
        }

        if (q3.questionActive && appointmentMethod === 2 && state.locationId) {
            if(success === false && alreadyFocus === false) {
                document.getElementById("appointmentMethod").focus();
                alreadyFocus = true;
          }
        }

        if(q3.questionActive && appointmentMethod === 1 && state.locationId === 'mamatas'){
            if(selectedStore === ''){
                success = false;
                setIsStore('null');
                if(success === false && alreadyFocus === false) {
                    document.getElementById("store").scrollIntoView();
                    alreadyFocus = true;
                }
            }else{
                setIsStore('');
            }
        }

        if(q7.questionActive){
            if (firstNameKanji === '') {
                success = false;
                setIsFirstNameKanji('null');
            }
            else if (firstNameKanji.length > 200) {
                success = false;
                setIsFirstNameKanji('lengthError');
            }
            else if (isFirstNameKanji === 'formatError') {
                success = false;
            }
            else {
                setIsFirstNameKanji('');
            }
            if(success === false && alreadyFocus === false) {
                document.getElementById("firstNameKanji").focus();
                alreadyFocus = true;
            }
            
            if (lastNameKanji === '') {
                success = false;
                setIsLastNameKanji('null');
            }
            else if (lastNameKanji.length > 200) {
                success = false;
                setIsLastNameKanji('lengthError');
            }
            else if (isLastNameKanji === 'formatError') {
                success = false;
            }
            else {
                setIsLastNameKanji('');
            }
            if(success === false && alreadyFocus === false) {
                    document.getElementById("lastNameKanji").focus();
                    alreadyFocus = true;
            }
        }
        
        if(q8.questionActive){
            if (firstNameKana === '') {
                success = false;
                setIsFirstNameKana('null');
            }
            else if (firstNameKana.length > 200) {
                success = false;
                setIsFirstNameKana('lengthError');
            }
            else if (isFirstNameKana === 'formatError') {
                success = false;
            }
            else {
                setIsFirstNameKana('');
            }
            if(success === false && alreadyFocus === false) {
                document.getElementById("firstNameKana").focus();
                alreadyFocus = true;
            }
            
            if (lastNameKana === '') {
                success = false;
                setIsLastNameKana('null');
            }
            else if (lastNameKana.length > 200) {
                success = false;
                setIsLastNameKana('lengthError');
            }
            else if (isLastNameKana === 'formatError') {
                success = false;
            }
            else {
                setIsLastNameKana('');
            }
            if(success === false && alreadyFocus === false) {
                document.getElementById("lastNameKana").focus();
                alreadyFocus = true;
            }
        }
        
        if(q14.questionActive){
            if(postCode === ''){
                success = false;
                setIsPostCode('null');
                if(success === false && alreadyFocus === false) {
                    document.getElementById("postCode").focus();
                    alreadyFocus = true;
                }
            }else{
                setIsPostCode('');
            }

            if(prefectures === ''){
                success = false;
                setIsPrefecture('null');
                if(success === false && alreadyFocus === false) {
                    document.getElementById("prefectures").scrollIntoView();
                    alreadyFocus = true;
                }
            }else{
                setIsPrefecture('');
            }

            if(municipalities === ''){
                success = false;
                setIsMunicipalities('null');
                if(success === false && alreadyFocus === false) {
                    document.getElementById("municipalities").focus();
                    alreadyFocus = true;
                }
            }else{
                setIsMunicipalities('')
            }

            if(address === ''){
                success = false;
                setIsAddress('null');
                if(success === false && alreadyFocus === false) {
                    document.getElementById("address").focus();
                    alreadyFocus = true;
                }
            }else{
                setIsAddress('');
            }
        }

        if(q15.questionActive){
            if(companyEmail === ''){
                success = false;
                setIsCompnayEmail('null')
            }
            else if (companyEmail.length > 256) {
                success = false;
                setIsCompnayEmail('lengthError');
            }
            else if (!emailCheck(companyEmail)) {
                success = false;
                setIsCompnayEmail('formatError');
            }
            else {
                setIsCompnayEmail('');
            }
            if(success === false && alreadyFocus === false) {
                document.getElementById("companyEmail").focus();
                alreadyFocus = true;
            }
        }

        if(q9.questionActive){
            if (state.locationId !== 'mamatas' && email === '') {
                success = false;
                setIsEmail('null');
            }
            else if (email.length > 256) {
                success = false;
                setIsEmail('lengthError');
            }
            else if (state.locationId !== 'mamatas' ? !emailCheck(email) : (email !== '' && !emailCheck(email))) {
                success = false;
                setIsEmail('formatError');
            }
            else {
                setIsEmail('');
            }
            if(success === false && alreadyFocus === false) {
                document.getElementById("email").focus();
                alreadyFocus = true;
            }
        }

        if(q10.questionActive){
            if (phone === '') {
                success = false;
                setIsPhone('null');
            }
            else if (phone.length > 11 || phone.length < 10) {
                success = false;
                setIsPhone('lengthError');
            }
            else if (isphone === 'formatError') {
                success = false;
            }
            else {
                setIsPhone('');
            }
            if(success === false && alreadyFocus === false) {
                document.getElementById("phone").focus();
                alreadyFocus = true;
            }
        }

        if(q11.questionActive){
            if(employeeNumber === ''){
                success = false;
                setIsEmployeeNumber('null');
            }
            else if (isEmployeeNumber === 'lengthError') {
                success = false;
            }else if (isEmployeeNumber === 'formatError'){
                success = false;
            }
            else {
                setIsEmployeeNumber('');
            }
            if(success === false && alreadyFocus === false) {
                document.getElementById("employeeNumber").focus();
                alreadyFocus = true;
            }
        }

        if(q12.questionActive){
            if(dob.length !== 10){
                success = false;
                setIsDob('null');
            }else {
                setIsDob('');
            }

            if(success === false && alreadyFocus === false) {
                document.getElementById("dob").scrollIntoView();
                alreadyFocus = true;
            }
        }

        if(q16.questionActive){
            if(department === ''){
                success = false;
                setIsDepartment('null');
            }else if (isDepartment === 'lengthError') {
                success = false;
            }else{
                setIsDepartment('');
            }

            if(success === false && alreadyFocus === false) {
                document.getElementById("department").focus();
                alreadyFocus = true;
            }
        }

        if(q17.questionActive){
            if(extensionNumber === ''){
                success = false;
                setIsExtensionNumber('null');
            }else if (extensionNumber.length > 10) {
                success = false;
                setIsExtensionNumber('lengthError')
            }else if (isExtensionNumber === 'formatError') {
                success = false;
            }else{
                setIsExtensionNumber('');
            }
            if(success === false && alreadyFocus === false) {
                document.getElementById("extensionNumber").focus();
                alreadyFocus = true;
            }
        }
        if(q19.questionActive === 1 && preferredAppointmentMethod === "その他（ご要望などございましたら備考欄にご記入ください）"){
            if(isRemark === 'lengthError'){
                success = false;
            }
            if(success === false && alreadyFocus === false) {
                document.getElementById("remark").focus();
                alreadyFocus = true;
            }
        }

        if (success) {
            setStep2AppointmentMethod(appointmentMethod)
            setStep2SelectedStore(selectedStore)
            setSelectedBranch(selectedMamatasBranch);
            setStep2Date(selectedDate);
            setStep2Time(selectedTimeSlot);
            setStep2FirstNameKana(firstNameKana);
            setStep2LastNameKana(lastNameKana);
            setStep2FirstNameKanji(firstNameKanji);
            setStep2LastNameKanji(lastNameKanji);
            setStep2Email(email);
            setStep2Phone(phone);
            setStep2EmpoyeeNumber(employeeNumber);
            setStep2Dob(q12.questionActive === 0 ? "" : dob);
            setStep2Gender(q13.questionActive === 0 ? "" : gender);
            setStep2PostCode(postCode);
            setStep2Prefectures(prefectures);
            setStep2Municipalities(municipalities);
            setStep2Address(address);
            setStep2CompanyEmail(companyEmail);
            setStep2Department(department);
            setStep2ExtensionNumber(extensionNumber);
            if(q18.questionActive){
                setStep2PreferredAppointmentMethod(preferredAppointmentMethod);
                setStep2ConvenientTime(convenientTime);
                if(preferredAppointmentMethod === "その他（ご要望などございましたら備考欄にご記入ください）"){
                    setStep2Remark(remark);
                }else{
                    setStep2Remark("");
                }
                
            }
            window.scrollTo(0, 0);
            if(state.locationId){
                history.push({ pathname: state.locationId === 'mamatas' ? `/mamatas_reservation_step_3/${state.branchId}` : `/group_reservation_step_3/${state.branchId}`, state: { branchId: state.branchId, locationId: state.locationId, skip_1: state.skip_1  } });            
            }else{
                history.push({ pathname: `/reservation_step_3/${state.branchId}`, state: { branchId: state.branchId, agentData: state.agentData} });
            }
        }
    }

    return (
        (state === undefined || state.branchId === null || state.branchId === '') ?
            <PageNotFound />
            :
            <ARContainer>
                <AppointmentStepper step_no={2} skip_1={state.skip_1} />
                {
                    q3.questionActive === 1 && state.locationId === 'mamatas' && state.branchId &&
                    <Row className={classes.method_fields}>
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('03')} ${classes.field}`}>
                            <label className={classes.label}>{q3.questionContent}</label>
                                <div className={classes.gender_radio}>
                                    <RadioButton
                                        labelClassName={classes.agent_before_radio_button}
                                        id="appointment-method"
                                        value={2}
                                        label={t('appointment_reservation_page.step_2.discuss_online')}
                                        selectedValue={appointmentMethod}
                                        onChange={handleAppointmentMethod} />
                                    <RadioButton
                                        labelClassName={classes.agent_before_radio_button}
                                        id="appointment-method"
                                        value={1}
                                        label={t('appointment_reservation_page.step_2.choice_store_discuss')}
                                        selectedValue={appointmentMethod}
                                        onChange={handleAppointmentMethod} />
                                </div>
                        </Col> 
                        {appointmentMethod === 1 &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('')} ${classes.field}`}>
                            <label id="store" className={classes.label}>{t('branches_selection_page.title')}</label>
                            <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />
                            <GeneralDropdown 
                                items={storeList}
                                onSelect={handleStores}
                                className={classes.store_dropdown}
                                selectedItem={selectedStore}/>
                            {(isStore === 'null' || isStore === 'formatError' || isStore === 'lengthError') 
                                &&<ErrorMessage className={`${classes.date_warning_msg}`} message={t('branches_selection_page.error_message')} />}
                        </Col>
                        }
                    </Row>
                }
                { q5.questionActive === 1 &&
                <div className={`row`}>
                    <Col xs={12} sm={12} md={12} lg={6} className={`pr-lg-5 ${classes.calendar_child}`}>
                        <span tabIndex="0" id="calendar" className={classes.title}>{t('appointment_reservation_page.step_2.date.title')}</span>
                        <GeneralCard className={`${classes.calendar_card} ${classes.testing}`}>
                            {
                                state.locationId === 'mamatas' ?
                                <CalendarMamatas onSelect={getSelectedDate} selectedDate={step2_date} locationId={state.locationId} />
                                :
                                <Calendar onSelect={getSelectedDate} selectedDate={step2_date} clickPosition='step2' />
                            }
                            {!isdate && <ErrorMessage className={`${classes.date_warning_msg}`} message={t('appointment_reservation_page.step_2.date.require_date')} />}
                        </GeneralCard>
                        {(selectedDate && selectedDate !== '') && <span className={classes.border}></span>}
                    </Col>

                    {
                        (selectedDate && selectedDate !== '') &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`pl-lg-5 d-flex ${classes.calendar_child}`}>
                            <div className="w-100">
                                <Row className={`${classes.calendar}`}>
                                    <Col><span tabIndex="0" id="timeslots" className={classes.title}>{t('appointment_reservation_page.step_2.time.title')}</span></Col>
                                </Row>
                                <GeneralCard className={`${classes.calendar_card} ${classes.testing}`}>
                                    {
                                        timeSlotsLoading ?
                                            <div className={classes.loading_container}>
                                                <img src={require('../../../assets/images/icons/timeslot_loading.gif').default} className={classes.loading_icon} alt="loading..." />
                                            </div>
                                            :
                                            <div className={`${classes.space_between}`}>
                                                <div>
                                                    {
                                                        timeslots.length === 0 ?
                                                            <Row>
                                                                <h6 className={classes.empty_timeslots_msg}>{t('appointment_reservation_page.step_2.time.empty_timeslots_msg')}</h6>
                                                            </Row>
                                                            :
                                                            <>
                                                                <Row >
                                                                    <Col xs={6} sm={6} >
                                                                        {timeslots && timeslots.slice(0, Math.ceil(timeslots.length / 2)).map((timeslot, index) => (
                                                                            <TimeSlot key={index} value={convertPeroid(timeslot.timeSlot)} selectedValue={selectedTimeSlot} onChange={handleTimeSlotChange} />
                                                                        ))}
                                                                    </Col>
                                                                    <Col xs={6} sm={6}>
                                                                        {timeslots && timeslots.slice(timeslots.length - Math.floor(timeslots.length / 2)).map((timeslot, index) => (
                                                                            <TimeSlot key={index} value={convertPeroid(timeslot.timeSlot)} selectedValue={selectedTimeSlot} onChange={handleTimeSlotChange} />
                                                                        ))}
                                                                    </Col>
                                                                </Row>
                                                                {!istime && <ErrorMessage message={t('appointment_reservation_page.step_2.time.require_timeslot')} />}
                                                            </>
                                                    }
                                                </div>
                                                <Row>
                                                    <Col>
                                                        <span className={`${classes.warning_msg} font-12`}>{t('appointment_reservation_page.step_2.time.notice')}</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                    }
                                </GeneralCard>
                            </div>
                        </Col>
                    }
                </div>
                }
                <Row className={classes.schedule_fields}>
                    {
                        q3.questionActive === 1 && state.locationId === 'reservation' &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('03')} ${classes.field}`}>
                            <label className={classes.label}>{q3.questionContent}</label>
                                <div className={classes.gender_radio}>
                                <RadioButton
                                    labelClassName={classes.agent_before_radio_button}
                                    id="appointment-method"
                                    value={1}
                                    label={t('appointment_reservation_page.step_1.question_2_no')}
                                    selectedValue={appointmentMethod}
                                    onChange={handleAppointmentMethod} />
                                <RadioButton
                                    labelClassName={classes.agent_before_radio_button}
                                    id="appointment-method"
                                    value={2}
                                    label={t('appointment_reservation_page.step_1.question_2_yes')}
                                    selectedValue={appointmentMethod}
                                    onChange={handleAppointmentMethod} />
                                </div>
                        </Col> 
                    }
                    {
                        q7.questionActive === 1 && 
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('07')} ${classes.field} ${classes.name_row}`}>
                            <div className={classes.name_col}>
                                <label className={classes.label}>{t('general.fields.first_name_kanji.title')}</label>
                                {state.locationId !== 'reservation' &&
                                    <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />}
                                <GeneralTextbox id="firstNameKanji" value={firstNameKanji} placeholder={t('general.fields.first_name_kanji.place_holder')} onChange={handleFirstNameKanji} onFocus={()=>{onTextBoxFocus('onFirstNameKanjiInput')}} />
                                {(isFirstNameKanji === 'null' || isFirstNameKanji === 'formatError' || isFirstNameKanji === 'lengthError')
                                    && <ErrorMessage message={t('general.fields.first_name_kanji.require')} />}
                            </div>
                            <div className={classes.name_col}>
                                <label className={classes.label}>{t('general.fields.last_name_kanji.title')}</label>
                                {state.locationId !== 'reservation' &&
                                    <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />}
                                <GeneralTextbox id="lastNameKanji" value={lastNameKanji} placeholder={t('general.fields.last_name_kanji.place_holder')} onChange={handleLastNameKanji} onFocus={()=>{onTextBoxFocus('onLastNameKanjiInput')}} />
                                {(isLastNameKanji === 'null' || isLastNameKanji === 'formatError' || isLastNameKanji === 'lengthError')
                                    && <ErrorMessage message={t('general.fields.last_name_kanji.require')} />}
                            </div>
                        </Col> 
                    }
                    {
                        q8.questionActive === 1 &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('08')} ${classes.field} ${classes.name_row}`}>
                            <div className={classes.name_col}>
                                <label className={classes.label}>{t('general.fields.first_name_kana.title')}</label>
                                {state.locationId !== 'reservation' &&
                                    <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />}
                                <GeneralTextbox id="firstNameKana" value={firstNameKana} placeholder={t('general.fields.first_name_kana.place_holder')} onChange={handleFirstNameKana} onFocus={()=>{onTextBoxFocus('onFirstNameKanaInput')}} />
                                {(isFirstNameKana === 'null' || isFirstNameKana === 'formatError' || isFirstNameKana === 'lengthError')
                                    && <ErrorMessage message={t('general.fields.first_name_kana.require')} />}
                            </div>
                            <div className={classes.name_col}>
                                <label className={classes.label}>{t('general.fields.last_name_kana.title')}</label>
                                {state.locationId !== 'reservation' &&
                                    <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />}
                                <GeneralTextbox id="lastNameKana" value={lastNameKana} placeholder={t('general.fields.last_name_kana.place_holder')} onChange={handleLastNameKana} onFocus={()=>{onTextBoxFocus('onLastNameKanaInput')}} />
                                {(isLastNameKana === 'null' || isLastNameKana === 'formatError' || isLastNameKana === 'lengthError')
                                    && <ErrorMessage message={t('general.fields.last_name_kana.require')} />}
                            </div>
                        </Col>
                    }
                    {
                        (q12.questionActive === 1 && state.locationId !== undefined) &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('12')} ${classes.field}`}>
                            <label className={classes.label} id={'dob'}  >{t('general.fields.dob.title')}</label>
                            {state.locationId === 'mamatas' && 
                                <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />}
                            <DobSpreadSelector setSelectedDob={setDob} selectedDob = {dob}  />
                            {(isDob === 'null')
                                && <ErrorMessage message={t('general.fields.dob.require')} />}
                        </Col> 
                    }
                    {
                        (q13.questionActive === 1 && state.locationId !== undefined) &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('13')} ${classes.field}`}>
                            <label className={classes.label}>{t('general.fields.gender.title')}</label>
                            <div className={classes.gender_radio}>
                                <RadioButton
                                    labelClassName={classes.agent_before_radio_button}
                                    id="gender"
                                    value={t('general.fields.gender.male')}
                                    label={t('general.fields.gender.male')}
                                    selectedValue={gender}
                                    onChange={handleGenderSelect} />
                                <RadioButton
                                    labelClassName={classes.agent_before_radio_button}
                                    id="gender"
                                    value={t('general.fields.gender.female')}
                                    label={t('general.fields.gender.female')}
                                    selectedValue={gender}
                                    onChange={handleGenderSelect} />
                                <RadioButton
                                    labelClassName={classes.agent_before_radio_button}
                                    id="gender"
                                    value={t('general.fields.gender.no_answer')}
                                    label={t('general.fields.gender.no_answer')}
                                    selectedValue={gender}
                                    onChange={handleGenderSelect} />
                            </div>
                        </Col> 
                    }
                    {
                        q14.questionActive === 1 && 
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('14')} ${classes.field}`}>
                            <div className={`${classes.address_row}`}>
                                <div>
                                    <label className={classes.label}>{t('general.fields.address.post_code.title')}</label>
                                    <>
                                    {state.locationId === 'mamatas' && 
                                        <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />}
                                    <ZipCodeSearch/>
                                    </>
                                    <GeneralTextbox id="postCode" value={postCode} onChange={handlePostCode} placeholder={t('general.fields.address.post_code.place_holder')} onFocus={()=>{onTextBoxFocus('onPostCodeInput')}} />
                                    {(isPostCode === 'null' || isPostCode === 'formatError' || isPostCode === 'lengthError')
                                    && <ErrorMessage message={t('general.fields.address.post_code.require')} />}
                                </div>
                                <div>
                                    <label id="prefectures" className={classes.label}>{t('general.fields.address.prefectures.title')}</label>
                                    {state.locationId === 'mamatas' && 
                                        <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />}
                                    <GeneralDropdown items={prefecturesData}  onSelect={handlePrefectures} className={classes.prefecture_dropdown}  selectedItem={prefectures}/>
                                    {(isPrefecture === 'null' || isPrefecture === 'formatError' || isPrefecture === 'lengthError')
                                    && <ErrorMessage message={t('general.fields.address.prefectures.require')} />}
                                </div>
                            </div>
                            <div className={classes.address_second_row} >
                                <label className={classes.label}>{t('general.fields.address.municipalities.title')}</label>
                                {state.locationId === 'mamatas' && 
                                    <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />}
                                <GeneralTextbox id="municipalities" value={municipalities} onChange={handleMunicipalities} onFocus={()=>{onTextBoxFocus('onMunicipalityInput')}} />
                                {(isMunicipalities === 'null' || isMunicipalities === 'formatError' || isMunicipalities === 'lengthError')
                                    && <ErrorMessage message={t('general.fields.address.municipalities.require')} />}
                            </div>
                            <div>
                                <label className={classes.label}>{t('general.fields.address.address.title')}</label>
                                {state.locationId === 'mamatas' && 
                                    <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />}
                                <GeneralTextbox id="address" value={address} onChange={handleAddress} onFocus={()=>{onTextBoxFocus('onAddressInput')}} /> 
                                {(isAddress === 'null' || isAddress === 'formatError' || isAddress === 'lengthError')
                                    && <ErrorMessage message={t('general.fields.address.address.require')} />}
                            </div>
                        </Col>
                    }
                    {
                        (q15.questionActive === 1 && state.locationId !== undefined) &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('15')} ${classes.field}`}>
                            <label className={classes.label}>{t('general.fields.company_email.title')}</label>
                            <GeneralTextbox id="companyEmail" value={companyEmail} placeholder={t('general.fields.company_email.place_holder')} onChange={handleCompanyEmail} onFocus={()=>{onTextBoxFocus('onCompanyEmailInput')}} />
                            {(isCompanyEmail === 'null' || isCompanyEmail === 'formatError' || isCompanyEmail === 'lengthError')
                                && <ErrorMessage message={t('general.fields.company_email.require')} />}
                        </Col>
                    }
                    {
                        q9.questionActive === 1 && 
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('09')} ${classes.field}`}>
                            <label className={classes.label}>{t( state.locationId === 'reservation' ? 'general.fields.private_email_address.title' : 'general.fields.email_address.title')}</label>
                            {!state.locationId &&
                                <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />}
                            <GeneralTextbox id="email" value={email} placeholder={t('general.fields.email_address.place_holder')} onChange={handleEmail} onFocus={()=>{onTextBoxFocus('onEmailInput')}} />
                            {(isemail === 'null' || isemail === 'formatError' || isemail === 'lengthError')
                                && <ErrorMessage message={t( state.locationId === 'reservation' ? 'general.fields.private_email_address.require' : 'general.fields.email_address.require')} />}
                            <span className="font-12">
                                {t('appointment_reservation_page.step_2.email_notice_label')}
                            </span>
                        </Col>
                    }
                    {
                        q10.questionActive === 1 &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('10')} ${classes.field}`}>
                            <label className={classes.label}>{t('general.fields.phone_number.title')}</label>
                           {state.locationId !== 'reservation' &&
                                <Mandatory text={t('general.fields.mandatory')} className={classes.mandatory} />}
                            <GeneralTextbox id="phone" value={phone} placeholder={t('general.fields.phone_number.place_holder')} onChange={handlePhone} onFocus={()=>{onTextBoxFocus('onPhoneNumberInput')}} />
                            {(isphone === 'null' || isphone === 'formatError' || isphone === 'lengthError')
                                && <ErrorMessage message={t('general.fields.phone_number.require')} />}
                        </Col>
                    }
                    {
                        (q11.questionActive === 1 && state.locationId !== undefined) &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('11')} ${classes.field}`}>
                            <label className={classes.label}>{t('general.fields.employeeNumber.title')}</label>
                            <GeneralTextbox id="employeeNumber" value={employeeNumber} placeholder={t('general.fields.employeeNumber.place_holder')} onChange={handleEmployeeNumber} onFocus={()=>{onTextBoxFocus('onEmployeeNumberInput')}} />
                            {(isEmployeeNumber === 'null' || isEmployeeNumber === 'formatError' || isEmployeeNumber === 'lengthError')
                                && <ErrorMessage message={t('general.fields.employeeNumber.require')} />}
                        </Col> 
                    }
                    {
                        (q16.questionActive === 1 && state.locationId !== undefined) &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('16')} ${classes.field}`}>
                            <label className={classes.label}>{t('general.fields.department.title')}</label>
                            <GeneralTextbox id="department" value={department} placeholder={t('general.fields.department.place_holder')} onChange={handleDepartment} onFocus={()=>{onTextBoxFocus('onDepartmentInput')}} />
                            {(isDepartment === 'null' || isDepartment === 'formatError' || isDepartment === 'lengthError')
                                && <ErrorMessage message={t('general.fields.department.require')} />}
                        </Col>
                    }   
                    {
                        (q17.questionActive === 1 && state.locationId !== undefined) &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('17')} ${classes.field}`}>
                            <label className={classes.label}>{t('general.fields.extension_number.title')}</label>
                            <GeneralTextbox id="extensionNumber" value={extensionNumber} placeholder={t('general.fields.extension_number.place_holder')} onChange={handleExtensionNumber} onFocus={()=>{onTextBoxFocus('onExtensionNumberInput')}} />
                            {(isExtensionNumber === 'null' || isExtensionNumber === 'formatError' || isExtensionNumber === 'lengthError')
                                && <ErrorMessage message={t('general.fields.extension_number.require')} />}
                        </Col>
                    }   
                    {
                        (q18.questionActive === 1 && state.locationId !== undefined) &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('18')} ${classes.field}  ${classes.column_flex}`}>
                            <label className={classes.label}>{t('general.fields.preferred_appointment_method.title')}</label>
                            <div className={classes.convenient_time}>
                                {
                                    q18.options.map((item, index)=>{
                                        return item.optionActive === 1 ?
                                        <RadioButton
                                        key={'preferred_appointment_method_'+index}
                                        labelClassName={classes.agent_before_radio_button}
                                        id="preferred_appointment_method"
                                        value={item.optionContent}
                                        label={item.optionContent}
                                        selectedValue={preferredAppointmentMethod}
                                        onChange={handlePreferredAppointmentMethod} />
                                        : <Fragment key={'fragment_tag'}></Fragment>
                                    })
                                    
                                }
                            </div>
                        </Col> 
                    }
                    {
                        (q19.questionActive === 1 && state.locationId !== undefined && showConvenientTime) &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${getItemPosition('19')} ${classes.field} ${classes.column_flex}`}>
                            <label className={classes.label}>{t('general.fields.convenient_time.title')}</label>
                            <div className={classes.convenient_time}>
                                {
                                    q19.options.map((item, index)=>{
                                        return item.optionActive === 1 ?
                                        <RadioButton
                                        key={'convenient_time'+index}
                                        labelClassName={classes.agent_before_radio_button}
                                        id="convenient_time"
                                        value={item.optionContent}
                                        label={item.optionContent}
                                        selectedValue={convenientTime}
                                        onChange={handleConvenientTime} /> :
                                        <Fragment key={'fragment_tag'}></Fragment>
                                    })
                                }
                            </div>
                        </Col> 
                    }
                    {
                        (state.locationId !== undefined && showRemark) &&
                        <Col xs={12} sm={12} md={12} lg={6} className={`${q19.questionActive === 1 ? 'pr-lg-5': 'pl-lg-5'} ${classes.field}`}>
                            <label className={classes.label}>{t('general.label.optional') + t('general.fields.remark.title')}</label>
                            <GeneralTextbox id="remark" value={remark} onChange={handleRemark} onFocus={()=>{onTextBoxFocus('onPreferredContactMethodInput')}} />
                            {(isRemark === 'null' || isRemark === 'formatError' || isRemark === 'lengthError')
                                && <ErrorMessage message={t('general.fields.remark.require')} />}
                        </Col> 
                    }
                </Row>
                <Row>
                    <Col className={classes.buttons_container}>
                        <NextButton onClick={onNext} className="order-2" />
                        {state.skip_1 ?  <></> : <BackButton onClick={onBack} className="order-1" />}
                    </Col>
                </Row>
            </ARContainer>
    )
}

const stateToProps = state => {
    return {
        step2_date: state.step2_date,
        step2_appointmentMethod: state.step2AppointmentMethod,
        step2_selectedStore: state.step2SelectedStore,
        step2_selectedBranch: state.selectedBranch,
        step2_time: state.step2_time,
        step2_first_name_kanji: state.step2_first_name_kanji,
        step2_last_name_kanji: state.step2_last_name_kanji,
        step2_first_name_kana: state.step2_first_name_kana,
        step2_last_name_kana: state.step2_last_name_kana,
        step2_email: state.step2_email,
        step2_phone: state.step2_phone,
        step2_employee_number: state.step2_employee_number,
        step2_dob: state.step2_dob,
        step2_gender: state.step2_gender,
        step2_postCode: state.step2_postCode,
        step2_prefectures: state.step2_prefectures,
        step2_municipalities: state.step2_municipalities,
        step2_address: state.step2_address,
        step2_company_email: state.step2_company_email,
        step2_department: state.step2_department,
        step2_extension_number: state.step2_extension_number,
        step2_preferred_appointment: state.step2_preferred_appointment_method,
        step2_convenient_time: state.step2_convenient_time,
        step2_remark: state.step2_remark,
        agent_id: state.agentId,
        steps: state.groupReservationConfig.steps.filter((item)=> item.step === 2)[0],
        step2_group_timeSlots: state.groupReservationConfig.timeSlots,
        blockDates: state.groupReservationConfig.blockDates,
        breadcrumbsPath: state.breadcrumbsPath
    }
}

const dispatchToProps = dispatch => {
    return {
        setStep2Date: (date) => {
            dispatch(setStep2Date(date));
        },
        setStep2AppointmentMethod: (appointmentMethod) => {
            dispatch(setStep2AppointmentMethod(appointmentMethod));
        },
        setStep2SelectedStore: (selectedStore) => {
            dispatch(setStep2SelectedStore(selectedStore));
        },
        setSelectedBranch: (selectedMamatasBranch) => {
            dispatch(setSelectedBranch(selectedMamatasBranch));
        },
        setStep2Time: (time) => {
            dispatch(setStep2Time(time));
        },
        setStep2FirstNameKanji: (first_name_kanji) => {
            dispatch(setStep2FirstNameKanji(first_name_kanji))
        },
        setStep2LastNameKanji: (last_name_kanji) => {
            dispatch(setStep2LastNameKanji(last_name_kanji))
        },
        setStep2FirstNameKana: (first_name_kana) => {
            dispatch(setStep2FirstNameKana(first_name_kana));
        },
        setStep2LastNameKana: (last_name_kana) => {
            dispatch(setStep2LastNameKana(last_name_kana));
        },
        setStep2Email: (email) => {
            dispatch(setStep2Email(email));
        },
        setStep2Phone: (email) => {
            dispatch(setStep2Phone(email));
        },
        setStep2EmpoyeeNumber: (employee_number) => {
            dispatch(setStep2EmpoyeeNumber(employee_number));
        },
        setStep2Dob: (dob) => {
            dispatch(setStep2Dob(dob));
        },
        setStep2Gender: (gender) => {
            dispatch(setStep2Gender(gender));
        },
        setStep2PostCode: (postCode) => {
            dispatch(setStep2PostCode(postCode));
        },
        setStep2Prefectures: (prefectures) => {
            dispatch(setStep2Prefectures(prefectures));
        },
        setStep2Municipalities: (municipalities) => {
            dispatch(setStep2Municipalities(municipalities));
        },
        setStep2Address: (address) => {
            dispatch(setStep2Address(address));
        },
        setStep2CompanyEmail: (company_email) => {
            dispatch(setStep2CompanyEmail(company_email));
        },
        setStep2Department: (department) => {
            dispatch(setStep2Department(department));
        },
        setStep2ExtensionNumber: (extension_number) => {
            dispatch(setStep2ExtensionNumber(extension_number));
        },
        setStep2PreferredAppointmentMethod: (preferred_appointment_method) => {
            dispatch(setStep2PreferredAppointmentMethod(preferred_appointment_method));
        },
        setStep2ConvenientTime: (convenient_time) => {
            dispatch(setStep2ConvenientTime(convenient_time));
        },
        setStep2Remark: (remark) => {
            dispatch(setStep2Remark(remark));
        },
        setBreadcrumbsPath: (breadcrumbsPath) => {
            dispatch(setBreadcrumbsPath(breadcrumbsPath))
        }
    }
}

export default connect(stateToProps, dispatchToProps)(ARStep2);