import React, { useState } from 'react';


import classes from './styles.module.css';

const GenderCard = ({ items, defaultItem, onSelect , className, style }) => {
    
    const [male, setMale] = useState(items[0].id === defaultItem);
    const [female, setFemale] = useState(items[1].id === defaultItem);
    const [notAnswer, setNotAnswer] = useState(items[2].id === defaultItem);

    const onMaleSelect = (e) => {
        setMale(true);
        setFemale(false);
        setNotAnswer(false);
        onSelect && onSelect(e.target.value, e.target.textContent);
    }

    const onFemaleSelect = (e) => {
        setMale(false);
        setFemale(true);
        setNotAnswer(false);
        onSelect && onSelect(e.target.value, e.target.textContent);
    }

    const onNotAnswerSelect = (e) => {
        setMale(false);
        setFemale(false);
        setNotAnswer(true);
        onSelect && onSelect(e.target.value, e.target.textContent);
    }
    
    return(
        <div className={`${classes.gender_card} ${className}`} style={style}>
            {/* <div className={classes.genders_container}> */}
                {/* {
                    items.map((item, index) => (
                        <div key={index} className={classes.gender_item} value={item.value} onClick={onMaleSelect}>
                            <li className={`PoppinsSemiBold ${male ? classes.selected_gender_label : classes.gender_label}`}>{item.name}</li>
                        </div>
                    ))
                } */}
                <div className={classes.gender_item}>
                    <li 
                        draggable="true"
                        value={items[0].id}
                        onClick={onMaleSelect} 
                        className={`PoppinsSemiBold ${male ? classes.selected_gender_label : classes.gender_label}`} >
                            {items[0].name}
                    </li>
                </div>
                <div className={classes.gender_item}>
                    <li 
                        draggable="true"
                        value={items[1].id}
                        onClick={onFemaleSelect} 
                        className={`PoppinsSemiBold ${female ? classes.selected_gender_label : classes.gender_label}`}>
                            {items[1].name}
                    </li>
                </div>
                <div className={classes.gender_item}>
                    <li 
                        draggable="true"
                        value={items[2].id}
                        onClick={onNotAnswerSelect} 
                        className={`PoppinsSemiBold ${notAnswer ? classes.selected_gender_label : classes.gender_label}`}>
                            {items[2].name}
                    </li>
                </div>
            {/* </div> */}
        </div>
    );
}

export default GenderCard;