import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { getAppointmentMethodContent } from '../../../utilities/helper'

import { resetAppointmentReservation, setBreadcrumbsPath } from '../../../store/actions';

import ARContainer from '../index';
import AppointmentStepper from '../AppointmentStepper';
import BackButton from '../../../components/Buttons/BackButton';
import SubmitButton from '../../../components/Buttons/SubmitButton';
import ErrorMessage from '../../../components/ErrorMessage';
import StyledCheckbox from '../../../components/Checkbox';
import PageNotFound from '../../PageNotFound';

import { insertAppointment } from '../../../api/Appointment/insertAppointment';

import { useRemoveSpace, useConvertWeekDay } from '../../../utilities/customHooks';

import classes from './styles.module.css';

const pageview = {
    dataLayer: {
        event: 'pageview',
        log_pageurl: '',
        log_pagename: ''
    }
}

const ARStep3 = ({
    birthYear,
    customerEmail,
    customerFirstNameKana,
    customerLastNameKana,
    customerFirstNameKanji,
    customerLastNameKanji,
    customerPhone,
    familyStructure,
    employeeNumber,
    gender,
    personal_gender,
    dob,
    remark,
    prefectures,
    municipalities,
    address,
    companyEmail,
    department,
    extensionNumber,
    preferredMethod,
    convenientTime,
    directQuestions,
    answeredDirQns,
    interestedInsurance,
    isEverRequested,
    appointmentMethod,
    requestSummary,
    selectedDate,
    selectedTime,
    resetAppointmentReservation,
    steps,
    discussStatus,
    discussStatusText,
    talkingDemand,
    talkingDemandText,
    selectedStore,
    selectedBranch,
    postCode,
    userId,
    breadcrumbsPath,
    setBreadcrumbsPath,
}) => {

    useEffect(() => {
        TagManager.dataLayer(pageview);
    }, []);

    const { state, pathname } = useLocation();
    const { t } = useTranslation();
    const history = useHistory();
    const { trackPageView, trackEvent } = useMatomo();
    const selectedTimeRS = useRemoveSpace(selectedTime);
    const convertedWeekDay = useConvertWeekDay(selectedDate);
    
    document.title = !state.locationId ? '相談予約Step3' : state.locationId === 'mamatas' ? '相談予約Step3（ママタス）': '相談予約Step3（団体予約）';

    const [submitBtnLoading, setLoading2SubmitBtn] = useState(false);
    const [submissionFailed, setSubmissionFailed] = useState(false);
    const [checked, setChecked] = useState(false);

    const appointment_method = directQuestions.find((el) => el.id === answeredDirQns.find((el) => el.questionId === 4)?.questionId)?.questionItems.find((el) => el.itemId === answeredDirQns.find((el) => el.questionId === 4)?.itemId);
    const q3 = steps[1].items.filter((item) => item.questionNo === "03")[0];
    const q4 = steps[0].items.filter((item) => item.questionNo === "04")[0];
    const q5 = steps[1].items.filter((item) => item.questionNo === "05")[0];
    const q7 = steps[1].items.filter((item) => item.questionNo === "07")[0];
    const q8 = steps[1].items.filter((item) => item.questionNo === "08")[0];
    const q9 = steps[1].items.filter((item) => item.questionNo === "09")[0];
    const q10 = steps[1].items.filter((item) => item.questionNo === "10")[0];
    const q11 = steps[1].items.filter((item) => item.questionNo === "11")[0];
    const q12 = steps[1].items.filter((item) => item.questionNo === "12")[0];
    const q13 = steps[1].items.filter((item) => item.questionNo === "13")[0];
    const q14 = steps[1].items.filter((item) => item.questionNo === "14")[0];
    const q15 = steps[1].items.filter((item) => item.questionNo === "15")[0];
    const q16 = steps[1].items.filter((item) => item.questionNo === "16")[0];
    const q17 = steps[1].items.filter((item) => item.questionNo === "17")[0];
    const q18 = steps[1].items.filter((item) => item.questionNo === "18")[0];
    const q19 = steps[1].items.filter((item) => item.questionNo === "19")[0];
    const q20 = steps[0].items.filter((item) => item.questionNo === "20")[0];
    const q21 = steps[0].items.filter((item) => item.questionNo === "21")[0];

    useEffect(() => {
        trackPageView();
        if(!state.locationId){
            let pathnames = [`agents/${state.branchId}`, `agent_detail/${state.branchId}`, pathname.substring(1)];
            setBreadcrumbsPath(pathnames);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    const onBack = () => {
        const backButtonEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': 'click, Back to answer step 2 of Appointment Reservation'
            }
        }
        trackEvent({ category: 'onBack', action: 'step3-click-event' });
        TagManager.dataLayer(backButtonEvent);
        history.goBack();
    }

    const getAppointmentMethod = () => {
        if (state.locationId) {
            if(q3.questionActive === 1){
                if(state.locationId !== 'mamatas'){
                    return appointmentMethod === 1 ? 'オンライン相談を希望しない' : 'オンライン相談を希望する';
                }else{
                    return appointmentMethod === 1 ? '店舗に来店して相談' : 'オンライン相談';
                }
            }else{
                return "";
            }
            
        } else {
            return getAppointmentMethodContent(appointment_method.itemId, appointment_method.content)
        }
    }

    const onSubmit = async () => {
        const nextButtonEvent = {
            dataLayer: {
                'event': 'click-event',
                'data-log-click': 'click, Submit all Appointment Reservation information'
            }
        }
        TagManager.dataLayer(nextButtonEvent);
        setLoading2SubmitBtn(true);
        const body = {
            "agentId": state.locationId ? "" : state.agentData.agentId,
            "agentName": state.locationId ? "" : state.agentData.agentName,
            "birthYear": birthYear === 0 ? birthYear : birthYear.name,
            "branchId": !state.locationId ? state.agentData.branchId : state.branchId,
            "selectedBranchId": state.locationId === 'mamatas' ? selectedBranch : '',
            "customerEmail": customerEmail,
            "customerNameKana": customerFirstNameKana + ' ' + customerLastNameKana,
            "customerNameKanji": customerFirstNameKanji + ' ' + customerLastNameKanji,
            "customerPhone": customerPhone,
            "familyStructure": familyStructure === 0 ? familyStructure : familyStructure.name,
            "gender": state.locationId ? gender : personal_gender? personal_gender.name : '',
            "interestedInsurance": getInterestedInsurance(),
            "isEverRequested": !isEverRequested ? '' : isEverRequested === 1 ? false : true,
            "appointmentMethod": getAppointmentMethod(),
            "requestSummary": state.locationId === 'mamatas'? talkingDemandText : requestSummary,
            "selectedDate": !state.locationId ? selectedDate : q5.questionActive === 1 ? selectedDate : "",
            "selectedTime": selectedTimeRS,
            "locationId": state.locationId,
            "employeeNumber": employeeNumber,
            "birthDate": dob,
            "address": prefectures + municipalities  + address,
            "customerEmailCompany": companyEmail,
            "department": department,
            "extensionNumber": extensionNumber,
            "contactMethod": preferredMethod,
            "convenientTime": preferredMethod === 'メール' ? '' : convenientTime,
            "remarks": remark ? remark : "",
            "discussStatus": discussStatusText === "" ? discussStatus : discussStatus + "： " + discussStatusText,
            "postcode": postCode,
            "userId": userId
        } 
        const onSuccess = (data) => {
            setSubmissionFailed(false);
            setLoading2SubmitBtn(false);
            resetAppointmentReservation();
            trackEvent({ category: 'onSubmit', action: 'step3-click-event' });
            if (state.locationId) {
                history.push({ pathname: state.locationId === 'mamatas' ? `/mamatas_thanks/${state.branchId}` : `/group_thanks/${state.branchId}`, state: { branchId: state.branchId, locationId: state.locationId, customerEmail, companyEmail } });
            } else {
                history.push({ pathname: `/thanks/${state.branchId}`, state: { branchId: state.branchId, locationId: state.locationId, customerEmail } });
            }
        }

        const onFail = (error) => {
            console.log(error);
            setSubmissionFailed(true);
            setLoading2SubmitBtn(false);
        }
        await insertAppointment(body, onSuccess, onFail);
    }

    const getPersonal = () => {
        return (
            <>
                <tr>
                    <td>{t('appointment_reservation_page.step_3.associate_name')}</td>
                    <td className='text-break'>{state.agentData.agentName}</td>
                </tr>
            {
                q5.questionActive === 1 &&
                <tr>
                    <td>{t('appointment_reservation_page.step_3.appointment_time')}</td>
                    <td className='text-break'>{`${selectedDate} (${convertedWeekDay}) ${selectedTime}`}</td>
                </tr>
            }
            {
                q3.questionActive === 1 &&
                <tr>
                    <td>{t('appointment_reservation_page.step_3.appointment_method')}</td>
                    <td className='text-break'>{getAppointmentMethod() || ""}</td>
                </tr>
            }
             {
                q7.questionActive === 1 &&
                <tr>
                    <td>{t('appointment_reservation_page.step_3.name_kanji')}</td>
                    <td className='text-break'>{customerFirstNameKanji + ' ' + customerLastNameKanji}</td>
                </tr>
            }
            {
                q8.questionActive === 1 &&
                <tr>
                    <td>{t('appointment_reservation_page.step_3.name_kana')}</td>
                    <td className='text-break'>{customerFirstNameKana + ' ' + customerLastNameKana}</td>
                </tr>
            }
            {
                q10.questionActive === 1 &&
                <tr>
                    <td>{t('appointment_reservation_page.step_3.phone_no')}</td>
                    <td className='text-break'>{customerPhone}</td>
                </tr>
            } 
            {
                q9.questionActive === 1 &&
                <tr>
                    <td>{t('appointment_reservation_page.step_3.email')}</td>
                    <td className='text-break'>{customerEmail}</td>
                </tr>
            }
            {
                q4.questionActive === 1 && 
                <tr>
                    <td>{t('appointment_reservation_page.step_3.request_summary')}</td>
                    <td className='text-break'>{requestSummary ? requestSummary : '-'}</td>
                </tr>
            }
            </>
        );
    }

    const getGroup = () => {
        return (
            <>
                {
                    q4.questionActive === 1 &&
                    <tr>
                        <td>{t('appointment_reservation_page.step_3.request_summary')}</td>
                        <td className='text-break'>{requestSummary ? requestSummary : '-'}</td>
                    </tr>
                }
                {
                    q20.questionActive === 1 && 
                    <tr>
                        <td>{q20.questionContent}</td>
                        <td className='text-break'>{discussStatusText === "" ? discussStatus : discussStatus + "： " + discussStatusText}</td>
                    </tr>
                }
                {
                    q5.questionActive === 1 && 
                    <tr>
                        <td>{t('appointment_reservation_page.step_3.appointment_time')}</td>
                        <td className='text-break'>{`${selectedDate} (${convertedWeekDay}) ${selectedTime}`}</td>
                    </tr>
                }
                {
                    q3.questionActive === 1 &&
                    <tr>
                        <td>{t('appointment_reservation_page.step_3.appointment_method')}</td>
                        <td className='text-break'>{getAppointmentMethod() || ""}</td>
                    </tr>
                }
                {
                    q7.questionActive === 1 &&
                    <tr>
                        <td>{t('appointment_reservation_page.step_3.name_kanji')}</td>
                        <td className='text-break'>{customerFirstNameKanji + ' ' + customerLastNameKanji}</td>
                    </tr>
                }
                {
                    q8.questionActive === 1 &&
                    <tr>
                        <td>{t('appointment_reservation_page.step_3.name_kana')}</td>
                        <td className='text-break'>{customerFirstNameKana + ' ' + customerLastNameKana}</td>
                    </tr>
                }
                {
                    q12.questionActive === 1 &&
                    <tr>
                        <td>{q12.questionContent}</td>
                        <td className='text-break'>{dob}</td>
                    </tr>
                }
                {
                    q13.questionActive === 1 &&
                    <tr>
                        <td>{q13.questionContent}</td>
                        <td className='text-break'>{gender}</td>
                    </tr>
                }
                {
                    q14.questionActive === 1 &&
                    <tr>
                        <td>{q14.questionContent}</td>
                        <td className='text-break'>{q14.questionActive === 0 ? "" : prefectures + municipalities  + address}</td>
                    </tr>
                }
                {
                    q15.questionActive === 1 &&
                    <tr>
                        <td>{q15.questionContent}</td>
                        <td className='text-break'>{companyEmail}</td>
                    </tr>
                }
                {
                    q9.questionActive === 1 &&
                    <tr>
                        <td>{ state.locationId ? q9.questionContent : t('appointment_reservation_page.step_3.email')}</td>
                        <td className='text-break'>{customerEmail}</td>
                    </tr>
                }
                {
                    q10.questionActive === 1 &&
                    <tr>
                        <td>{t('appointment_reservation_page.step_3.phone_no')}</td>
                        <td className='text-break'>{customerPhone}</td>
                    </tr>
                } 
                {
                    (state.locationId && q11.questionActive === 1) &&
                    <tr>
                        <td>{t('appointment_reservation_page.step_3.employee_number')}</td>
                        <td className='text-break'>{employeeNumber}</td>
                    </tr>
                }
                {
                    q16.questionActive === 1 &&
                    <tr>
                        <td>{q16.questionContent}</td>
                        <td className='text-break'>{department}</td>
                    </tr>
                } 
                {
                    q17.questionActive === 1 &&
                    <tr>
                        <td>{q17.questionContent}</td>
                        <td className='text-break'>{extensionNumber}</td>
                    </tr>
                } 
                {
                    q18.questionActive === 1 &&
                    <>
                        <tr>
                            <td>{q18.questionContent}</td>
                            <td className='text-break'>{preferredMethod}</td>
                        </tr>
                        {
                            preferredMethod !== 'メール'  && 
                            <tr>
                                <td>{q19.questionContent}</td>
                                <td className='text-break'>{convenientTime ? convenientTime : '-'}</td>
                            </tr>
                        }
                            {
                            preferredMethod === 'その他（ご要望などございましたら備考欄にご記入ください）'  && 
                            <tr>
                                <td>{t('general.fields.remark.title')}</td>
                                <td className='text-break'>{remark ? remark : '-'}</td>
                            </tr>
                        }
                        
                    </>
                } 
            </>
        );
    }

    const getMamatas = () =>{
        return(
            <>
                {
                    q21.questionActive === 1 &&
                    <tr>
                        <td>{q21.questionContent}</td>
                        <td className='text-break'>{talkingDemand.length ? talkingDemand.map(item => item.name).join('、') : '-'}</td>
                    </tr>
                }
                {
                    q21.questionActive === 1 &&
                    <tr>
                        <td>{`${q21.questionContent}（その他）`}</td>
                        <td className='text-break'>{talkingDemandText ? talkingDemandText : '-'}</td>
                    </tr>
                }
                {
                    q3.questionActive === 1 && 
                    <tr>
                        <td>{t('appointment_reservation_page.step_3.appointment_method')}</td>
                        <td className='text-break'>{getAppointmentMethod() + (selectedStore ? `（店舗：${selectedStore}）` : '') || ""}</td>
                    </tr>
                }
                {
                    q5.questionActive === 1 &&
                    <tr>
                        <td>{t('appointment_reservation_page.step_3.appointment_time')}</td>
                        <td className='text-break'>{`${selectedDate} (${convertedWeekDay}) ${selectedTime}`}</td>
                    </tr>
                }
                {
                    q7.questionActive === 1 &&
                    <tr>
                        <td>{t('appointment_reservation_page.step_3.name_kanji')}</td>
                        <td className='text-break'>{customerFirstNameKanji + ' ' + customerLastNameKanji}</td>
                    </tr>
                }
                {
                    q8.questionActive === 1 &&
                    <tr>
                        <td>{t('appointment_reservation_page.step_3.name_kana')}</td>
                        <td className='text-break'>{customerFirstNameKana + ' ' + customerLastNameKana}</td>
                    </tr>
                }
                {
                    q12.questionActive === 1 &&
                    <tr>
                        <td>{q12.questionContent}</td>
                        <td className='text-break'>{dob}</td>
                    </tr>
                }
                {
                    q13.questionActive === 1 &&
                    <tr>
                        <td>{q13.questionContent}</td>
                        <td className='text-break'>{gender}</td>
                    </tr>
                }
                {
                    q14.questionActive === 1 &&
                    <tr>
                        <td>{q14.questionContent}</td>
                        <td className='text-break'>{q14.questionActive === 0 ? "" : prefectures + municipalities  + address}</td>
                    </tr>
                }
                {
                    q9.questionActive === 1 &&
                    <tr>
                        <td>{ state.locationId ? q9.questionContent : t('appointment_reservation_page.step_3.email')}</td>
                        <td className='text-break'>{customerEmail ? customerEmail : '-'}</td>
                    </tr>
                }
                {
                    q10.questionActive === 1 &&
                    <tr>
                        <td>{t('appointment_reservation_page.step_3.phone_no')}</td>
                        <td className='text-break'>{customerPhone}</td>
                    </tr>
                } 
            </>
        )
    }

    const getInterestedInsurance = () => {
        if(state.locationId){
            return state.locationId === 'mamatas'? talkingDemand.map(item => item.name).join('、') : '';
        }
        return interestedInsurance.map(x => x.name).join('、')
    }

    return (
        (state === undefined || state.branchId === null || state.branchId === '') ?
            <PageNotFound />
            :
            <ARContainer>
                <div className={classes.schedule_meetup_step3}>
                    <AppointmentStepper step_no={3} skip_1={state.skip_1} />
                    <table className={`${classes.schedule_meet_table} w-100 mb-4`}>
                        <tbody>
                           {
                                !state.locationId &&
                                getPersonal()
                           }
                           {
                                state.locationId && state.locationId === 'reservation' &&
                                getGroup()
                           }
                           {
                                state.locationId && state.locationId === 'mamatas' &&
                                getMamatas()
                           }
                        </tbody>
                    </table>
                    <div className={classes.schedule_meet_notice_box}>
                        <p className={classes.schedule_meet_notice_ttl}>{t('appointment_reservation_page.step_3.box1.title')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.sub_title')}</p>

                        <p className={classes.schedule_meet_notice_title}>{t('appointment_reservation_page.step_3.box1.part1.title')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part1.sub_title1')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part1.sub_title1_content1')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part1.sub_title2')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part1.sub_title2_content1')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part1.sub_title3')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part1.sub_title3_content1')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part1.sub_title4')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part1.sub_title4_content1')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part1.sub_title5')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part1.sub_title5_content1')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part1.sub_title6')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part1.sub_title6_content1')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part1.sub_title7')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part1.sub_title7_content1')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part1.sub_title8')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part1.sub_title8_content1')}</p>

                        <p className={classes.schedule_meet_notice_title}>{t('appointment_reservation_page.step_3.box1.part2.title')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part2.sub_title1')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part2.sub_title1_content1')}</p><p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part2.sub_title2')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part2.sub_title2_content1')}</p>
                        <p className={classes.schedule_meet_notice_title}>{t('appointment_reservation_page.step_3.box1.part3.title')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part3.sub_title1')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part3.sub_title1_content1')}</p><p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part3.sub_title2')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part3.sub_title2_content1')}</p>
                        <p className={classes.schedule_meet_notice_title}>{t('appointment_reservation_page.step_3.box1.part4.title')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part4.sub_title1')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part4.sub_title1_content1')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part4.sub_title2')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part4.sub_title2_content1')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part4.sub_title3')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part4.sub_title3_content1')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part4.sub_title4')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part4.sub_title4_content1')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part4.sub_title5')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part4.sub_title5_content1')}</p>
                        <p className={classes.schedule_meet_notice_sub_title}>{t('appointment_reservation_page.step_3.box1.part4.sub_title6')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.part4.sub_title6_content1')}</p>

                        <br />
                        <br />
                        <p>{t('appointment_reservation_page.step_3.box1.statement')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.time_1')}</p>
                        <p>{t('appointment_reservation_page.step_3.box1.time_2')}</p>
                    </div>
                    <Row>
                        <Col className={classes.agreement_col}>
                            <StyledCheckbox
                                value="readme"
                                text={t('appointment_reservation_page.step_3.checkbox_label')}
                                checked={checked}
                                className={classes.agreement_cb}
                                onChecked={() => {
                                    setChecked(true)
                                    trackEvent({ category: 'onRegulationsAgreeSelect', action: 'step3-click-event' });
                                }}
                                onUnchecked={() => {
                                    setChecked(false)
                                    trackEvent({ category: 'onRegulationsAgreeSelect', action: 'step3-click-event' });
                                }}
                            />
                        </Col>
                    </Row>
                    {submissionFailed && <ErrorMessage message={t('appointment_reservation_page.step_3.error_message')} />}
                    <div className={classes.buttons_container}>
                        <SubmitButton disabled={!checked} onClick={onSubmit} loading={submitBtnLoading} className="order-2" />
                        <BackButton onClick={onBack} className="order-1" />
                    </div>
                </div>
            </ARContainer>
    )
}

const stateToProps = state => {
    return {
        birthYear: state.selectedBirthYear,
        customerEmail: state.step2_email,
        customerFirstNameKana: state.step2_first_name_kana,
        customerLastNameKana: state.step2_last_name_kana,
        customerFirstNameKanji: state.step2_first_name_kanji,
        customerLastNameKanji: state.step2_last_name_kanji,
        customerPhone: state.step2_phone,
        employeeNumber: state.step2_employee_number,
        familyStructure: state.selectedFamilyStr,
        gender: state.step2_gender,
        personal_gender: state.selectedGenderId,
        dob: state.step2_dob,
        address: state.step2_address,
        prefectures: state.step2_prefectures,
        municipalities: state.step2_municipalities,
        remark: state.step2_remark,
        companyEmail: state.step2_company_email,
        department: state.step2_department,
        extensionNumber: state.step2_extension_number,
        preferredMethod: state.step2_preferred_appointment_method,
        convenientTime: state.step2_convenient_time,
        directQuestions: state.directQuestions,
        answeredDirQns: state.answeredDirQns,
        interestedInsurance: state.step1InsuranceTypes,
        isEverRequested: state.step1IsHasExperience,
        appointmentMethod: state.step2AppointmentMethod,
        requestSummary: state.step1RequestMessage,
        selectedDate: state.step2_date,
        selectedTime: state.step2_time,
        steps: state.groupReservationConfig.steps,
        discussStatus: state.step1DiscussStatus,
        discussStatusText: state.step1DiscussStatusText,
        talkingDemand: state.step1TalkingDemand,
        talkingDemandText: state.step1TalkinngDemandText,
        selectedStore: state.step2SelectedStore,
        selectedBranch: state.selectedBranch,
        postCode: state.step2_postCode,
        userId: state.userId,
        breadcrumbsPath: state.breadcrumbsPath,
    }
}

const dispatchToProps = dispatch => {
    return {
        resetAppointmentReservation: () => {
            dispatch(resetAppointmentReservation());
        },
        setBreadcrumbsPath: (breadcrumbsPath) => {
            dispatch(setBreadcrumbsPath(breadcrumbsPath))
        }
    }
}

export default connect(stateToProps, dispatchToProps)(ARStep3);