import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { Row, Col } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import HeaderBar from "../../components/HeaderBar";
import FooterBar from "../../components/FooterBar";
import AgentCard from "../../components/CardViews/AgentCard/AgentCard.js";
import DateFilterCard from "../../components/CardViews/DateFilterCard";
import icoFilter from "../../assets/images/icons/filter.png";
import GeneralButton from "../../components/Buttons/GeneralButton";
import Breadcrumbs from "../../components/Breadcrumbs";
import { getAgentList } from "../../api/Agent/listing";
import PageNotFound from "../PageNotFound";
import eventBus from "../../EventBus";
import {
  setAgentListingScrollPosition,
  setAgentListingCurrentPage,
  setAgentListingSelectedDateFilter,
  setAgentListingStartTimeFilter,
  setAgentListingEndTimeFilter,
  setAgentNameFilter,
  resetMatchingQuestions,
  setBreadcrumbsPath,
} from "../../store/actions";

import styles from "./styles.module.css";

const pageview = {
  dataLayer: {
    event: "pageview",
    log_pageurl: "",
    log_pagename: "",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const AgentListing = ({
  userId,
  answeredDirQns,
  answeredSymQns,
  selectedInsurancesId,
  selectedBirthYear,
  selectedFamilyStr,
  agentListScrollPosition,
  agentListCurrentPage,
  agentListSelectedDateFilter,
  agentListStartTimeFilter,
  agentListEndTimeFilter,
  agentNameFilter,
  setAgentListingScrollPosition,
  setAgentListingCurrentPage,
  setAgentListingSelectedDateFilter,
  setAgentListingStartTimeFilter,
  setAgentListingEndTimeFilter,
  setAgentNameFilter,
  resetMatchingQuestions,
  breadcrumbsPath,
  setBreadcrumbsPath,
}) => {
  document.title = "マッチング結果ページ";

  const { trackPageView, trackEvent } = useMatomo();
  const { state, pathname } = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const [agents, setAgents] = useState();
  const [currentPage, setCurrentPage] = useState(agentListCurrentPage);
  const [total, setTotal] = useState([]);
  const [display, setDisplay] = useState(
    window.innerWidth <= 767.98 ? "none" : "flex"
  );
  const [opacity, setOpacity] = useState(window.innerWidth <= 767.98 ? 0 : 1);
  const [selectedDate, setSelectedDate] = useState(agentListSelectedDateFilter);
  const [startTime, setStartTime] = useState(agentListStartTimeFilter);
  const [endTime, setEndTime] = useState(agentListEndTimeFilter);
  const [updateFilter, setUpdateFilter] = useState(false);
  const [agentName, setAgentName] = React.useState(agentNameFilter);
  const history = useHistory();
  const setData = async (pageNumber, paginate, resetAgentName) => {
    try {
      let result = await getAgentList({
        appointmentMethodId: answeredDirQns.find((el) => el.questionId === 4)
          .itemId,
        birthYearId:
          selectedBirthYear === 0 ? selectedBirthYear : selectedBirthYear.id,
        branchId: state.branchId,
        directQuestions: answeredDirQns,
        familyStructureId:
          selectedFamilyStr === 0 ? selectedFamilyStr : selectedFamilyStr.id,
        insuranceId: selectedInsurancesId,
        pageNumber: pageNumber,
        pageSize: 6,
        sympathyQuestions: answeredSymQns,
        userId: userId,
        searchStr: resetAgentName ? "" : agentName,
        filterDate: selectedDate ? selectedDate : "",
        filterStartTime: startTime ? startTime.toString().padStart(4, "0") : "",
        filterEndTime: endTime ? endTime.toString().padStart(4, "0") : "",
      });
      setAgents(result.agentList);
      setTotal(result.totalCount);
      if (paginate) {
        window.scrollTo(0, 0);
      } else {
        window.scrollBy(0, agentListScrollPosition);
      }
    } catch (error) {
      eventBus.dispatch("something_went_wrong");
      setAgents([]);
      setTotal(0);
    }
  };

  const handlePagination = (event, value) => {
    setData(value - 1, true, false);
    setCurrentPage(value - 1);
    handleHistoryChange(value - 1, true);
  };

  const handleHistory = () => {
    handleHistoryChange(currentPage, false);
  };

  const handleHistoryChange = (page, paginate) => {
    setAgentListingScrollPosition(paginate ? 0 : window.pageYOffset);
    setAgentListingCurrentPage(page);
    setAgentListingSelectedDateFilter(selectedDate);
    setAgentListingStartTimeFilter(startTime);
    setAgentListingEndTimeFilter(endTime);
    setAgentNameFilter(agentName);
  };

  const resetFilter = () => {
    trackEvent({ category: 'onAgentListSearchingReset', action: 'agents-click-event' })
    if (agentName) {
      setAgentName("");
      setAgentNameFilter("");
      setUpdateFilter(!updateFilter);
      setData(0, true, true);
    } else {
      setAgentListingSelectedDateFilter("");
      setAgentListingStartTimeFilter("");
      setAgentListingEndTimeFilter("");
      setStartTime("");
      setEndTime("");
      setSelectedDate("");
    }
  };

  const handleAgentNameFocus = () => {
    trackEvent({ category: 'onAgentNameInput', action: 'agents-input-event' })
    if (!agentName) {
      setAgentListingSelectedDateFilter("");
      setAgentListingStartTimeFilter("");
      setAgentListingEndTimeFilter("");
      setStartTime("");
      setEndTime("");
      setSelectedDate("");
      setUpdateFilter(!updateFilter);
    }
  };

  const handleSearch = () => {
    setData(0, true, false);
    trackEvent({ category: 'onAgentListSearching', action: 'agents-click-event' })
  };

  const handleNameFilterChange = (value) => {
    setAgentNameFilter(value);
    setAgentName(value);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    TagManager.dataLayer(pageview);
    window.onbeforeunload = (e) => {
      setAgentListingScrollPosition(0);
    };
    trackPageView();
    let pathnames = new Array(pathname.substring(1));
    setBreadcrumbsPath(pathnames);
  }, []);

  useEffect(() => {
    setCurrentPage(0);
    setAgentListingScrollPosition(0);
    setAgentListingCurrentPage(0);
    setAgentListingSelectedDateFilter(selectedDate);
    if (!selectedDate) {
      setAgentListingStartTimeFilter("");
      setAgentListingEndTimeFilter("");
      setStartTime("");
      setEndTime("");
      setData(0, true, false);
    } else {
      setAgentListingStartTimeFilter(startTime);
      setAgentListingEndTimeFilter(endTime);
      setAgentName("");
      setAgentNameFilter("");
      setData(0, true, true);
    }
    setUpdateFilter(!updateFilter);
  }, [selectedDate, startTime, endTime]);

  const pagination = (count) => {
    if (count >= 6) {
      const pages = Math.ceil(count / 6);
      const default_page = pages >= currentPage ? currentPage : 0;
      return (
        <Pagination
          count={Math.ceil(count / 6)}
          onChange={handlePagination}
          page={default_page + 1}
          size="small"
          showFirstButton
          showLastButton
          className="m-auto"
        />
      );
    }
  };

  const toggleClass = () => {
    if (window.innerWidth <= 767.98) {
      if (display === "none") {
        setDisplay("flex");
        setTimeout(() => {
          setOpacity(1);
        }, 20);
      }
      if (display === "flex") {
        setOpacity(0);
        setTimeout(() => {
          setDisplay("none");
        }, 10);
      }
    }
  };

  useEffect(() => {
    const updateWindowDimensions = () => {
      setDisplay(window.innerWidth <= 767.98 ? "none" : "flex");
      setOpacity(window.innerWidth <= 767.98 ? 0 : 1);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const handleReSearch = () => {
    resetMatchingQuestions();
    history.goBack();
  }

  return state === undefined ||
    state.branchId === null ||
    state.branchId === "" ? (
    <PageNotFound />
  ) : (
    <div>
      <HeaderBar />
      <div className={`${styles.content_area}  cmn-inner-width cmn-height`}>
        <Row className={styles.headerContainer}>
          <Col lg="3" md="12" sm="12" xs="12">
            <Breadcrumbs breadcrumbsPath={breadcrumbsPath} />
            <h3 className={styles.header}>{t("agent_listing_page.title")}</h3>
            {/* <h4>{t("agent_listing_page.sub_title")}</h4> */}
          </Col>
          <Col lg="9" md="12" sm="12" xs="12">
            <div className={styles.filter_bar}>
              <div  className={styles.filter_buttons}>
              <GeneralButton
                id={"filterButton"}
                frontIcon={icoFilter}
                className={styles.filter_button}
                onClick={toggleClass}
              />
              {/* <GeneralButton
                  className={styles.research_button}
                  onClick={handleReSearch}
                  text={t("general.buttons.research")}
                /> */}
              </div>
              <div
                className={`${styles.filter_container}`}
                style={{
                  transition: "opacity 0.3s ease",
                  opacity: opacity,
                  display: display,
                }}
              >
                <DateFilterCard
                  defaultDate={selectedDate}
                  defaultStartTime={startTime}
                  defaultEndTime={endTime}
                  defaultAgentName={agentName}
                  onDateChange={setSelectedDate}
                  onStartTimeChange={setStartTime}
                  onEndTimeChange={setEndTime}
                  onAgentNameChange={handleNameFilterChange}
                  update={updateFilter}
                  onReset={resetFilter}
                  onFocus={handleAgentNameFocus}
                  onSearch={handleSearch}
                  onClear={resetFilter}
                  onReSearch={handleReSearch}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {Array.isArray(agents) && agents.length !== 0
            ? agents.map((agent, index) => (
              <Col
                lg="6"
                md="12"
                sm="12"
                className={styles.card_container}
                key={index}
              >
                <AgentCard data={agent} setHistory={handleHistory} />
              </Col>
            ))
            : Array.isArray(agents) && (
              <Col>
                <h3 className={styles["no-agent"]}>
                  {t("agent_listing_page.no_agent")}
                </h3>
              </Col>
            )}
        </Row>
        <Row className={`${classes.root} ${styles.pagination_box}`}>
          {pagination(total)}
        </Row>
      </div>
      <FooterBar />
    </div>
  );
};

const stateToProps = (state) => {
  return {
    userId: state.userId,
    answeredDirQns: state.answeredDirQns,
    answeredSymQns: state.answeredSymQns,
    selectedInsurancesId: state.selectedInsurancesId,
    selectedBirthYear: state.selectedBirthYear,
    selectedFamilyStr: state.selectedFamilyStr,
    agentListScrollPosition: state.agentListScrollPosition,
    agentListCurrentPage: state.agentListCurrentPage,
    agentListSelectedDateFilter: state.agentListSelectedDateFilter,
    agentListStartTimeFilter: state.agentListStartTimeFilter,
    agentListEndTimeFilter: state.agentListEndTimeFilter,
    agentNameFilter: state.agentNameFilter,
    breadcrumbsPath: state.breadcrumbsPath,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    setAgentListingScrollPosition: (agentListScrollPosition) => {
      dispatch(setAgentListingScrollPosition(agentListScrollPosition));
    },
    setAgentListingCurrentPage: (agentListCurrentPage) => {
      dispatch(setAgentListingCurrentPage(agentListCurrentPage));
    },
    setAgentListingSelectedDateFilter: (agentListSelectedDateFilter) => {
      dispatch(setAgentListingSelectedDateFilter(agentListSelectedDateFilter));
    },
    setAgentListingStartTimeFilter: (agentListStartTimeFilter) => {
      dispatch(setAgentListingStartTimeFilter(agentListStartTimeFilter));
    },
    setAgentListingEndTimeFilter: (agentListEndTimeFilter) => {
      dispatch(setAgentListingEndTimeFilter(agentListEndTimeFilter));
    },
    setAgentNameFilter: (agentNameFilter) => {
      dispatch(setAgentNameFilter(agentNameFilter));
    },
    resetMatchingQuestions: () => {
      dispatch(resetMatchingQuestions());
    },
    setBreadcrumbsPath: (breadcrumbsPath) => {
      dispatch(setBreadcrumbsPath(breadcrumbsPath));
    }
  };
};

export default connect(stateToProps, dispatchToProps)(AgentListing);
