import { base_api_url } from '../../configs/environments';
import api_urls from '../../routes/api_urls';
import { defaultHeaderOptions } from '../headerOptions';

import QuestionConfig from '../mock/getQuestionsConfig.json';
import MamatasQuestionConfig from '../mock/getMamatasQuestionsConfig.json';


import eventBus from '../../EventBus';

const getQuestionConfigs = (onSuccess, onFail, locationId, branchId) => {
    if (locationId === null) {
        onSuccess(QuestionConfig);
        return;
    }
    if (locationId === 'mamatas' && branchId === '2000002001'){
        onSuccess(MamatasQuestionConfig);
        return;
    }
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions,
        body: JSON.stringify({
            "branchId": branchId
        })
    };
    fetch(`${base_api_url}${api_urls.get_question_configs}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            onSuccess && onSuccess(data);
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('Get Questions config api error - ', error);
            onFail && onFail(error);
        });
}

export {
    getQuestionConfigs
}