import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../utilities/customHooks";

const EntryPage = () => {
  const history = useHistory();
  const query = useQuery();
  const branchId = query.get("s_id");
  const locationId = query.get("l_id");
  const userId = query.get("c_id");
  const isMountedRef = useRef();

  useEffect(() => {
    isMountedRef.current = true;

    if(locationId && locationId === 'reservation'){
      history.replace({ pathname: `/welcome_group/${branchId}`, state: { branchId: branchId, locationId: locationId, userId: userId } })
    }else if( locationId && locationId === 'mamatas' && branchId === '2000002001'){
      history.replace({ pathname: `/welcome_mamatas/${branchId}`, state: { branchId: branchId, locationId: locationId, userId: userId } })
    }else{
      history.replace({ pathname: `/welcome_regular/${branchId}`, state: { branchId: branchId, locationId: locationId, userId: userId } })
    }

    return () => { isMountedRef.current = false }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (<></>);
};

export default EntryPage;