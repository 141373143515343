import React from 'react';


import './styles.css';

const GeneralCardView = ({ children, className, style, onClick}) => {
    return (
        <div className={`general-card ${className}`} style={style} onClick={onClick}>
            {children}
        </div>
    )
};

export default GeneralCardView;