import React from 'react';

import classes from './styles.module.css';
import logo from '../../../assets/images/logo.png';

const MainLogo = ({ className, style, onClick}) => {
    return (
        <div className={`${classes.main_logo} ${className}`} style={style} onClick={onClick}>
            <img src={logo} className={classes.logo_image} alt="logo" />
        </div>
    )
};

export default MainLogo;