import React from "react";

import RadioButton from "../../RadioButtons";

import { formatPostcode, formatPhoneNo } from '../../../utilities/helper';

import classes from "./styles.module.css";

const BranchMI = ({ branch, selectedBranch, onClick, className }) => {
  return (
    <div
      className={`${classes["branch-mi"]} ${
        branch.branchId === selectedBranch?.branchId ? classes["selected"] : ""
      } ${className}`}
      onClick={onClick}
    >
      <div className={classes["radio-button-container"]}>
        <RadioButton
          value={branch.branchId}
          selectedValue={selectedBranch?.branchId}
          className={classes["radio-button"]}
        />
      </div>
      <div className={classes["info-container"]}>
        <span className={classes["branch-name"]}>
          {branch.branchName}
          {branch.subBranchName}
        </span>
        {branch.postcode && (
          <span className={classes["branch-postcode"]}>
            〒{formatPostcode(branch.postcode)}
          </span>
        )}
        {branch.address && (
          <span className={classes["branch-address"]}>{branch.address}</span>
        )}
        {branch.contact && (
          <span className={classes["branch-contact"]}>
            TEL：{formatPhoneNo(branch.contact)}
          </span>
        )}
      </div>
    </div>
  );
};

export default BranchMI;
