import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useMatomo } from "@datapunt/matomo-tracker-react";

import GeneralDropdown from '../Dropdowns/GeneralDropdown';

import classes from './styles.module.css';

const DobSpreadSelector = ({ className, setSelectedDob, selectedDob }) => {
    const { trackEvent } = useMatomo();
    const defaultMonths = [
      { id: '01' , name : '01'},
      { id: '02' , name : '02'},
      { id: '03' , name : '03'},
      { id: '04' , name : '04'},
      { id: '05' , name : '05'},
      { id: '06' , name : '06'},
      { id: '07' , name : '07'},
      { id: '08' , name : '08'},
      { id: '09' , name : '09'},
      { id: '10' , name : '10'},
      { id: '11' , name : '11'},
      { id: '12' , name : '12'}
    ]
    const earliestYear = moment().add(-120, 'years'); /* eslint-disable react-hooks/exhaustive-deps */
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedDay, setSelectedDay] = useState('');
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState(defaultMonths);
    const [days, setDays] = useState([]);


    useEffect(()=>{
      if(selectedDob){
        let arr = selectedDob.split('/');
        setSelectedDay(arr[2]);
        setSelectedMonth(arr[1]);
        setSelectedYear(arr[0] < earliestYear.year().toString() ? earliestYear.year().toString() : arr[0]);
      }
    },[selectedDob]) /* eslint-disable react-hooks/exhaustive-deps */

    useEffect(()=>{
        let temp = [];
        for (let i = 0; i <= 120; i++) {
          let element = moment(earliestYear).add(i, 'years').year().toString();
          temp.push({
            id: element,
            name: element
          })
        }
        setYears(temp.reverse());
    },[]) /* eslint-disable react-hooks/exhaustive-deps */

    useEffect(()=>{
      let temp = [];
      let dayRange = moment(selectedYear + '-' + selectedMonth).daysInMonth();
      if(selectedMonth && selectedYear){
        if(moment().format('YYYY') === selectedYear.toString() && parseInt(selectedMonth) === parseInt(moment().format('MM'))){
          let currentDay = parseInt(moment().format('DD'));
          let remainingDays = dayRange - currentDay;
          for (let i = 0; i < dayRange - remainingDays; i++) {
            let element = padZero(i + 1);
            temp.push({
              id: String(element),
              name: String(element)
            })
          }
          setDays(temp);
          if(parseInt(selectedDay) > currentDay){
            setSelectedDay(padZero(currentDay));
            setSelectedDob(selectedYear + "/" + selectedMonth + "/" + padZero(currentDay));
          }else{
            setSelectedDob(selectedYear + "/" + selectedMonth + "/" + selectedDay);
          }
        }else{
          for (let i = 0; i < dayRange; i++) {
            let element = padZero(i + 1);
            temp.push({
              id: String(element),
              name: String(element)
            });
          }
          setDays(temp);
          if(selectedDay > dayRange){
            setSelectedDay(dayRange);
            setSelectedDob(selectedYear + "/" + selectedMonth + "/" + dayRange);
          }else{
            setSelectedDob(selectedYear + "/" + selectedMonth + "/" + selectedDay);
          }
        }
      }
    },[selectedYear, selectedMonth])/* eslint-disable react-hooks/exhaustive-deps */

    // useEffect(()=>{
    //   setSelectedDob(selectedYear + "/" + selectedMonth + "/" + selectedDay);
    // },[selectedYear, selectedMonth, selectedDay])/* eslint-disable react-hooks/exhaustive-deps */


    const padZero = (value)=> {
        return value.toString().length === 1 ? '0' + value : value;
    }

    const handleYearSelect = (value) => {
      let tempMonth = [];
      let currentMonth = parseInt(moment().format('MM'));
      if(moment().format('YYYY') === value.toString()){
        let remainingMonth = 12 - currentMonth;
        for (let index = 0; index < 12 - remainingMonth; index++) {
          let element = padZero(index + 1);
          tempMonth.push({
            id: element,
            name: element
          })
        }
        setMonths(tempMonth);
        if(parseInt(selectedMonth) > currentMonth){
          setSelectedMonth(padZero(currentMonth));
          setSelectedDob(value + "/" + padZero(currentMonth) + "/" + selectedDay);
        }else{
          setSelectedDob(value + "/" + selectedMonth + "/" + selectedDay);
        }
      }else{
        setMonths(defaultMonths);
      }
      setSelectedYear(value);
      trackEvent({ category: 'onBirthYearSelect', action: 'step2-click-event' });
    }

    const handleMonthSelect = (value) => {
      setSelectedMonth(padZero(value))
      trackEvent({ category: 'onBirthMonthSelect', action: 'step2-click-event' });
    }

    const handleDaySelect = (value) => {
      setSelectedDay(padZero(value))
      setSelectedDob(selectedYear + "/" + selectedMonth + "/" + padZero(value));
      trackEvent({ category: 'onBirthDateSelect', action: 'step2-click-event' });
    }

    return (
        <div className={`${classes.container} ${className}`}>
          <div className={`${classes.date_selection} `}>
            <GeneralDropdown className={classes.dropdown_height} items={years} selectedItem={selectedYear} onSelect={handleYearSelect} />
            {'年'}
          </div>
          <div className={`${classes.date_selection} `}>
          <GeneralDropdown className={classes.dropdown_height} items={months} selectedItem={selectedMonth} onSelect={handleMonthSelect}/>
            {'月'}
          </div>
          <div className={`${classes.date_selection} `}>
          <GeneralDropdown className={classes.dropdown_height} items={days} selectedItem={selectedDay} onSelect={handleDaySelect}/>
            {'日'}
          </div>
        </div>
    )
}

export default DobSpreadSelector;