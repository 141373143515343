import {
    RESET_ALL_2_INITIAL_STATE,
    SET_S_ID,
    RESET_WELCOME_SEARCHING_INFO,
    RESET_AGENT_LIST_HISTORIES,
    RESET_APPOINTMENT_RESERVATION,
    USER_ID,
    INSURANCE_TYPES,
    SELECTED_INSURANCES_ID,
    SELECTED_BIRTH_YEAR,
    SELECTED_FAMILY_STR,
    SELECTED_GENDER_ID,
    DIRECT_QUESTIONS,
    ANSWERED_DIRECT_QNA,
    SYMPATHY_QUESTIONS,
    ANSWERED_SYMPATHY_QNS,
    AGENT_LIST_SCROLL_POSITION,
    AGENT_LIST_CURRENT_PAGE,
    AGENT_LIST_SELECTED_FILTER,
    AGENT_LIST_SELECTED_DATE_FILTER,
    AGENT_LIST_START_TIME_FILTER,
    AGENT_LIST_END_TIME_FILTER,
    STEP1_IS_HAS_EXPERIENCE,
    STEP2_APPOINTMENT_METHOD,
    STEP2_SELECTED_STORE,
    STEP1_INSURANCE_TYPES,
    STEP1_REQUEST,
    STEP2_DATE,
    STEP2_TIME,
    STEP2_FIRST_NAME_KANJI,
    STEP2_LAST_NAME_KANJI,
    STEP2_FIRST_NAME_KANA,
    STEP2_LAST_NAME_KANA,
    STEP2_EMAIL,
    STEP2_PHONE,
    AGENT_NAME_FILTER,
    SELECTED_BRANCH,
    GROUP_RESERVATION_CONFIG,
    STEP2_EMPLOYEE_NUMBER,
    RESET_MATCHING_QUESTIONS,
    STEP2_DOB,
    STEP2_GENDER,
    STEP2_POST_CODE,
    STEP2_PREFECTURES,
    STEP2_MUNICIPALITIES,
    STEP2_ADDRESS,
    STEP2_COMPANY_EMAIL,
    STEP2_DEPARTMENT,
    STEP2_EXTENSION_NUMBER,
    STEP2_PREFERRED_APPOINTMENT,
    STEP2_CONVENIENT_TIME,
    STEP2_REMARK,
    STEP1_DISCUSS_STATUS,
    STEP1_DISCUSS_STATUS_TEXT,
    STEP1_TALKING_DEMAND,
    STEP1_TALKIING_DEMANND_TEXT,
    RESET_SETP2_EXCEPT_DATE,
    SET_BREADCRUMBS_PATH
} from "../constants";

const initialState = {
    s_id: "",
    userId: "",
    insuranceTypes: [],
    selectedInsurancesId: [],
    selectedBirthYear: 0,
    selectedFamilyStr: 0,
    selectedGenderId: 0,
    directQuestions: [],
    answeredDirQns: [],
    sympathyQuestions: [],
    answeredSymQns: [],
    selectedBranch: undefined,
    step1IsHasExperience: 1,
    step2AppointmentMethod: 2,
    step2SelectedStore: "",
    step1InsuranceTypes: [],
    step1DiscussStatus: '',
    step1DiscussStatusText: '',
    step1RequestMessage: "",
    step1TalkingDemand: [],
    step1TalkinngDemandText: "",
    step2_date: "",
    step2_time: "",
    step2_first_name_kanji: "",
    step2_last_name_kanji: "",
    step2_first_name_kana: "",
    step2_last_name_kana: "",
    step2_email: "",
    step2_phone: "",
    step2_employee_number: "",
    step2_dob: "",
    step2_gender: "",
    step2_postCode: "",
    step2_prefectures: "",
    step2_municipalities: "",
    step2_address: "",
    step2_company_email: "",
    step2_department: "",
    step2_extension_number: "",
    step2_preferred_appointment_method: "",
    step2_convenient_time: "",
    step2_remark: "",
    agentListScrollPosition: 0,
    agentListCurrentPage: 0,
    agentListSelectedFilter: "401",
    agentListSelectedDateFilter: "",
    agentListStartTimeFilter: "",
    agentListEndTimeFilter: "",
    agentNameFilter: "",
    groupReservationConfig: {
        blockDates: [],
        steps: [],
        timeSlots: []
    },
    breadcrumbsPath: []
};

export function customerFrontEnd(state = initialState, action) {
    switch (action.type) {
        case RESET_ALL_2_INITIAL_STATE:
            return {...initialState };
        case SET_S_ID:
            return {...state, s_id: action.s_id };
        case RESET_WELCOME_SEARCHING_INFO:
            return {
                ...state,
                selectedInsurancesId: initialState.selectedInsurancesId,
                selectedBirthYear: initialState.selectedBirthYear,
                selectedFamilyStr: initialState.selectedFamilyStr,
                selectedGenderId: initialState.selectedGenderId,
            };
        case RESET_AGENT_LIST_HISTORIES:
            return {
                ...state,
                agentListScrollPosition: initialState.agentListScrollPosition,
                agentListCurrentPage: initialState.agentListCurrentPage,
                agentListSelectedFilter: initialState.agentListSelectedFilter,
                agentListSelectedDateFilter: initialState.agentListSelectedDateFilter,
                agentListStartTimeFilter: initialState.agentListStartTimeFilter,
                agentListEndTimeFilter: initialState.agentListEndTimeFilter,
                agentNameFilter: initialState.agentNameFilter,
            };
        case RESET_APPOINTMENT_RESERVATION:
            return {
                ...state,
                step1IsHasExperience: initialState.step1IsHasExperience,
                step2AppointmentMethod: initialState.step2AppointmentMethod,
                step2SelectedStore: initialState.step2SelectedStore,
                step1InsuranceTypes: state.insuranceTypes.filter((x) =>
                    state.selectedInsurancesId.some((y) => y === x.id)
                ),
                step1DiscussStatus: initialState.step1DiscussStatus,
                step1DiscussStatusText: initialState.step1DiscussStatusText,
                step1RequestMessage: initialState.step1RequestMessage,
                step1TalkingDemand: initialState.step1TalkingDemand,
                step1TalkinngDemandText: initialState.step1TalkinngDemandText,
                step2_date: initialState.step2_date,
                step2_time: initialState.step2_time,
                step2_first_name_kanji: initialState.step2_first_name_kanji,
                step2_last_name_kanji: initialState.step2_last_name_kanji,
                step2_first_name_kana: initialState.step2_first_name_kana,
                step2_last_name_kana: initialState.step2_last_name_kana,
                step2_email: initialState.step2_email,
                step2_phone: initialState.step2_phone,
                step2_employee_number: initialState.step2_employee_number,
                agent_list_history: initialState.agent_list_history,
                step2_dob: initialState.step2_dob,
                step2_gender: initialState.step2_gender,
                step2_postCode: initialState.step2_postCode,
                step2_prefectures: initialState.step2_prefectures,
                step2_municipalities: initialState.step2_municipalities,
                step2_address: initialState.step2_address,
                step2_company_email: initialState.step2_company_email,
                step2_department: initialState.step2_department,
                step2_extension_number: initialState.step2_extension_number,
                step2_preferred_appointment_method: initialState.step2_preferred_appointment_method,
                step2_convenient_time: initialState.step2_convenient_time,
                step2_remark: initialState.step2_remark,
                selectedBranch: initialState.selectedBranch
            };
        case USER_ID:
            return {...state, userId: action.userId };
        case INSURANCE_TYPES:
            return {...state, insuranceTypes: action.insuranceTypes };
        case SELECTED_INSURANCES_ID:
            return {...state, selectedInsurancesId: action.insuranceTypes };
        case SELECTED_BIRTH_YEAR:
            return {...state, selectedBirthYear: action.birthYear };
        case SELECTED_FAMILY_STR:
            return {...state, selectedFamilyStr: action.familyStructure };
        case SELECTED_GENDER_ID:
            return {...state, selectedGenderId: action.genderId };
        case DIRECT_QUESTIONS:
            return {...state, directQuestions: action.directQuestions };
        case ANSWERED_DIRECT_QNA:
            return {...state, answeredDirQns: action.answeredQns };
        case SYMPATHY_QUESTIONS:
            return {...state, sympathyQuestions: action.sympathyQuestions };
        case ANSWERED_SYMPATHY_QNS:
            return {...state, answeredSymQns: action.answeredQns };
        case SELECTED_BRANCH:
            return {...state, selectedBranch: action.selectedBranch };
        case AGENT_LIST_SCROLL_POSITION:
            return {
                ...state,
                agentListScrollPosition: action.agentListScrollPosition,
            };
        case AGENT_LIST_CURRENT_PAGE:
            return {...state, agentListCurrentPage: action.agentListCurrentPage };
        case AGENT_LIST_SELECTED_FILTER:
            return {
                ...state,
                agentListSelectedFilter: action.agentListSelectedFilter,
            };
        case AGENT_LIST_SELECTED_DATE_FILTER:
            return {
                ...state,
                agentListSelectedDateFilter: action.agentListSelectedDateFilter,
            };
        case AGENT_LIST_START_TIME_FILTER:
            return {
                ...state,
                agentListStartTimeFilter: action.agentListStartTimeFilter,
            };
        case AGENT_LIST_END_TIME_FILTER:
            return {
                ...state,
                agentListEndTimeFilter: action.agentListEndTimeFilter,
            };
        case AGENT_NAME_FILTER:
            return {
                ...state,
                agentNameFilter: action.agentNameFilter,
            };
        case STEP1_IS_HAS_EXPERIENCE:
            return {...state, step1IsHasExperience: action.isHasExperience };
        case STEP2_APPOINTMENT_METHOD:
            return {...state, step2AppointmentMethod: action.appointmentMethod };
        case STEP2_SELECTED_STORE:
            return {...state, step2SelectedStore: action.selectedStore };
        case STEP1_INSURANCE_TYPES:
            return {...state, step1InsuranceTypes: action.selectedInsurances };
        case STEP1_REQUEST:
            return {...state, step1RequestMessage: action.requestMessage };
        case STEP1_DISCUSS_STATUS:
            return {...state, step1DiscussStatus: action.discussStatus };
        case STEP1_DISCUSS_STATUS_TEXT:
            return {...state, step1DiscussStatusText: action.discussStatusText };
        case STEP1_TALKING_DEMAND:
            return {...state, step1TalkingDemand: action.selectedTalkingDemand };
        case STEP1_TALKIING_DEMANND_TEXT:
            return {...state, step1TalkinngDemandText: action.talkingDemandText };
        case STEP2_DATE:
            return {...state, step2_date: action.step2_date };
        case STEP2_TIME:
            return {...state, step2_time: action.step2_time };
        case STEP2_FIRST_NAME_KANJI:
            return {...state, step2_first_name_kanji: action.step2_first_name_kanji };
        case STEP2_LAST_NAME_KANJI:
            return {...state, step2_last_name_kanji: action.step2_last_name_kanji };
        case STEP2_FIRST_NAME_KANA:
            return {...state, step2_first_name_kana: action.step2_first_name_kana };
        case STEP2_LAST_NAME_KANA:
            return {...state, step2_last_name_kana: action.step2_last_name_kana };
        case STEP2_EMAIL:
            return {...state, step2_email: action.step2_email };
        case STEP2_PHONE:
            return {...state, step2_phone: action.step2_phone };
        case STEP2_EMPLOYEE_NUMBER:
            return {...state, step2_employee_number: action.step2_employee_number };
        case GROUP_RESERVATION_CONFIG:
            return {...state, groupReservationConfig: action.config };
        case RESET_MATCHING_QUESTIONS:
            return {...state,
                answeredDirQns: initialState.answeredDirQns,
                answeredSymQns: initialState.answeredSymQns,
                selectedBranch: initialState.selectedBranch
            }
        case STEP2_DOB :
            return {...state, step2_dob: action.dob};
        case STEP2_GENDER:
            return {...state, step2_gender: action.gender};
        case STEP2_POST_CODE: 
            return {...state, step2_postCode: action.postCode};
        case STEP2_PREFECTURES:
            return {...state, step2_prefectures: action.prefectures};
        case STEP2_MUNICIPALITIES: 
            return {...state, step2_municipalities: action.municipalities};
        case STEP2_ADDRESS :
            return {...state, step2_address: action.address};
        case STEP2_COMPANY_EMAIL: 
            return {...state, step2_company_email: action.company_email};
        case STEP2_DEPARTMENT:
            return {...state, step2_department: action.department};
        case STEP2_EXTENSION_NUMBER:
            return {...state, step2_extension_number: action.extension_number};
        case STEP2_PREFERRED_APPOINTMENT:
            return {...state, step2_preferred_appointment_method : action.preferred_appointment_method};
        case STEP2_CONVENIENT_TIME:
            return {...state, step2_convenient_time: action.convenient_time}
        case STEP2_REMARK:
            return {...state, step2_remark: action.remark}
        case SET_BREADCRUMBS_PATH:
            return {...state, breadcrumbsPath: action.breadcrumbsPath}
        case RESET_SETP2_EXCEPT_DATE:
            return {...state, 
                step2_first_name_kanji: initialState.step2_first_name_kanji,
                step2_last_name_kanji: initialState.step2_last_name_kanji,
                step2_first_name_kana: initialState.step2_first_name_kana,
                step2_last_name_kana: initialState.step2_last_name_kana,
                step2_email: initialState.step2_email,
                step2_phone: initialState.step2_phone,
                step2_employee_number: initialState.step2_employee_number,
                step2_dob: initialState.step2_dob,
                step2_gender: initialState.step2_gender,
                step2_postCode: initialState.step2_postCode,
                step2_prefectures: initialState.step2_prefectures,
                step2_municipalities: initialState.step2_municipalities,
                step2_address: initialState.step2_address,
                step2_company_email: initialState.step2_company_email,
                step2_time: initialState.step2_time,
                step2_department: initialState.step2_department,
                step2_extension_number: initialState.step2_extension_number,
                step2_preferred_appointment_method: initialState.step2_preferred_appointment_method,
                step2_convenient_time: initialState.step2_convenient_time,
                step2_remark: initialState.step2_remark,
                step2SelectedStore: initialState.step2SelectedStore
            }
        default:
            return state;
    }
}