import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "./styles.css";
import buildCalendar, {
  dayMamatasStyles,
  beforeToday,
  beforeThisMonth,
  beforeNextMonth,
  afterNextMonth,
  afterDec31,
} from "../../utilities/calendar";
import Header from "./header";
import { getCalendarData, calculateDaysFromNext3WorkDay } from "../../api/Appointment/Step2";
import { convertDate } from "../../utilities/helper";
import { useMatomo } from "@datapunt/matomo-tracker-react";

export default function Calendar({ data, onSelect, selectedDate, locationId }) {
  const { t } = useTranslation();
  const { trackEvent } = useMatomo();
  const [calendar, setCalendar] = useState([]);
  const [value, setValue] = useState(
    selectedDate !== "" ? moment(selectedDate) : moment()
  );
  const [selected, setSelected] = useState(selectedDate);
  const [currentDate, setCurrentDate] = useState(moment());
  const [originalCalendar, setOriginalCalendar] = useState({ dateList: [] });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getCalendar();
    onSelect(selected);
  }, [value]);

  useEffect(() => {
    if (!selectedDate) {
      setSelected(selectedDate);
      setValue(moment())
    }
  }, [selectedDate]);

  const getCalendar = async () => {
    if (originalCalendar.dateList.length <= 0) {
      await getCalendarData({
        date: "",
      }).then((data) => {
        setCurrentDate(moment(data.currentDate));
        const calendar_data = buildCalendar(
          data,
          data,
          value,
          moment(data.currentDate)
        );
        setCalendar(calendar_data);
        setOriginalCalendar(data);
      });
    } else {
      const calendar_data = buildCalendar(
        originalCalendar,
        data,
        value,
        currentDate
      );
      setCalendar(calendar_data);
    }
  };
  const onDayClick = (param_day) => {
    const day = moment(param_day.date);
    // weekends and off days not selectable
    // if (!(isEnable(param_day) || afterNextMonth(day, value) || beforeToday(day) || (beforeThisMonth(day, value) && beforeNextMonth(day))) && !afterDec31(day) && !isToday(day)) {
    //     setSelected(convertDate(day));
    //     setValue(day);
    // }

    // weekends and off days selectable
    const { dateList, currentDate } = originalCalendar;
    let unclickableDaysAfterToday = calculateDaysFromNext3WorkDay(dateList, currentDate) - 1;
    if (
      !(
        afterNextMonth(day, value) ||
        beforeToday(day) ||
        (beforeThisMonth(day, value) && beforeNextMonth(day))
      ) &&
      !afterDec31(day) &&
      !day.isBefore(moment().add(unclickableDaysAfterToday, "day"))
    ) {
      setSelected(convertDate(day));
      setValue(day);
      trackEvent({ category: 'onCalendarDateSelect', action: 'step2-click-event' });
    }
  };

  const convertDay = (value) => {
    const date = moment(value).format("D").toString();
    return date === "Invalid date" ? "" : date;
  };

  return (
    <div className="calendar">
      <Header value={value} setValue={setValue} />
      <div className="day-names">
        {[
          t("general.calendar.weekdays.sunday"),
          t("general.calendar.weekdays.monday"),
          t("general.calendar.weekdays.tuesday"),
          t("general.calendar.weekdays.wednesday"),
          t("general.calendar.weekdays.thursday"),
          t("general.calendar.weekdays.friday"),
          t("general.calendar.weekdays.saturday"),
        ].map((d, index) => (
          <div key={index} className="week">
            {index === 0 || index === 6 ? (
              <div className="weekend">{d}</div>
            ) : (
              <div>{d}</div>
            )}
          </div>
        ))}
      </div>
      <div className="body">
        {calendar.map((week, index) => (
          <div key={index} className="days-container">
            {week.map((day, index) => (
              <div key={index} className="day" onClick={() => onDayClick(day)}>
                <div className={dayMamatasStyles(day, value, selected, calendar, locationId)}>
                  {!(
                    (beforeThisMonth(moment(day.date), value) &&
                      beforeNextMonth(moment(day.date))) ||
                    afterNextMonth(moment(day.date), value)
                  ) && convertDay(day.date)}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
