import React, { useState, useEffect } from "react";

import classes from "./styles.module.css";
import cleanTextIcon from "../../../assets/images/icons/clean_text.png";

const ClearableTextBox = ({
  front_icon,
  placeholder,
  className,
  style,
  onChange,
  onClean,
  text,
  inputtype,
  onKeyDown,
  id,
  focus,
  onFocus,
  reference,
}) => {
  const [emptyInput, setEmptyInput] = useState(true);

  const onInputChange = (e) => {
    onChange(e);
    e.target.value === "" ? setEmptyInput(true) : setEmptyInput(false);
  };

  const onCleanText = () => {
    onClean();
    setEmptyInput(true);
  };

  useEffect(() => {
    if (!text) {
      setEmptyInput(true);
    }else{
      setEmptyInput(false);
    }
  }, [text]);

  return (
    <div className={`${classes.general_textbox} ${className}`} style={style}>
      {front_icon && (
        <img src={front_icon} className={classes.front_icon} alt="front icon" />
      )}
      <input
        className={
          front_icon === undefined ? classes.text_input_100 : classes.text_input
        }
        placeholder={placeholder}
        onChange={onInputChange}
        id={id}
        value={text}
        type={inputtype}
        onKeyDown={onKeyDown}
        autoComplete="off"
        autoFocus={focus}
        onFocus={onFocus}
        ref={reference}
      />
      {onClean && !emptyInput && (
        <img
          src={cleanTextIcon}
          onClick={onCleanText}
          className={classes.clean_text_icon}
          alt="clean text"
        />
      )}
    </div>
  );
};

export default ClearableTextBox;
