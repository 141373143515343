export const RESET_ALL_2_INITIAL_STATE = 'RESET_2_INITIAL_STATE';
export const SET_S_ID = 'SET_S_ID';
export const RESET_WELCOME_SEARCHING_INFO = 'RESET_WELCOME_SEARCHING_INFO';
export const RESET_AGENT_LIST_HISTORIES = 'RESET_AGENT_LIST_HISTORIES';
export const RESET_APPOINTMENT_RESERVATION = 'RESET_APPOINTMENT_RESERVATION';
export const USER_ID = 'USER_ID';
export const INSURANCE_TYPES = 'INSURANCE_TYPES';
export const SELECTED_INSURANCES_ID = 'SELECTED_INSURANCES_ID';
export const SELECTED_BIRTH_YEAR = 'SELECTED_BIRTH_YEAR';
export const SELECTED_FAMILY_STR = 'SELECTED_FAMILY_STR';
export const SELECTED_GENDER_ID = 'SELECTED_GENDER_ID';
export const DIRECT_QUESTIONS = 'DIRECT_QUESTIONS';
export const ANSWERED_DIRECT_QNA = 'ANSWERED_DIRECT_QNA';
export const SYMPATHY_QUESTIONS = 'SYMPATHY_QUESTIONS';
export const ANSWERED_SYMPATHY_QNS = 'ANSWERED_SYMPATHY_QNS';
export const AGENT_LIST_SCROLL_POSITION = "AGENT_LIST_SCROLL_POSITION";
export const AGENT_LIST_CURRENT_PAGE = "AGENT_LIST_CURRENT_PAGE";
export const AGENT_LIST_SELECTED_FILTER = "AGENT_LIST_SELECTED_FILTER";
export const AGENT_LIST_SELECTED_DATE_FILTER = "AGENT_LIST_SELECTED_DATE_FILTER";
export const AGENT_LIST_START_TIME_FILTER = "AGENT_LIST_START_TIME_FILTER";
export const AGENT_LIST_END_TIME_FILTER = "AGENT_LIST_END_TIME_FILTER";
export const AGENT_NAME_FILTER = "AGENT_NAME_FILTER";
export const STEP1_IS_HAS_EXPERIENCE = 'STEP1_IS_HAS_EXPERIENCE';
export const STEP2_APPOINTMENT_METHOD = 'STEP2_APPOINTMENT_METHOD';
export const STEP2_SELECTED_STORE = 'STEP2_SELECTED_STORE';
export const STEP1_INSURANCE_TYPES = 'STEP1_INSURANCE_TYPES';
export const STEP1_REQUEST = 'STEP1_REQUEST';
export const STEP2_DATE = 'STEP2_DATE';
export const STEP2_TIME = 'STEP2_TIME';
export const STEP2_FIRST_NAME_KANJI = 'STEP2_FIRST_NAME_KANJI';
export const STEP2_LAST_NAME_KANJI = 'STEP2_LAST_NAME_KANJI';
export const STEP2_FIRST_NAME_KANA = 'STEP2_FIRST_NAME_KANA';
export const STEP2_LAST_NAME_KANA = 'STEP2_LAST_NAME_KANA';
export const STEP2_EMAIL = 'STEP2_EMAIL';
export const STEP2_PHONE = 'STEP2_PHONE';
export const STEP2_DOB = 'STEP2_DOB';
export const STEP2_GENDER = 'STEP2_GENDER';
export const STEP2_POST_CODE = 'STEP2_POST_CODE';
export const STEP2_PREFECTURES = 'STEP2_PREFECTURES';
export const STEP2_MUNICIPALITIES = 'STEP2_MUNICIPALITIES';
export const STEP2_ADDRESS = 'STEP2_ADDRESS';
export const STEP2_COMPANY_EMAIL = 'STEP2_COMPANY_EMAIL';
export const STEP2_DEPARTMENT = 'STEP2_DEPARTMENT';
export const STEP2_EXTENSION_NUMBER = 'STEP2_EXTENSION_NUMBER';
export const STEP2_PREFERRED_APPOINTMENT = 'STEP2_PREFERRED_APPOINTMENT';
export const STEP2_CONVENIENT_TIME = 'STEP2_CONVENIENT_TIME';
export const STEP2_EMPLOYEE_NUMBER = 'STEP2_EMPLOYEE_NUMBER';
export const SELECTED_BRANCH = 'SELECTED_BRANCH';
export const GROUP_RESERVATION_CONFIG = 'GROUP_RESERVATION_CONFIG';
export const RESET_MATCHING_QUESTIONS = 'RESET_MATCHING_QUESTIONS';
export const STEP2_REMARK = 'STEP2_REMARK';
export const STEP1_DISCUSS_STATUS = 'STEP1_DISCUSS_STATUS';
export const STEP1_DISCUSS_STATUS_TEXT = 'STEP1_DISCUSS_STATUS_TEXT';
export const STEP1_TALKING_DEMAND = 'STEP1_TALKING_DEMAND';
export const STEP1_TALKIING_DEMANND_TEXT = 'STEP1_TALKIING_DEMANND_TEXT';
export const RESET_SETP2_EXCEPT_DATE = 'RESET_SETP2_EXCEPT_DATE';
export const SET_BREADCRUMBS_PATH = 'SET_BREADCRUMBS_PATH';
