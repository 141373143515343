import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useMatomo } from "@datapunt/matomo-tracker-react";

import ProfileInfoCard from '../../components/CardViews/ProfileInfoCard';


import styles from './styles.module.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={styles.description_box}>
          <div component="div">{children}</div>
        </Box>
      )}
    </div>
  );
}



function selected(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomTab = withStyles({
  root: {
    textTransform: 'none',
    minWidth: '50%',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: 'hsl(194, 100%, 43%)',
    },
    '&:focus': {
      color: '#40a9ff',
    },
  }
})((props) => <Tab {...props} />);


const CustomTabs = withStyles({
  root: {
    borderBottom: '1px solid #D1D1D1'
  }
})((props) => <Tabs {...props} />);


const TabPage = ({ data }) => {
  const { t } = useTranslation();
  const { trackEvent } = useMatomo();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    trackEvent({ category: 'onAgentDetailView', action: 'agent-detail-click-event' })
  };

  return (
    <div >
      <CustomTabs value={value} onChange={handleChange} aria-label="tabs" TabIndicatorProps={{
        style: {
          backgroundColor: 'hsl(194, 100%, 43%)'
        }
      }} >
        <CustomTab label={t('agent_detail_page.tabs.description')} {...selected(0)} />
        <CustomTab label={
          <div className={styles.customtab_container}>
            <div>{t('agent_detail_page.tabs.profile.title')}</div>
          </div>
        } {...selected(1)} selected />
      </CustomTabs>
      <TabPanel value={value} index={0} >
        <p className={`font-14 ${styles.wrap_text}`}>{data.description}</p>
        <p className={`font-14 ${styles.wrap_text}`}>{data.selfIntro}</p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProfileInfoCard title={t('agent_detail_page.tabs.profile.birthplace')} description={data.birthplace ? data.birthplace : '-'} />
        <ProfileInfoCard title={t('agent_detail_page.tabs.profile.goodAt')} description={data.goodAt ? data.goodAt : '-'} />
        <ProfileInfoCard title={t('agent_detail_page.tabs.profile.favoriteFood')} description={data.favoriteFood ? data.favoriteFood : '-'} />
        <ProfileInfoCard title={t('agent_detail_page.tabs.profile.addictedLately')} description={data.addictedLately ? data.addictedLately : '-'} />
        <ProfileInfoCard title={t('agent_detail_page.tabs.profile.favoriteTv')} description={data.favoriteTv ? data.favoriteTv : '-'} />
        <ProfileInfoCard title={t('agent_detail_page.tabs.profile.clubCircles')} description={data.clubCircles ? data.clubCircles : '-'}/>
        <ProfileInfoCard title={t('agent_detail_page.tabs.profile.acquireCertification')} description={data.acquireCertification ? data.acquireCertification : '-'}/>
        <ProfileInfoCard title={t('agent_detail_page.tabs.profile.didBest')} description={data.didBest ? data.didBest : '-'}/>
        <ProfileInfoCard title={t('agent_detail_page.tabs.profile.thisYearGoal')} description={data.thisYearGoal ? data.thisYearGoal : '-'}/>
        <ProfileInfoCard title={t('agent_detail_page.tabs.profile.moreDetails')} description={data.moreDetails ? data.moreDetails : '-'} />
      </TabPanel>
    </div>
  );
}

export default TabPage