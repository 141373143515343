import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import { I18nextProvider } from "react-i18next";
import i18n from "./languages";
import App from "./App";
import { SnackbarProvider } from "notistack";
import TagManager from "react-gtm-module";
import reportWebVitals from "./reportWebVitals";
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import { matomo } from "./matomo";

const environment = process.env.REACT_APP_ENV;
if (environment === "prod") {
  const tagManagerArgs = {
    gtmId: "GTM-MGNPWBQ",
  };
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={matomo}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <PersistGate persistor={persistor}>
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </PersistGate>
        </I18nextProvider>
      </Provider>
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
