import React from 'react';

import classes from './styles.module.css';

const GeneralTextArea = ({value, onChange, className, placeholder, onFocus}) => {
    return (
        <textarea  
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            placeholder={placeholder}
            className={`PoppinsMedium font-12 ${classes.general_textarea} ${className}`} />
    )
}

export default GeneralTextArea;