import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import { useHistory, useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import { getQuestionConfigs } from "../../api/Welcome/groupReservation";
import { getRecruitmentNum } from "../../api/Welcome/getRecruitmentNum";
import readMoreIcon from "../../assets/images/icons/readmore.png";

import { setGroupReservationConfig, resetAppointmentReservation, setSID, setAgentListingSelectedDateFilter, setLoginUser } from "../../store/actions";

import HeaderBar from "../../components/HeaderBar";
import FooterBar from "../../components/FooterBar";
import SearchingForm from "./SearchingForm";
import PageNotFound from "../PageNotFound";

import styles from "./styles.module.css";

const pageview = {
  dataLayer: {
    event: "pageview",
    log_pageurl: "",
    log_pagename: "",
  },
};

const WelcomePage = ({ setSID, setGroupReservationConfig, resetAppointmentReservation, setLoginUser }) => {
  const history = useHistory();
  const { state } = useLocation();
  const branchId = state.branchId;
  const locationId = state.locationId;
  const { t } = useTranslation();
  const isMountedRef = useRef();
  const { trackPageView, pushInstruction } = useMatomo();
  
  document.title = !locationId ? 'トップページ' : locationId === 'mamatas' ? 'トップページ（ママタス）': 'トップページ（団体予約）';

  const [readMore, setReadMore] = useState(false);
  const [configStatus, setConfigStatus] = useState(false);
  const [complete, setComplete] = useState(false);
  const [recruitmentNum, setRecruitmentNum] = useState('');

  const getConfig = async () => {
    const onSuccess = (data) => {
      setGroupReservationConfig(data);
      setConfigStatus(true);
      setComplete(true);
    }
    await getQuestionConfigs(onSuccess, null, locationId, branchId);
   
  }
  const getRecruitment = async () => {
    const onSuccess = (data)=> {
      setRecruitmentNum(data);
    }
    await getRecruitmentNum(onSuccess, null);
  }

  useEffect(() => {
    trackPageView();
    if(state?.userId){
      pushInstruction('setUserId', state.userId);
      setLoginUser(state.userId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (branchId) {
      TagManager.dataLayer(pageview);
      setSID(branchId);
      setAgentListingSelectedDateFilter()
      getConfig();
      if(!state.locationId){
        getRecruitment();
      }
    }

  }, [branchId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isMountedRef.current = true
    if (locationId && locationId === 'reservation' && configStatus) {
      resetAppointmentReservation();
      history.replace({ pathname: `/group_reservation_step_1/${branchId}`, state: { branchId: branchId, locationId: locationId } })
    }
    if(locationId && locationId === 'mamatas' && branchId === '2000002001' && configStatus){
      resetAppointmentReservation();
      history.replace({ pathname: `/mamatas_reservation_step_1/${branchId}`, state: { branchId: branchId, locationId: locationId } })
    }
    return () => { isMountedRef.current = false }
  }, [configStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return !complete ? <></> : branchId === null || branchId === "" ? (
    <PageNotFound />
  ) : (
    <div>
      <HeaderBar />
      <Container fluid={true}>
        <Row className={`${styles.search_page} cmn-height`}>
          <Col lg="7">
            <Row>
              <Col
                lg={{ size: 10, offset: 1 }}
                xl={{ size: 8, offset: 3 }}
                className={styles.titles_container}
              >
                <h1 className={styles.title}>{t("home_search_page.title")}</h1>
                <div className={styles.hide_in_mobile}>

                  <p className={`font-16 ${styles.sub_title}`}>
                    {t("home_search_page.desc")}
                  </p>
                  <div className={styles.readmore}>
                    <div
                      className={styles.readmore_container}
                      onClick={() => setReadMore(!readMore)}
                    >
                      <p className={`${styles.readmore_label} }`} draggable="true">
                        {t("home_search_page.readmore")}
                      </p>
                      <img
                        src={readMoreIcon}
                        className={styles.readmore_icon}
                        draggable="true"
                        alt="readmore"
                      />
                    </div>
                  </div>
                  <p
                    className={
                      readMore
                        ? `font-16 ${styles.sub_title} ${styles.showReadMore}`
                        : `font-16 ${styles.sub_title} ${styles.hideReadMore}`
                    }
                    draggable="true"
                  >
                    {t("home_search_page.readmore_desc")}
                  </p>
                </div>

              </Col>
            </Row>
          </Col>
          <Col lg="5">
            <Row>
              <Col
                sm="12"
                md="12"
                lg="12"
                xl="9"
                className={styles.search_form_container}
              >
                <SearchingForm branchId={branchId} />
              </Col>
            </Row>
            <Row>
              <Col className="p-0">
                <p
                  className={`font-12 ${styles.internal_review_and_application_no}`}
                >
                  {recruitmentNum ? recruitmentNum : ''}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <FooterBar />
    </div>
  );
};


const dispatchToProps = (dispatch) => {
  return {
    setSID: (s_id) => {
      dispatch(setSID(s_id));
    },
    setGroupReservationConfig: (config) => {
      dispatch(setGroupReservationConfig(config))
    },
    resetAppointmentReservation: () => {
      dispatch(resetAppointmentReservation());
    },
    setLoginUser: (userId) => {
      dispatch(setLoginUser(userId))
  }
  };
};

export default connect(null, dispatchToProps)(WelcomePage);
