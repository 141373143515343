import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import TagManager from "react-gtm-module";
import { connect } from "react-redux";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import HeaderBar from "../../components/HeaderBar";
import FooterBar from "../../components/FooterBar";
import DirectQuestionsPage from '../DirectQuestionsPage';
import BranchesSelectionPage from "../BranchesSelectionPage";
import SympathyQuestionsPage from '../SympathyQuestionsPage';

import classes from "./styles.module.css";

import {
  setAnsweredDirectQns,
  setAnsweredSympathyQns,
  setSympathyQuestions,
  resetAgentListFilter,
  setSelectedBranch,
} from "../../store/actions";
import { isMainBranch } from "../../utilities/helper";

const AiMatchingPage = ({ 
  directQuestions,
  answeredDirQns,
  setAnsweredDirectQns,
  answeredSymQns,
  sympathyQuestions,
  setAnsweredSympathyQns,
  selectedBranch,
  setSelectedBranch
 }) => {
  document.title = "マッチング条件入力ページ";

  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();
  const { trackPageView, trackEvent } = useMatomo();

  const [isFaceToFace, setIsFaceToFace] = useState(false);
  const [firstAnswer, setFirstAnswer] = useState(
    answeredDirQns[0] && answeredDirQns[0]
  );
  const [secondAnswer, setSecondAnswer] = useState(
    answeredDirQns[1] && answeredDirQns[1]
  );
  const [directQuestionsError, setDirectQuestionsError] = useState(false);

  const pageview = {
    dataLayer: {
      event: "pageview",
      log_pageurl: "",
      log_pagename: "",
    },
  };

  useEffect(() => {
    TagManager.dataLayer(pageview);
    window.scrollTo(0,-100);
    trackPageView();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Direct Question
  useEffect(()=>{
    if(isMainBranch(state.branchId)){
      secondAnswer && setIsFaceToFace(secondAnswer.itemId === 401 ? true : false);
    }
  },[secondAnswer, state.branchId])

  const onDirectQuestionValidate = () => {
    //check require
    firstAnswer === undefined || secondAnswer === undefined
    ? setDirectQuestionsError(true)
    : setDirectQuestionsError(false);

    if (firstAnswer !== undefined && secondAnswer !== undefined) {
      setAnsweredDirectQns([
        {
          itemId: firstAnswer.itemId,
          questionId: directQuestions[0].id,
        },
        {
          itemId: secondAnswer.itemId,
          questionId: directQuestions[1].id,
        },
      ]);
      return true;
    }else{
      scrollToError('direct_question_error');
      return false;
    }
}

  const scrollToError = (id) => {
    let element = document.getElementById(id);
      if(element){
        element.scrollIntoView(false);
      }
  }

   
  //Direct Questions

  //Branch Selection Page
  const [selectedBranchList, setSelectedBranchList] = useState(selectedBranch);
  const [branchError, setBranchError] = useState(false);

  const onBranchValidate = () => {
    if(selectedBranchList){
      setSelectedBranch(selectedBranchList);
      setBranchError(false);
      return true;
    }else{
      setBranchError(true);
      scrollToError('branch_list_error');
      return false;
    }
  }

  //Branch Selection Page

  //Sympath Page
  const [sympathyFirstAnswer, setSympathyFirstAnswer] = useState(answeredSymQns[0] && answeredSymQns[0]);
  const [sympathySecondAnswer, setSympathySecondAnswer] = useState(answeredSymQns[1] && answeredSymQns[1]);
  const [sympathyThirdAnswer, setSympathyThirdAnswer] = useState(answeredSymQns[2] && answeredSymQns[2]);
  const [sympathyQuestionsError, setSympathyQuestionsError] = useState(false);
  
  const onSympathyValidate = (paramBranch) => {
    if (sympathyFirstAnswer === undefined || sympathySecondAnswer === undefined || sympathyThirdAnswer === undefined) {
        setSympathyQuestionsError(true);
        scrollToError('sympath_question_error');
        return;
    }else{
        setSympathyQuestionsError(false);
    }
    if (sympathyFirstAnswer !== undefined && sympathySecondAnswer !== undefined && sympathyThirdAnswer !== undefined) {
        setAnsweredSympathyQns(
            [
                {
                    "itemId": sympathyFirstAnswer.itemId,
                    "questionId": sympathyQuestions[0].id
                },
                {
                    "itemId": sympathySecondAnswer.itemId,
                    "questionId": sympathyQuestions[1].id
                },
                {
                    "itemId": sympathyThirdAnswer.itemId,
                    "questionId": sympathyQuestions[2].id
                }
            ]
        );
        resetAgentListFilter();
        const nextButtonEvent = {
          dataLayer: {
              'event': 'click-event',
              'data-log-click': 'click, Submit Sympathy Question answers for matching'
          }
        }
        TagManager.dataLayer(nextButtonEvent);
        history.push({ pathname: `/agents/${paramBranch}`, state: { branchId: paramBranch } });
    }
}
  
  //Sympath Page

  const onValidate = () => {
    if(onDirectQuestionValidate()){
      if(isFaceToFace){
        if(onBranchValidate()){
          onSympathyValidate(selectedBranchList ? selectedBranchList.branchId : state.branchId);
        }
      }else{
        setSelectedBranch(undefined);
        setSelectedBranchList(undefined);
        onSympathyValidate(state.branchId);
      }
      trackEvent({ category: 'onNext', action: 'matching-click-event' })
    }
   
  }

  const onBack = () => {
    const backButtonEvent = {
        dataLayer: {
            'event': 'click-event',
            'data-log-click': 'click, Back to Welcome Page'
        }
    }
    TagManager.dataLayer(backButtonEvent);
    trackEvent({ category: 'onBack', action: 'matching-click-event' })
    history.goBack();
  }

  useEffect(()=>{
    if(directQuestionsError){
      scrollToError('direct_question_error');
      return;
    }

    if(branchError){
      scrollToError('branch_list_error');
      return;
    }

    if(sympathyQuestionsError){
      scrollToError('sympath_question_error');
      return;
    }
  },[directQuestionsError, branchError, sympathyQuestionsError ])


  return (
    <>
      <HeaderBar />
      <div className={`${classes.ai_matching_page} cmn-height`}>
        <Container className={classes.container}>
          <Row>
            <Col className="p-0">
              <h3 className={`text-center ${classes.title} mb-4`}>
                {t("ai_matching_page.title")}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col lg="9" md="12" className={classes.ai_main_box}>
              <DirectQuestionsPage
                  state={state}
                  isFaceToFace={isFaceToFace}
                  directQuestions={directQuestions}
                  firstAnswer={firstAnswer}
                  setFirstAnswer={setFirstAnswer}
                  secondAnswer={secondAnswer}
                  setSecondAnswer={setSecondAnswer}
                  directQuestionsError={directQuestionsError}
              />
            </Col>
          </Row> 
          {
            isFaceToFace &&
            <>
            <Row className="pt-4">
              <Col lg="9" md="12" className={classes.ai_main_box}>
                <BranchesSelectionPage
                  selectedBranch={selectedBranchList}
                  setSelectedBranch={setSelectedBranchList}
                  branchError={branchError}
                  setBranchError={setBranchError}
                />
              </Col>
            </Row>
            </>
          }
          <Row  className="pt-4">
            <Col lg="9" md="12" className={classes.ai_main_box}>
              <SympathyQuestionsPage
                  sympathyFirstAnswer={sympathyFirstAnswer}
                  setSympathyFirstAnswer={setSympathyFirstAnswer}
                  sympathySecondAnswer={sympathySecondAnswer}
                  setSympathySecondAnswer={setSympathySecondAnswer}
                  sympathyThirdAnswer={sympathyThirdAnswer}
                  setSympathyThirdAnswer={setSympathyThirdAnswer}
                  sympathyQuestions={sympathyQuestions}
                  sympathyQuestionsError={sympathyQuestionsError}
                  onSubmit={onValidate}
                  onBack={onBack}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <FooterBar />
    </>
  );
};

const stateToProps = (state) => {
  return {
    directQuestions: state.directQuestions,
    answeredDirQns: state.answeredDirQns,
    sympathyQuestions: state.sympathyQuestions,
    answeredSymQns: state.answeredSymQns,
    selectedBranch: state.selectedBranch
  };
};

const dispatchToProps = (dispatch) => {
  return {
    setAnsweredDirectQns: (answeredQns) => {
      dispatch(setAnsweredDirectQns(answeredQns));
    },
    setAnsweredSympathyQns: (answeredQns) => {
      dispatch(setAnsweredSympathyQns(answeredQns));
    },
    setSympathyQuestions: (sympathyQuestions) => {
      dispatch(setSympathyQuestions(sympathyQuestions));
    },
    setSelectedBranch: (selectedBranch) => {
      dispatch(setSelectedBranch(selectedBranch));
    },
    resetAgentListFilter: () => {
        dispatch(resetAgentListFilter());
    }
  };
};

export default connect(stateToProps, dispatchToProps)(AiMatchingPage);

