import moment from 'moment';

import { base_api_url, base_proxy_api_url } from '../../configs/environments';
import api_urls from '../../routes/api_urls';
import { defaultHeaderOptions } from '../headerOptions';

import eventBus from '../../EventBus';

import { convertDate } from '../../utilities/helper';

const getTimeSlots = (branchId, agentId) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions,
        body: JSON.stringify({
            "branchId": branchId,
            "agentId": agentId
        })
    };

    const result = fetch(`${base_api_url}${api_urls.get_time_slot}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }

            return data;
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('getting Time Slots data api error - ', error);
            return [];
        });

    return result;
}

const getAvailableTimeSlotsByUuid = (body, onSuccess, onFail) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions,
        body: JSON.stringify(body)
    };

    fetch(`${base_api_url}${api_urls.get_time_slot_by_uuid}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            onSuccess(data.timeSlots.filter(timeslot => !timeslot.block));
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('Getting Time Slots data by uuid api error - ', error);
            onFail(error);
        });
}

const getAvailableTimeSlotsByBranchId = (body, onSuccess, onFail) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions,
        body: JSON.stringify(body)
    };
    fetch(`${base_api_url}${api_urls.get_time_slot_by_branchid}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            onSuccess(data);
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('Getting Time Slots data by branchId api error - ', error);
            onFail(error);
        });
}

const getCalendarData = (body) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions,
        body: JSON.stringify(body)
    };

    const result = fetch(`${base_api_url}${api_urls.get_calender}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            return data;
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('getting Calendar api error - ', error);
            return error;
        });

    return result;
}

const getTomorrowDate4Calendar = (body, onSuccess, onFail) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions,
        body: JSON.stringify(body)
    };

    fetch(`${base_api_url}${api_urls.get_calender}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                Promise.reject(error);
            }
            onSuccess(moment(data.currentDate).add(1, 'day').format('YYYY/MM/DD'));
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('getting Calendar api error - ', error);
            onFail(error);
        });
}

const getNext3WorkdayDate4Calendar = (body, onSuccess, onFail) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions,
        body: JSON.stringify(body)
    };

    fetch(`${base_api_url}${api_urls.get_calender}`, requestOptions)
        .then(async response => {
            const data = await response.json();
            const {dateList, currentDate} = data;
            if (!response.ok) {
                const error = (data && data.message) || response.status;
                Promise.reject(error);
            }

            onSuccess(moment(data.currentDate).add(calculateDaysFromNext3WorkDay(dateList, currentDate), 'day').format('YYYY/MM/DD'));
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('getting Calendar api error - ', error);
            onFail(error);
        });
}

const calculateDaysFromNext3WorkDay = (dateList, currentDate) => {
    let index = dateList.findIndex(item => item.date === currentDate);
    let calculateDate = dateList[index];
    for(let next = 0; next < 3; next++){
        index += 1;
        calculateDate = dateList[index];
        if(calculateDate){
            if(calculateDate.isWeekend || calculateDate.isHoliday || calculateDate.isDayOff){
                next--;
            }
        }
    }
    let addDays = dateList.findIndex(item => item.date === calculateDate.date) - dateList.findIndex(item => item.date === currentDate);
    return addDays;
}

const searchNextAvailableDate4Calendar = (body, onSuccess, onFail) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions,
        body: JSON.stringify(body)
    };

    fetch(`${base_api_url}${api_urls.get_calender}`, requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                Promise.reject(error);
            }

            let nextAvailDate = moment(data.currentDate).add(1, 'day').format('YYYY-MM-DD');
            const dateList = data.dateList;

            for (let i = 0; i < dateList.length; i++) {
                if (dateList[i].date === nextAvailDate) {
                    if (dateList[i].isDayOff === 0) {
                        onSuccess(convertDate(dateList[i].date));
                        break;
                    }
                    else {
                        nextAvailDate = moment(nextAvailDate).add(1, 'day').format('YYYY-MM-DD');
                    }
                }
            }
        })
        .catch(error => {
            eventBus.dispatch("something_went_wrong");
            console.error('getting Calendar api error - ', error);
            onFail(error);
        });
}

const getZipAddress = (postcode, onSuccess, onFail) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions,
        body: JSON.stringify({
            postcode: postcode
        })
    };

    const result = fetch(`${base_api_url}${api_urls.get_address}`, requestOptions)
        .then(async response => {
            const data = await response.json();
            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            onSuccess && onSuccess(data);
        })
        .catch(error => {
            console.error('getting Zip address api error - ', error);
            onFail && onFail(error);
            return error;
        });

    return result;
}

const getKatakana = (body) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaderOptions,
        body: JSON.stringify(body)
    };

    const result = fetch(`${base_proxy_api_url}${api_urls.get_katakana}`,requestOptions)
        .then(async response => {
            const data = await response.text();
            if (response.status === 200) {
                return data;
            }
        })
        .catch(error => {
            console.error('getting katakana api error - ', error);
            return error;
        });

    return result;
}

export {
    getTimeSlots,
    getAvailableTimeSlotsByUuid,
    getAvailableTimeSlotsByBranchId,
    getCalendarData,
    getTomorrowDate4Calendar,
    getNext3WorkdayDate4Calendar,
    calculateDaysFromNext3WorkDay,
    searchNextAvailableDate4Calendar,
    getZipAddress,
    getKatakana
};