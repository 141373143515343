import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';

import FooterBar from '../../components/FooterBar';

import classes from './styles.module.css';

const pageview = {
    dataLayer: {
        event: 'pageview',
        log_pageurl: '',
        log_pagename: ''
    }
}

const PageNotFound = () => {
    document.title = "Page Not Found";

    const { t } = useTranslation();

    useEffect(() => {
        TagManager.dataLayer(pageview);
    }, []);

    return (
        <div>
            <div className={classes.page_not_found}>
                <h1>{t('general.page_not_found')}</h1>
            </div>
            <FooterBar />
        </div>
    )
}

export default PageNotFound;