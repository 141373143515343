import React from 'react';
import { useTranslation } from 'react-i18next';


import styles from './styles.module.css';
import loadingGif from '../../../assets/images/icons/submit_btn_loading.gif';
import submitIcon from '../../../assets/images/icons/submit_btn.png';

const SubmitButton = ({ loading = false, onClick, disabled = false, iconClassName, iconStyle, className, style }) => {

    const { t } = useTranslation();

    return (
        <button disabled={disabled || loading} className={!disabled ? `${styles.submit_button} ${className}` : `${styles.disabled_submit_btn} ${className}`} onClick={onClick} style={style}>
            {
                loading ?
                    <img src={loadingGif} className={`${styles.icon} ${iconClassName}`} style={iconStyle} alt="button loading icon" />
                    :
                    <img src={submitIcon} className={`${styles.icon} ${iconClassName}`} style={iconStyle} alt="button back icon" />
            }
            <p className={styles.text} draggable="true">{t('general.buttons.submit')}</p>
        </button>
    );
};


export default SubmitButton;