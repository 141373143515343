import React from 'react';

import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';

const ZipCodeSearch = ({className, style}) => {
    const { t } = useTranslation();
    const openLink = () => {
        window.open('https://www.post.japanpost.jp/zipcode/');
    }
    return (
        <div className={`font-12 ${classes.base} ${className}`} style={style} onClick={openLink} >
            <p>{t('general.fields.zipCodeSearch')}</p>
        </div>
    )
};


export default ZipCodeSearch;