import React from 'react';

import loadingGif from '../../../assets/images/icons/submit_btn_loading.gif';

import styles from './styles.module.css';

const GeneralLoadingButton = ({
    id,
    text,
    onClick,
    loading = false,
    disabled = false,
    loadingIconClassName,
    loadingIconStyle,
    frontIcon,
    frontIconClassName,
    frontIconStyle,
    backIcon,
    backIconClassName,
    backIconStyle,
    className,
    style
}) => {

    return (
        <button id={id} disabled={disabled || loading} className={!disabled ? `${styles.general_button} ${className}` : `${styles.disabled_general_button} ${className}`} onClick={onClick} style={style}>
            {
                loading ?
                    <img src={loadingGif} className={`${styles.loading_icon} ${loadingIconClassName}`} style={loadingIconStyle} alt="button loading icon" />
                    :
                    frontIcon &&
                    <img id={id} src={frontIcon} className={`${styles.front_icon} ${frontIconClassName}`} style={frontIconStyle} alt="button front icon" />
            }

            <p id={id} className={`${styles.text}`} draggable="true">{text}</p>

            {
                backIcon &&
                <img id={id} src={backIcon} className={`${styles.back_icon} ${backIconClassName}`} style={backIconStyle} alt="button back icon" />
            }
        </button>
    );
};


export default GeneralLoadingButton;