import React from 'react';


import classes from './styles.module.css';

const RadioButton = ({label, value, selectedValue, id, onChange, className, labelClassName, name}) => {
    return (        
        <li className={`${classes.custom_radio_box} ${className}`}>                   
            <input type="radio" id={`${id}-${value}`} value={value} checked={selectedValue === value} name={name} onChange={(e) => {onChange && onChange(e, label)}} />
            <span className={classes.custom_radio}></span>
            <label htmlFor={`${id}-${value}`} className={labelClassName} draggable="true">{label}</label>                   
        </li>     
    )
}

export default RadioButton;